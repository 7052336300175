import { Dialog, styled } from '@mui/material'
import React from 'react'

import { CheckoutForm } from '@/pages/stripe'
import COLORS from '@/utils/colors'
import theme from '@/utils/theme'

export default function StripeDialog ({ skuToBuy, isOpen, onClose }) {
  function onCloseHandler () {
    onClose()
  }

  return (
    <StyledDialog open={isOpen} onClose={onCloseHandler}>
      <CheckoutForm productId={skuToBuy} />
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  zIndex: 9999,
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    maxWidth: 'none',
    [theme.breakpoints.down('md')]: {
      margin: '16px'
    }
  }
}))
