import { useState } from 'react'

import { Container } from './container'
import { UserImageDialog } from './user-image-dialog'

import { Button } from '@/components/button'
import { useAvatar } from '@/hooks/use-avatar'

/**
 * UserImageEditor component
 *
 * @exports
 * @param props {object} { className }
 * @returns {React.ReactElement}
 */
export function UserImageEditor (props) {
  const [userImageDialogOpened, setUserImageDialogOpened] = useState(false)
  const { UserAvatar, hasAvatar, removeAvatar } = useAvatar()

  return (
    <>
      <Container className={props.className}>
        <UserAvatar />
        <div className='buttons-container'>
          <Button
            variant='outlined'
            className='edit-button primary-outline'
            onClick={() => setUserImageDialogOpened(true)}
          >
            Edit
          </Button>
          {hasAvatar() &&
            <Button
              className='remove-button'
              onClick={() => removeAvatar()}
            >
              Remove image
            </Button>}
        </div>
      </Container>
      <UserImageDialog open={userImageDialogOpened} onClose={() => setUserImageDialogOpened(false)} />
    </>
  )
}
