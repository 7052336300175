/**
 * @file NewFolderDialog.js
 * @description A dialog component for creating a new folder.
*/

import CloseIcon from '@mui/icons-material/Close'
import { OutlinedInput, styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button } from '@/components/button'
import useKeypress from '@/hooks/use-keypress'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { createFolder, updateFolder } from '@/store/library'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import COLORS from '@/utils/colors'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

/**
 * NewFolderDialog component for creating a new folder.
 * @function
 * @param {object} props
 * @param {boolean} props.isOpen - Indicates if the dialog is open.
 * @param {function} props.setIsOpen - Function to set the dialog open state.
 * @returns {JSX.Element} The NewFolderDialog component.
 */

export default function NewFolderDialog (props) {
  const isMobile = useMobileDetect()
  const openSnackbar = useSnackbar()
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const { fid } = useParams()
  const [isLoading, setIsLoading] = useState('')
  const [folderName, setFolderName] = useState('')

  const folderData = props.isOpen
  const isRenameMode = folderData?.fid

  const isSameAsOldName = folderName === folderData?.name

  /**
 * Checks if the folder name is invalid based on certain conditions.
 *
 * Conditions of iteration (AND):
 * - Iterated item is a folder
 * - Iterated item has the same parent (`fid`) as the folder we are currently in (could be undefined for root)
 * - Iterated item has the same name as the current folder.
 * - If in rename mode, iterated item `fid` property should not match the current folder being renamed.
 *
 * @returns {boolean} `true` if the folder name is invalid, `false` otherwise.
 */
  const isInvalidName = useMemo(() => {
    return props.filteredVideoExcerpts.some((item) => {
      const isSameRoot = String(item.parent) === String(fid)
      const isSameName = item.name === folderName
      const isDifferentFolder = !isRenameMode || folderData.fid !== item.fid

      return item.fid && isSameRoot && isSameName && isDifferentFolder
    })
  }, [fid, folderData.fid, folderName, isRenameMode, props.filteredVideoExcerpts])

  const isOpen = useMemo(() => {
    return !!props.isOpen
  }, [props.isOpen])

  const handleClose = () => {
    props.setIsOpen(false)
    setFolderName('')
  }

  const createFolderHandler = async () => {
    try {
      setIsLoading(true)
      const folderData = {
        name: folderName.trim()
      }
      if (fid) folderData.parent = Number(fid)

      await dispatch(createFolder(folderData))
    } catch (error) {
      openSnackbar('An error occurred while creating the folder. Please try again.', 'error')
    } finally {
      handleClose()
      setIsLoading(false)
    }
  }

  const renameFolderHandler = async () => {
    try {
      setIsLoading(true)
      await dispatch(updateFolder({ fid: folderData.fid, name: folderName.trim() }))
      openSnackbar('Folder successfully renamed.')
    } catch (error) {
      openSnackbar('An error occurred while renaming the folder. Please try again.', 'error')
    } finally {
      handleClose()
      setIsLoading(false)
    }
  }

  const handleAccept = () => {
    isRenameMode ? renameFolderHandler() : createFolderHandler()
  }

  useKeypress('Enter', () => {
    folderName && handleAccept()
  })

  const onChange = (event) => {
    const { value } = event.target
    setFolderName(value)
  }

  useEffect(() => {
    inputRef?.current?.focus()
    isRenameMode && setFolderName(folderData.name)
  }, [folderData.name, isOpen, isRenameMode])

  return (
    <>
      <StyledDialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <div className={`content ${isMobile && 'mobile'}`}>
          <div className='head'>
            <p>{isRenameMode ? 'Rename folder' : 'New Folder'}</p>
            <CloseIcon onClick={handleClose} />
          </div>
          <OutlinedInput
            inputRef={inputRef}
            placeholder='Untitled Folder'
            value={folderName}
            onChange={onChange}
            autoFocus
          />
          {isInvalidName && <p>This folder name already exists in this location. Please choose another name.</p>}
          <div className='actions'>
            <Button className='gray no-outline' onClick={handleClose}>Cancel</Button>
            <Button
              className={(folderName.trim() && !isSameAsOldName && !isInvalidName) ? 'green' : 'gray-filled'} disabled={!folderName.trim() || isLoading || isSameAsOldName || isInvalidName} onClick={handleAccept}
            >{isRenameMode ? 'Rename' : 'Create'}
            </Button>
          </div>
        </div>
      </StyledDialog>
    </>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    margin: 0
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px 24px',
    width: '500px',

    '& .head': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& svg': {
        cursor: 'pointer'
      },
      '& p': {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: COLORS['neutral-800']
      }
    },
    '& > p': {
      fontSize: 12,
      color: COLORS['danger-500']
    },
    '& input': {
      padding: '8px'
    },
    '& .actions': {
      display: 'flex',
      gap: '8px',
      justifyContent: 'flex-end'
    },

    '&.mobile': {
      width: '90vw'
    }
  }

}))
