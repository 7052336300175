import { useMemo } from 'react'

/**
 * getAvg - calculate avg and make sure to return 0 if both values are 0 (preventing NaN)
 *
 * @param {Number} total - Total value
 * @param {Number} count - Count how many values have been added
 * @returns {Number}
 */
const getAvg = (total, count) => !(total && count) ? 0 : total / count

/**
 * Converts shot statistics into percentage values.
 *
 * @param {Object} shots - An object containing shot data.
 * @param {number} shots.in - The number of shots that were successful.
 * @param {number} shots.net - The number of shots that hit net.
 * @param {number} shots.out - The number of shots that were out.
 * @param {number} shots.total - The total number of shots taken.
 * @returns {Object} An object containing the percentage of successful,
 *                  net, and out shots, rounded to the nearest integer.
 */
function convertToPercentages (shots) {
  return {
    in: Math.round(shots.in / shots.total * 100),
    net: Math.round(shots.net / shots.total * 100),
    out: Math.round(shots.out / shots.total * 100)
  }
}

/**
 * Calculate the statistics for players based on rallies data.
 *
 * @param {Array} rallies - The array of rally data
 * @returns {Object} - Object containing calculated player statistics
 */
export const useRalliesPlayersStats = (rallies) => {
  return useMemo(() => {
    const playersStatsCache = [...Array(4).keys()].reduce((acc, c, i) => {
      acc[i] = {
        // Initialize statistics properties for each player
        avg_height_above_net: 0,
        avg_height_above_net_count: 0,
        avg_baseline_distance: 0,
        avg_baseline_distance_count: 0,
        drive_avg_speed: 0,
        drive_avg_speed_count: 0,
        serve_fastest_speed: 0,
        serve_avg_speed: 0,
        serve_avg_speed_count: 0,
        thirdShotStats: {
          drive: {
            avg_height_above_net: 0,
            avg_height_above_net_count: 0
          },
          drop: {
            avg_height_above_net: 0,
            avg_height_above_net_count: 0
          },
          lob: {
            avg_baseline_distance: 0,
            avg_baseline_distance_count: 0
          }
        },
        shotAccuracy: {
          in: 0,
          net: 0,
          out: 0,
          total: 0
        }
      }
      return acc
    }, {})

    for (let r = 0; r < rallies.length; r++) {
      const shots = rallies[r].shots
      for (let s = 0; s < shots.length; s++) {
        const shot = shots[s]
        if (shot.resulting_ball_movement) {
          if (shot.resulting_ball_movement.height_over_net) {
            playersStatsCache[shot.player_id].avg_height_above_net += shot.resulting_ball_movement.height_over_net
            playersStatsCache[shot.player_id].avg_height_above_net_count += 1
          }
          if (shot.resulting_ball_movement.distance_from_baseline) {
            playersStatsCache[shot.player_id].avg_baseline_distance += shot.resulting_ball_movement.distance_from_baseline
            playersStatsCache[shot.player_id].avg_baseline_distance_count += 1
          }
          if (shot.shot_type === 'drive' && shot.resulting_ball_movement.speed) {
            playersStatsCache[shot.player_id].drive_avg_speed += shot.resulting_ball_movement.speed
            playersStatsCache[shot.player_id].drive_avg_speed_count += 1
          }
          // Serve
          if (s === 0) {
            if (shot.resulting_ball_movement.speed > playersStatsCache[shot.player_id].serve_fastest_speed) {
              playersStatsCache[shot.player_id].serve_fastest_speed = shot.resulting_ball_movement.speed
            }
            playersStatsCache[shot.player_id].serve_avg_speed += shot.resulting_ball_movement.speed
            playersStatsCache[shot.player_id].serve_avg_speed_count += 1
          } else if (s === 2) {
            // Third shot stats
            if (shot.shot_type === 'lob') {
              const cache = playersStatsCache[shot.player_id].thirdShotStats[shot.shot_type]
              cache.avg_baseline_distance += shot.resulting_ball_movement.distance_from_baseline
              cache.avg_baseline_distance_count += 1
              // ignore shots with errors - those that don't have height over net
            } else if ((shot.shot_type === 'drive' || shot.shot_type === 'drop') && shot.resulting_ball_movement.height_over_net) {
              const cache = playersStatsCache[shot.player_id].thirdShotStats[shot.shot_type]
              cache.avg_height_above_net += shot.resulting_ball_movement.height_over_net
              cache.avg_height_above_net_count += 1
            }
          }
        }
        if (shot.errors?.faults?.net) {
          playersStatsCache[shot.player_id].shotAccuracy.net++
        } else if (shot.errors?.faults?.out?.outcome === 'landed') {
          playersStatsCache[shot.player_id].shotAccuracy.out++
        } else {
          playersStatsCache[shot.player_id].shotAccuracy.in++
        }
        playersStatsCache[shot.player_id].shotAccuracy.total++
      }
    }
    // Calculate and format the final statistics for each player
    const playersStats = Object.fromEntries(Object.entries(playersStatsCache).map(([k, v]) => {
      const stat = {
        avg_height_above_net: getAvg(v.avg_height_above_net, v.avg_height_above_net_count),
        avg_baseline_distance: getAvg(v.avg_baseline_distance, v.avg_baseline_distance_count),
        drive_avg_speed: getAvg(v.drive_avg_speed, v.drive_avg_speed_count),
        serve_avg_speed: getAvg(v.serve_avg_speed, v.serve_avg_speed_count),
        serve_fastest_speed: v.serve_fastest_speed,
        thirdShotStats: {
          drive: {
            avg_height_above_net: getAvg(v.thirdShotStats.drive.avg_height_above_net, v.thirdShotStats.drive.avg_height_above_net_count).toFixed(1)
          },
          drop: {
            avg_height_above_net: getAvg(v.thirdShotStats.drop.avg_height_above_net, v.thirdShotStats.drop.avg_height_above_net_count).toFixed(1)
          },
          lob: {
            avg_baseline_distance: getAvg(v.thirdShotStats.lob.avg_height_above_net, v.thirdShotStats.lob.avg_height_above_net_count).toFixed(1)
          }
        },
        shotAccuracy: convertToPercentages(v.shotAccuracy)
      }
      return [k, stat]
    }))

    return playersStats
  }, [rallies])
}
