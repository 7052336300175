import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import EditIcon from '@mui/icons-material/Edit'
import LinkIcon from '@mui/icons-material/Link'
import { useContext } from 'react'

import { PageContext } from '../../context'

import ContextMenu from '@/components/context-menu'
import { useIsLoggedIn } from '@/store/auth'
import COLORS from '@/utils/colors'

export default function FolderContextMenu ({ className, folder }) {
  const { setIsMoveItemsOpen, setItemsForMoving, setIsRemoveDialogOpen, setNewFolderDialogIsOpen, setIsShareDialogOpen, isRootFolderPublic, bulkSelected } = useContext(PageContext)
  const isLoggedIn = useIsLoggedIn()

  function onRename () {
    setNewFolderDialogIsOpen(folder)
  }
  function onShare () {
    setIsShareDialogOpen(folder)
  }
  function onShareInPublicLibrary () {
    setIsShareDialogOpen({ ...folder, isRootPublic: isRootFolderPublic })
  }
  function onRemove () {
    setIsRemoveDialogOpen(folder)
  }
  function onMoveFolder () {
    setIsMoveItemsOpen(true)
    if (bulkSelected?.length) {
      setItemsForMoving(bulkSelected)
    } else {
      setItemsForMoving(state => [...state, folder])
    }
  }

  const items = [
    {
      icon: <EditIcon />,
      text: 'Rename',
      onClick: onRename
    },
    {
      icon: <LinkIcon sx={{ color: COLORS['primary-500'] }} />,
      text: 'Share / Unshare',
      hidden: isRootFolderPublic,
      onClick: onShare
    },
    {
      icon: <LinkIcon sx={{ color: COLORS['primary-500'] }} />,
      text: 'Copy Public Share Link',
      hidden: !isRootFolderPublic,
      onClick: onShareInPublicLibrary
    },
    {
      icon: <DeleteIcon />,
      text: 'Remove',
      onClick: onRemove,
      hidden: !isLoggedIn
    },
    {
      icon: <DriveFileMoveIcon />,
      text: 'Move',
      onClick: onMoveFolder
    }
  ]

  return (
    <ContextMenu items={items} className={className} />
  )
}
