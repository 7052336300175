import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

import { DEMO_VIDEOS } from '../anonymous-upload'

import styles from './styles.module.scss'

import VideoIcon from '@/assets/on-demand-video.svg?react'
import PaddleIcon from '@/assets/pb-paddle.svg?react'
import Logo from '@/assets/pb-vis-horiz-logo.svg?react'
import { goToNativeUploadTipsScreen, startNativeUploadInMobileApp } from '@/utils/mobile-app-communication'

/**
 * The Welcome page is the first screen mobile app users see (after they get
 * past the hopefully very, very briefly displayed splash screen and webview
 * downloading screens).
 *
 * This screen is not shown outside of the native app webview.
 *
 * @returns {object} JSX
 */
function WelcomePage () {
  const navigate = useNavigate()

  const onDemoVideoClick = () => navigate(`/video/${DEMO_VIDEOS[0].vid}`)
  const onSignInButtonClick = () => navigate('/login')
  const onSignUpButtonClick = () => navigate('/create-account')
  const onChooseFromLibraryClick = () => startNativeUploadInMobileApp()

  return (
    <div className={styles.welcomePage}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <div className={styles.title}>Upload your video for free</div>
        <div className={styles.subtitle}>
          We recommended a full doubles game horizontal format with all 4 corners of the court in the frame.
          <button type='button' className={styles.needHelpLink} onClick={goToNativeUploadTipsScreen}>
            Need Help?
          </button>
        </div>
        <div>
          <Button
            variant='contained'
            className={styles.chooseFromLibraryButton}
            onClick={onChooseFromLibraryClick}
            endIcon={<VideoIcon />}
            classes={{
              endIcon: styles.icon
            }}
          >
            Record & Upload
          </Button>
        </div>
        <div className={styles.separator}>
          <div className={styles.line} />
          <div className={styles.text}>or use ours</div>
          <div className={styles.line} />
        </div>
        <Button
          variant='outlined'
          className={styles.demoVideoButton}
          onClick={onDemoVideoClick}
          endIcon={<PaddleIcon />}
          classes={{
            endIcon: styles.icon
          }}
        >
          Demo Video
        </Button>
      </div>
      <div className={styles.footer}>
        <Button variant='outlined' className={styles.signInButton} onClick={onSignInButtonClick}>
          Sign In
        </Button>
        <Button variant='outlined' className={styles.signInButton} onClick={onSignUpButtonClick}>
          Sign Up
        </Button>
      </div>
    </div>
  )
}

export default WelcomePage
