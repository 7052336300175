import { Popover } from '@mui/material'
import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

/**
 * SelfTagPopup container
 *
 * @returns {React.ReactElement}
 */
export const Container = styled(Popover)({
  '& .MuiPaper-root': {
    zIndex: 1300,
    width: '350px',
    padding: '20px',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    borderColor: '#cdd7e1',
    borderRadius: '12px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)'
  },
  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    '& .title': {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '166%',
      textAlign: 'left',
      color: COLORS['neutral-700']
    },
    '& .close-icon': {
      cursor: 'pointer',
      fontSize: '22px',
      color: '#636b74'
    }
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyItems: 'flex-start',
    gap: '15px',
    '& .MuiInputBase-root': {
      width: '308px',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      color: COLORS['text-tertiary'],
      borderRadius: '8px',
      '& .MuiSvgIcon-root': {
        color: COLORS['neutral-400']
      },
      '& input': {
        padding: '9px'
      }
    },
    '& .image-section': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '6px',
      '& .checkbox-form-control': {
        marginRight: '0',
        '& .checkbox': {
          '& svg': {
            fontSize: '20px'
          }
        },
        '& .label': {
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          color: '#171A1C'
        }
      },
      '& .image-container': {
        width: '80px',
        height: '80px',
        overflow: 'hidden',
        '& img': {
          width: '80px',
          height: '160px',
          marginTop: '7px'
        }
      }
    },
    '& button.MuiButtonBase-root': {
      alignSelf: 'center',
      marginTop: '10px',
      width: '200px',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
      textAlign: 'left',
      color: 'white',
      textTransform: 'none',
      height: '40px'
    }
  }
})
