import { LinearProgress, styled } from '@mui/material'
import React from 'react'

import { CountdownRemaining } from '@/components/CountdownRemaining'
import { useProcessingProgressForVideo } from '@/store/processing'
import COLORS from '@/utils/colors'
import { row, column } from '@/utils/flexGrid'

const Container = styled('div')({
  width: '100%',
  maxWidth: '850px',
  borderRadius: '4px',
  border: `1px solid ${COLORS['neutral-200']}`,
  background: COLORS['neutral-100'],
  color: COLORS['neutral-700'],
  fontSize: 14,
  gap: 16,
  marginTop: 15,
  padding: 16,
  ...column,

  '& .row': {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    ...row
  },
  '& .progressText': {
    fontSize: 12
  }
})

/**
 * UploadProgress Component
 *
 * This component is used to display the upload progress of a file.
 *
 * Behavior:
 * - It listens for changes in the upload status to trigger a reload of the page when the upload succeeds.
 * - If the upload status is 'failed', the component renders nothing.
 * - Otherwise, it displays the current progress and estimated time remaining if the video is being processed on the backend.
 */
export default function ProcessingProgress ({ vid, workflowId, workflowIndex }) {
  const progress = useProcessingProgressForVideo(vid, workflowId, workflowIndex)
  const estimatedPercentDone = progress?.percentDone ?? 0
  const estimatedProcessingDoneEpoch = progress?.estimatedFinishEpoch
  return (
    <Container>
      <div className='row'>
        <p>Processing {Math.round(100 * estimatedPercentDone)}% completed</p>
        <p className='progressText'>
          <CountdownRemaining epoch={estimatedProcessingDoneEpoch} />
        </p>
      </div>
      <div className='progress'>
        <LinearProgress variant='determinate' value={100 * estimatedPercentDone} />
      </div>
    </Container>
  )
}
