import { styled } from '@mui/material'
import React from 'react'

import AppleLogo from '@/assets/app-store-logo.svg?react'
import AppleText from '@/assets/app-store-text.svg?react'
import GetOnGooglePlayImg from '@/assets/images/get-it-on-google-play.png'
import { useDeviceOS, DeviceOS } from '@/hooks/use-device-os'
import COLORS from '@/utils/colors'

const APP_STORE_LINK = 'https://apps.apple.com/us/app/pb-vision-uploader/id6467020610'
const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.pb.vision.app'

export default function GetOnMobile (props) {
  const { deviceOS } = useDeviceOS()

  const device = props.deviceOs || deviceOS

  function openStore () {
    const link = device === DeviceOS.IOS ? APP_STORE_LINK : GOOGLE_PLAY_LINK
    window.open(link, '_blank')
  }

  return (
    <Container onClick={openStore} style={{ ...props.style }} className={device !== DeviceOS.IOS && 'google'}>
      {device === DeviceOS.IOS
        ? (
          <>
            <AppleLogo />
            <AppleText />
          </>
          )
        : (
          <img src={GetOnGooglePlayImg} alt='' srcSet='' />
          )}
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  gap: '15px',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${COLORS['neutral-300']}`,
  borderRadius: '20px',
  padding: '10px',
  width: '240px',
  height: '85px',
  '& img': {
    width: '100%'
  },
  '&.google': {
    border: 'none'
  }
})
