/// <reference types="vite-plugin-svgr/client" />

import { styled } from '@mui/material/styles'
import React, { useCallback } from 'react'

import { BarChart } from '../bar-chart'
import { PlayerImage } from '../player-image'
import { TagPlayer } from '../tag-player'

import { Avatar } from '@/components/avatar'
import { CourtKitchen } from '@/components/court-kitchen'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useRenamePlayer } from '@/pages/home/hooks/use-rename-player'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'

/**
 * TeamStats container
 *
 * @returns {React.ReactElement}
 */
const Container = styled('div')({
  ...column,
  padding: '0 32px',

  '& .flex-1': {
    flex: 1
  },

  '& .column': {
    ...column
  },
  '& .row': {
    ...row
  },
  '& .grow': {
    flexGrow: 1
  },
  '& .justify-end': {
    justifyContent: 'flex-end'
  },
  '& .gap50': {
    gap: 50
  },
  '& .title': {
    color: COLORS['neutral-800'],
    fontSize: 20,
    fontWeight: 600
  },
  '& .team2 .title': {
    textAlign: 'right'
  },
  '& .player': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 600,
    color: COLORS['00-on-surface-high-emphasis']
  },
  '& .team2 .player': {
    justifyContent: 'flex-end'
  },
  '& .img': {
    '&:first-of-type': {
      // Separate player images vertically
      marginBottom: 8
    },

    // Place the avatar initials in the middle of the image
    '&.player1, &.player2': {
      '& div': {
        transform: 'translate(50%, -50%)',
        right: 0,
        bottom: 0
      }
    },
    '&.player3, &.player4': {
      '& div': {
        transform: 'translate(-50%, -50%)',
        left: 0,
        bottom: 0
      }
    },

    '& div': {
      position: 'absolute',
      height: 35,
      width: 35,
      alignItems: 'center',
      justifyContent: 'center',
      ...column
    }
  },
  '& .half-w': {
    width: '50%'
  },

  // For truncating purposes
  '& .mw0': {
    minWidth: 0
  },

  '& .ml20': {
    marginLeft: 20
  },
  '& .mt16': {
    marginTop: 16
  },
  '& .mt22': {
    marginTop: 22
  },
  '& .mr30': {
    marginRight: 30
  },
  '& .tar': {
    textAlign: 'right'
  },
  '& .body1': {
    color: COLORS['00-on-surface-high-emphasis'],
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
    paddingBottom: 4
  },
  '& .ml-auto': {
    marginLeft: 'auto'
  },
  '& .edit-ico': {
    cursor: 'pointer',
    fontSize: 18,
    color: '#BDBDBD',
    margin: '0 5px'
  },
  '& .player1': {
    color: COLORS.player1
  },
  '& .player2': {
    color: COLORS.player2
  },
  '& .player3': {
    color: COLORS.player3
  },
  '& .player4': {
    color: COLORS.player4
  },
  // Inline edit
  '& .i-edit': {
    maxHeight: 79,

    '& button span': {
      whiteSpace: 'nowrap',
      fontSize: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 120,
      width: 'unset !important'
    },
    // Inline edit size fix
    '& .editing input': {
      padding: '4px',
      maxWidth: 'calc(100% - 20px)'
    },
    // Fix right side edit - left padding
    '&.tar': {
      marginLeft: 10
    }
  },
  '& .team-b': {
    '& span:first-of-type': {
      justifyContent: 'flex-end'
    }
  },
  // Override bar-chart default font
  '& .bar-chart div': {
    lineHeight: '30px',
    padding: 0,
    '& > span': {
      fontSize: 18,
      fontWeight: 600
    }
  },
  '& .court-kitchen': {
    padding: '0 8px'
  },
  // Mobile overrides
  '&.mobile': {
    '& .teams': {
      flexDirection: 'column',
      '& > .title': {
        marginBottom: 14
      },
      '& > .column': {
        flexDirection: 'row'
      },
      // Move court to the end
      '& > .court-kitchen': {
        order: 3
      },
      // Update team 2 alignments
      '& .team-b': {
        '& button': {
          order: 2
        },
        '& .img > div': {
          left: 'unset',
          right: 0,
          transform: 'translate(50%, -50%)'
        },
        '& span:first-of-type': {
          justifyContent: 'flex-start'
        }
      }
    },
    '& .team2': {
      order: 2,
      '& .player': {
        marginLeft: 26
      }
    },
    '& .shot-distribution': {
      ...column,
      marginBottom: 50,

      '& .bar-title': {
        marginTop: 22,
        fontSize: 16,
        color: COLORS['00-on-surface-high-emphasis']
      }
    },

    // Align name to start when in mobile mode
    '& .justify-start': {
      justifyContent: 'start'
    }
  }
})

/**
 * shows stats of both teams
 */
export function TeamStats ({ insights, video, workflow }) {
  const isMobile = useMobileDetect()
  const { userData, vid } = video
  const { player_data: playerData } = insights
  const { aiEngineVersion } = workflow

  const renamePlayerIfNeeded = useRenamePlayer()
  const onRenamePlayer = useCallback(
    (index) => (update) => { renamePlayerIfNeeded(video, index, update.text) },
    [renamePlayerIfNeeded, video]
  )

  /* eslint-disable camelcase */
  const kitchenArrivals = playerData.map(({ role_data }) => {
    const calc =
      role_data.serving.oneself.kitchen_arrival && role_data.serving.oneself.total
        ? (role_data.serving.oneself.kitchen_arrival + role_data.serving.partner.kitchen_arrival) /
          (role_data.serving.oneself.total + role_data.serving.partner.total)
        : 0
    // [number of serving rallies where the player made it to the kitchen] / [number of total rallies where this player's team served] * 100%
    return round(calc * 100)
  })
  /* eslint-enable camelcase */

  return (
    <Container className={cls(isMobile && 'mobile')}>
      <div className='row teams'>
        {isMobile && <div className='title'>Team A Stats</div>}
        <div className='column flex-1 team-a mw0'>
          <div className='row mw0'>
            <div className='column'>
              <PlayerImage className='img player1' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={0} text={userData.players[0]?.name || 'Player 1'} onChange={onRenamePlayer(0)}>
                <Avatar width='35' initials={userData.players[0]?.name} round />
              </PlayerImage>
              <PlayerImage className='img player2' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={1} text={userData.players[1]?.name || 'Player 2'} onChange={onRenamePlayer(1)}>
                <Avatar width='35' initials={userData.players[1]?.name} round />
              </PlayerImage>
            </div>
            <div className='column grow mw0'>
              <div className='player grow ml20 i-edit'>
                <TagPlayer
                  truncate
                  vid={vid}
                  playerIdx={0}
                  playerName={userData.players[0]?.name}
                  aiEngineVersion={aiEngineVersion}
                />
              </div>
              <div className='player grow ml20 i-edit'>
                <TagPlayer
                  truncate
                  vid={vid}
                  playerIdx={1}
                  playerName={userData.players[1]?.name}
                  aiEngineVersion={aiEngineVersion}
                />
              </div>
            </div>
          </div>
        </div>
        {isMobile && (
          <div className='shot-distribution'>
            <div className='bar-title'>Shot Distribution</div>
            <BarChart values={[{ value: playerData[0].total_team_shot_percentage, fillColor: COLORS.player1 }]} emptySpaceColor={COLORS.player2} />
            <div className='bar-title'>Left side play</div>
            <BarChart values={[
              { value: playerData[0].left_side_percentage, fillColor: COLORS.player1 },
              { value: playerData[1].left_side_percentage, fillColor: COLORS.player2 }
            ]}
            />
          </div>
        )}
        <CourtKitchen values={kitchenArrivals} />
        {isMobile && <div className='title'>Team B Stats</div>}
        <div className='column flex-1 team-b mw0'>
          <div className='row mw0 justify-end'>
            <div className='column team2 grow mw0'>
              <div className='player grow mr30 tar i-edit justify-start'>
                <TagPlayer
                  truncate
                  vid={vid}
                  playerIdx={2}
                  playerName={userData.players[2]?.name}
                  aiEngineVersion={aiEngineVersion}
                />
              </div>
              <div className='player grow mr30 tar i-edit justify-start'>
                <TagPlayer
                  truncate
                  vid={vid}
                  playerIdx={3}
                  playerName={userData.players[3]?.name}
                  aiEngineVersion={aiEngineVersion}
                />
              </div>
            </div>
            <div className='column'>
              <PlayerImage className='img player3' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={2} text={userData.players[2]?.name || 'Player 3'} onChange={onRenamePlayer(2)}>
                <Avatar width='35' initials={userData.players[2]?.name} round />
              </PlayerImage>
              <PlayerImage className='img player4' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={3} text={userData.players[3]?.name || 'Player 4'} onChange={onRenamePlayer(3)}>
                <Avatar width='35' initials={userData.players[3]?.name} round />
              </PlayerImage>
            </div>
          </div>
        </div>
        {isMobile && (
          <div className='shot-distribution'>
            <div className='bar-title'>Shot Distribution</div>
            <BarChart values={[{ value: playerData[2].total_team_shot_percentage, fillColor: COLORS.player3 }]} emptySpaceColor={COLORS.player4} />
            <div className='bar-title'>Left side play</div>
            <BarChart values={[
              { value: playerData[2].left_side_percentage, fillColor: COLORS.player3 },
              { value: playerData[3].left_side_percentage, fillColor: COLORS.player4 }
            ]}
            />
          </div>
        )}
        {isMobile && <div className='title'>Kitchen Arrival</div>}
      </div>
      {!isMobile && (
        <>
          <div className='row body1 mt16'>
            <div>Shot Distribution</div>
            <div className='ml-auto'>Shot Distribution</div>
          </div>
          <div className='row gap50'>
            <div className='column half-w'>
              <BarChart values={[{ value: playerData[0].total_team_shot_percentage, fillColor: COLORS.player1 }]} emptySpaceColor={COLORS.player2} />
            </div>
            <div className='column half-w'>
              <BarChart values={[{ value: playerData[2].total_team_shot_percentage, fillColor: COLORS.player3 }]} emptySpaceColor={COLORS.player4} />
            </div>
          </div>
          <div className='row body1 mt22'>
            <div>Left side play</div>
            <div className='ml-auto'>Left side play</div>
          </div>
          <div className='row gap50'>
            <div className='column half-w'>
              <BarChart values={[
                { value: playerData[0].left_side_percentage, fillColor: COLORS.player1 },
                { value: playerData[1].left_side_percentage, fillColor: COLORS.player2 }
              ]}
              />
            </div>
            <div className='column half-w'>
              <BarChart values={[
                { value: playerData[2].left_side_percentage, fillColor: COLORS.player3 },
                { value: playerData[3].left_side_percentage, fillColor: COLORS.player4 }
              ]}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
