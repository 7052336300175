import BiotechIcon from '@mui/icons-material/Biotech'
import CalendarToday from '@mui/icons-material/CalendarToday'
import CreditCard from '@mui/icons-material/CreditCard'
import FlagIcon from '@mui/icons-material/FlagOutlined'
import HelpOutline from '@mui/icons-material/HelpOutline'
import MenuBook from '@mui/icons-material/MenuBook'
import PersonIcon from '@mui/icons-material/Person'
import { styled } from '@mui/material/styles'
import { useEffect, useLayoutEffect } from 'react'
import { useLocation, useNavigate, Outlet, Link } from 'react-router-dom'

import Sidebar from './sidebar'

import DiscordLogo from '@/assets/icons/discord-logo-full-blue.svg?react'
import { Button } from '@/components/button'
import { IosFooter } from '@/components/ios-footer'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { useIsAuthInitialized, useIsLoggedIn, useUserHasFlag } from '@/store/auth'
import { isProd } from '@/utils'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column } from '@/utils/flexGrid'

export function useSettingsMenuItems () {
  const isTester = useUserHasFlag('tester')

  const settings = [
    {
      id: 'main',
      icon: PersonIcon,
      title: 'Account'
    },
    {
      id: 'subscription',
      icon: CalendarToday,
      title: 'Subscription'
    },
    {
      id: 'credits',
      icon: CreditCard,
      title: 'Credits'
    },
    {
      id: 'ambassador',
      icon: FlagIcon,
      title: 'Ambassador'
    }
  ]

  if (isTester && !isProd) {
    settings.push({
      id: 'test',
      icon: BiotechIcon,
      title: 'Test'
    })
  }

  return settings
}

const Container = styled('div')({
  display: 'flex !important',
  width: '100%',
  paddingTop: '24px !important',
  paddingBottom: '0 !important',
  '& .innerContainer': {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 72px)',

    '& .content': {
      flex: 1,
      padding: '0 24px 24px',
      '&.mobile': {
        padding: '0 16px 24px'
      }
    }
  },
  '& .need-help': {
    ...column,
    marginTop: '48px',
    width: '100%',
    backgroundColor: COLORS['neutral-100'],
    border: `1px solid ${COLORS['neutral-300']}`,
    borderRadius: '8px',
    gap: '10px',
    padding: '16px 24px',
    '& .help-buttons': {
      ...column,
      gap: '8px'
    }
  }
})

function Settings () {
  const isMobile = useMobileDetect()
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const { toggleZendeskWidget } = useZendesk()
  const items = useSettingsMenuItems()

  const isAuthReady = useIsAuthInitialized()
  const isLoggedIn = useIsLoggedIn()

  useEffect(() => {
    if (isAuthReady && !isLoggedIn) {
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthReady, isLoggedIn])

  useLayoutEffect(() => {
    if (pathname === '/settings/account') {
      isMobile ? navigate('/settings/choose') : navigate('/settings/main')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  function needHelpSection () {
    return (
      <div className='need-help'>
        <h3>Need Help?</h3>
        <p className='body sm'>
          Have questions or feedback for us? We’re listening.
        </p>
        <div className='help-buttons'>
          <Button LinkComponent={Link} to='https://roadmap.pb.vision/help' target='_blank' className='gray'>FAQ <MenuBook style={{ color: COLORS['primary-500'] }} /></Button>
          <Button className='gray' onClick={toggleZendeskWidget}>Contact Support <HelpOutline style={{ color: COLORS['primary-500'] }} /></Button>
          <Button LinkComponent={Link} to='https://discord.com/invite/N73jSVDpwK' target='_blank' className='gray'><DiscordLogo /></Button>
        </div>
      </div>
    )
  }

  return (
    <Container>
      <div className='innerContainer'>
        {isLoggedIn && (
          <>
            {!isMobile && <Sidebar items={items} />}
            <div className={cls('content', isMobile && 'mobile')}>
              <Outlet />
              {pathname !== '/settings/choose' && needHelpSection()}
              {(pathname === '/settings/subscription' || pathname === '/settings/credits') && <IosFooter />}
            </div>
          </>
        )}
      </div>
    </Container>
  )
}

export default Settings
