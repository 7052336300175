import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { useIsBuyingEnabled } from './monetization'

import { authSlice } from '@/store/auth'
import { isInMobileAppWebview, promptPurchaseOnNativeApp } from '@/utils/mobile-app-communication'

export function usePurchaseFlow () {
  const dispatch = useDispatch()
  const [skuToBuy, setSKUToBuy] = useState()
  const isBuyingEnabled = useIsBuyingEnabled()

  function buySKU (sku) {
    dispatch(authSlice.actions.purchaseStarted())
    if (isInMobileAppWebview()) {
      if (!isBuyingEnabled) {
        return
      }
      promptPurchaseOnNativeApp(sku)
    } else {
      setSKUToBuy(sku)
    }
  }
  return {
    clearSKUToBuy: () => setSKUToBuy(),
    skuToBuy,
    setNumCreditsToBuy: n => buySKU(`credit${n}`),
    subscribeToPlan: tier => buySKU(`annual_${tier}`)
  }
}
