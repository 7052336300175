import { styled } from '@mui/material/styles'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { usePlayersShotQuality } from '../../hooks/use-players-shot-quality'

import OverviewPlayerPerformanceSection from './sections/player-performance'
import OverviewTeamsSection from './sections/teams'

import { Divider } from '@/components/divider'

/**
 * Overview component
 *
 * @exports
 * @returns {React.ReactElement} Overview component
 */
export function Overview ({ insights, video, workflow }) {
  const { vid } = useParams()

  const { getShotQualityForAllShotsPerPlayer } = usePlayersShotQuality()

  const { aiEngineVersion } = workflow

  const shotsByQualityPerPlayer = useMemo(() => {
    const qualityPerPlayerObj = getShotQualityForAllShotsPerPlayer(insights)

    return qualityPerPlayerObj
  }, [getShotQualityForAllShotsPerPlayer, insights])

  return (
    <Container>
      <OverviewTeamsSection vid={vid} aiEngineVersion={aiEngineVersion} video={video} insights={insights} />
      <Divider sx={{ margin: '0 32px 32px', width: 'unset' }} />
      <OverviewPlayerPerformanceSection shotsByQualityPerPlayer={shotsByQualityPerPlayer} vid={vid} aiEngineVersion={aiEngineVersion} video={video} insights={insights} />
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '16px'
})
