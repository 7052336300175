import * as R from 'ramda'

function includeCourtPointsOnFirstFrameOnly (frames) {
  return R.mapObjIndexed((value, key) => {
    if (parseInt(key) === 0) {
      return value
    }
    return R.dissocPath(['court', 'court_points'], value)
  }, frames)
}

// TODO move this to a server-side function
export function createOverlaysFromCv (cv, options = { debug: false }) {
  return R.pipe(
    R.dissoc('players'),
    R.evolve({
      rallies: R.map(R.evolve({
        frames: R.pipe(
          includeCourtPointsOnFirstFrameOnly,
          R.map(R.pipe(
            R.dissoc('confidence')
          ))
        )
      }))
    })
  )(cv)
}

export function createRallyFramesByIndex (rallies) {
  const framesByIndex = {}
  for (const rally of rallies) {
    for (const [key, frame] of Object.entries(rally.frames)) {
      framesByIndex[rally.frame_index + parseInt(key)] = frame
    }
  }
  return framesByIndex
}
