import DebugAsPage from './debug-as'

import Page from './index'

export default [
  {
    path: '/login',
    element: <Page />
  },
  {
    path: '/debug_as',
    element: <DebugAsPage />
  }
]
