import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton, styled } from '@mui/material'

import { Tooltip } from '../tooltip'

import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export function ShotsDialog ({ onClose, playerName, buttons, playerIdx, title, sequences }) {
  const isMobile = useMobileDetect()
  const params = {
    sequences
  }

  return (
    <ShotsDialogContainer>
      <div className='head'>
        <p>{title || 'All shots'}</p>
        <IconButton className='close-button' onClick={onClose} title='Close'>
          <CloseIcon className='close-icon' />
        </IconButton>
      </div>
      <p className='player-name'>{playerName}</p>
      <div className='content'>
        {buttons && buttons.map((button, i) => {
          if (!button.shotCount) return null
          return (
            <Tooltip key={i} disabled={isMobile} title={`Click to view all ${button.label} shots in Shot Explorer`}>
              <div className='shot-group' onClick={() => button.callback(i, playerIdx, params)}>
                <VisibilityIcon style={{ color: button.color }} />
                <p>{button.label}</p>
                <p>{button.shotCount}</p>
              </div>
            </Tooltip>
          )
        })}
      </div>
    </ShotsDialogContainer>
  )
}

const ShotsDialogContainer = styled('div')({
  ...column,
  padding: '16px 24px',
  backgroundColor: COLORS['neutral-050'],
  border: `1px solid ${COLORS['neutral-300']}`,
  '& .head': {
    ...row,
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '20px',
    fontWeight: 600
  },
  '& .player-name': {
    fontSize: '14px',
    fontWeight: 600,
    marginTop: '-5px',
    color: COLORS['text-tertiary'],
    marginBottom: 20
  },
  '& .content': {
    ...column,
    gap: '8px',
    '& .shot-group': {
      ...row,
      width: '180px',
      cursor: 'pointer',
      padding: '8px 16px',
      backgroundColor: COLORS['neutral-100'],
      borderRadius: '8px',
      border: `1px solid ${COLORS['neutral-300']}`,
      gap: 8,
      transition: '300ms',
      '& p': {
        fontSize: '14px',
        fontWeight: 600,
        '&:last-child': {
          marginLeft: 'auto'
        }
      },
      '&:hover': {
        backgroundColor: COLORS['neutral-200']
      }
    }
  }

})
