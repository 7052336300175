export function sortByEpochDESC (items) {
  const sorted = items.sort((a, b) => {
    return b.epoch - a.epoch
  })

  return sorted
}

function returnMainFolderItems (items) {
  function query (item) {
    if (item?.vid) {
      return !item.fid
    } else {
      return !item?.parent
    }
  }

  return items.filter(item => query(item))
}

function returnSubFolderItems (items, fid) {
  function query (item) {
    if (item?.vid) {
      return item.fid === fid
    } else {
      return item.parent === fid
    }
  }

  return items.filter(item => query(item))
}

export function displayItemsByFolder (items, fid) {
  let folderItems = []

  // If fid does not exist, we are in the main folder
  if (!fid) {
    const mainFolder = returnMainFolderItems(items)

    folderItems = mainFolder
  } else {
    // Otherwise when fid is specified we are in some of sub-folders
    folderItems = returnSubFolderItems(items, Number(fid))
  }

  return sortByEpochDESC(folderItems)
}
