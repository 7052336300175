import styled from '@emotion/styled'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../button/index'

import ArrowLeftIcon from '@/assets/arrow-left.svg?react'
import BugIcon from '@/assets/bug-icon.svg?react'
import emojiUrl from '@/assets/emoji-sad.png'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

/**
 * The `SomethingWentWrong` component renders a fallback UI when an error boundary catches an error.
 * It displays a sad emoji, an error message, and provides buttons for navigating back or contacting support (Zendesk).
 *
 * @param {object} props - The component props.
 * @param {Function} props.resetErrorBoundary - A function to reset the error boundary state.
 * @param {object} error - The error object that was caught.
 */
export function SomethingWentWrong (props) {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const { toggleZendeskWidget } = useZendesk()

  function onBackToLibraryHandler () {
    props.resetErrorBoundary()
    navigate('/library')
  }

  function onContactUsHandler () {
    toggleZendeskWidget()
  }

  return (
    <Container className={isMobile && 'mobile'}>
      {process.env.NODE_ENV !== 'production' && (
        <div className='developer'>
          <h3>
            <strong>Web app crashed</strong>
          </h3>
          <div className='infos'>
            <div className='info head'>
              <p>Details (only shown on the test server)</p>
            </div>
            <div className='info'>
              <strong>Error message:</strong>
              <p>{props?.errorInfo?.message}</p>
            </div>
            <div className='info'>
              <strong>Error type:</strong>
              <p>{props?.errorInfo?.type}</p>
            </div>
            <div className='info'>
              <strong>Timestamp:</strong>
              <p>{new Date().toISOString()}</p>
            </div>
            <div className='info head'>
              <p>Environment</p>
            </div>
            <div className='info'>
              <strong>Browser:</strong>
              <p>{navigator.userAgent}</p>
            </div>
            <div className='info'>
              <strong>Server url:</strong>
              <p>{import.meta.env.VITE_API_SERVER}</p>
            </div>
            <div className='info'>
              <strong>Browser:</strong>
              <p>{navigator.userAgent}</p>
            </div>
            <div className='info'>
              <strong>Screen Resolution:</strong>
              <p>{`${window.screen.width}x${window.screen.height}`}</p>
            </div>
            <div className='info head'>
              <p>Stack info</p>
            </div>
            <div className='info'>
              <strong>Error stack:</strong>
              <pre>{props?.errorInfo?.stack} </pre>
            </div>
            <div className='info'>
              <strong>Component stack:</strong>
              <pre>{props?.errorInfo?.componentStack}</pre>
            </div>
          </div>
        </div>
      )}
      <img src={emojiUrl} alt='sad emoji' />
      <strong>Something went wrong...</strong>
      <p>Our team has been notified.</p>
      <p>Your experience is top priority – rest assured, we’re on it!</p>
      <div className='buttons'>
        <Button className='icon-left green' onClick={() => onBackToLibraryHandler()}>
          <ArrowLeftIcon /> Back to Library
        </Button>
        <Button className='gray' onClick={() => onContactUsHandler()}>
          Contact Us <BugIcon />
        </Button>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  textAlign: 'center',
  alignItems: 'center',
  height: 'fit-content',
  justifyContent: 'center',
  color: COLORS['neutral-700'],
  fontSize: 14,
  marginTop: 16,
  padding: 32,
  ...column,
  '& img': {
    height: 114
  },
  '& strong': {
    fontSize: 30,
    marginBlock: 16
  },
  '& p': {
    fontSize: 16
  },
  '& .buttons': {
    gap: 16,
    marginBlock: 32,

    ...row,
    '& .back': {
      color: COLORS.white,
      backgroundColor: COLORS['success-700']
    }
  },
  '& .developer': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '32px',
    padding: '8px 16px 16px',
    alignItems: 'center',
    borderRadius: '12px',
    border: `1px solid ${COLORS['warning-500']}`,
    background: COLORS['warning-050'],
    '& .infos': {
      flex: 1,
      paddingBlock: '8px',
      textAlign: 'start',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      borderRadius: '12px',
      width: '100%',
      border: `1px solid ${COLORS['warning-500']}`,
      backgroundColor: COLORS.white,
      '& .info': {
        display: 'flex',
        height: 'fit-content',
        overflowX: 'auto',
        paddingInline: '8px',
        alignItems: 'flex-start',
        gap: 8,
        ':nth-of-type(2n)': {
          backgroundColor: COLORS['neutral-300']
        },
        '& strong': {
          minWidth: '100px',
          width: '100px',
          fontSize: 14,
          marginBlock: 0,
          color: COLORS.black
        },
        '& p': {
          color: COLORS['neutral-700']
        },
        '&.head': {
          marginBottom: '8px'
        }
      }
    }
  },

  '&.mobile': {
    paddingInline: '8px !important'
  }
})
