import { Popover } from '@mui/material'
import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

export const Container = styled(Popover)({
  '& .MuiPaper-root': {
    zIndex: 1300,
    width: '510px',
    maxWidth: '100%',
    padding: '20px',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fbfcfe',
    borderColor: '#cdd7e1',
    borderRadius: '12px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)'
  },
  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title-section': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '10px',
      '& .avatar': {
        minWidth: '35px',
        height: '35px',
        borderRadius: '50%',
        backgroundColor: COLORS.player1,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '& .title': {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '166%',
        textAlign: 'left',
        color: COLORS['neutral-700']
      }
    },
    '& .close-icon': {
      cursor: 'pointer',
      fontSize: '22px',
      color: '#636b74'
    }
  },
  '& .content': {
    display: 'flex',
    gap: '16px',
    '& .player-images': {
      display: 'flex',
      height: 'fit-content',
      border: `3px solid ${COLORS.player1}`,
      borderRadius: '4px',
      '& img': {
        width: '55px',
        height: '106px'
      }
    },
    '& .button-section': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      '& .MuiButtonBase-root': {
        borderRadius: '8px',
        border: `1px solid ${COLORS['neutral-300']}`,
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '100%',
        textAlign: 'left',
        color: COLORS['neutral-700'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textTransform: 'none',
        '& .MuiSvgIcon-root': {
          fontSize: '24px'
        }
      },
      '& .save-without-tagging': {
        fontFamily: 'Inter',
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '142%',
        color: COLORS['primary-600'],
        marginTop: '6px',
        cursor: 'pointer'
      }
    }
  },
  '&.mobile': {
    '& .player-images': {
      '& img': {
        width: '40px',
        height: '90px'
      }
    }
  }
})
