import { Dialog, styled } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import StatsSectionTitle from '../components/stats-section-title'

import { useNthShotsForPlayerWithContext } from './selected-shots-viewer'

import { BarChart } from '@/components/bar-chart'
import { ShotsDialog } from '@/components/dialog/shots-dialog'
import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { scrollToTopOfMain } from '@/utils/helpers/scroll-to-top'

function SingleGraph (props) {
  const ref = useRef(null)
  const counterObj = {
    in: 0,
    out: 0,
    net: 0
  }
  const shotSuccessRateValues = useMemo(() => {
    props.shots.forEach(shot => {
      const errors = shot.shot.errors
      if (errors) {
        if (errors.faults.net) {
          counterObj.net++
        } else {
          counterObj.out++
        }
        return
      }
      counterObj.in++
    })

    return {
      in: ((counterObj.in / props.shots.length) * 100).toFixed(3),
      out: ((counterObj.out / props.shots.length) * 100).toFixed(3),
      net: ((counterObj.net / props.shots.length) * 100).toFixed(3)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shots])

  return (
    <SingleGraphContainer ref={ref} className={props.className}>
      <div className='head'>
        <strong>{props.title}</strong>
        <p>Total: {props.shots.length}</p>
      </div>
      <BarChart
        style={{ cursor: 'pointer' }}
        onClick={() => props.onOpenDialog(counterObj, props.playerIdx, ref, props.dialogTitle, props.sequences)}
        values={[
          { value: shotSuccessRateValues.in, customValue: `In (${Number(shotSuccessRateValues.in).toFixed(0)}%)`, fillColor: COLORS['Quality_scale-Excellent'], textColor: COLORS.black },
          { value: shotSuccessRateValues.net, fillColor: COLORS.pink, customValue: 'Net' },
          { value: shotSuccessRateValues.out, fillColor: COLORS['Quality_scale-Poor'], customValue: 'Out' }
        ]}
      />
    </SingleGraphContainer>
  )
}

export default function ServesReturnsViewer (props) {
  const isMobile = useMobileDetect()
  const userData = props.userData
  const viewDirection = isMobile ? 'column' : 'row'
  const [dialogData, setDialogData] = useState(null)
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 })
  const navigate = useNavigate()
  const { vid } = useParams()

  const serves = useNthShotsForPlayerWithContext(props.insights, props.playerIdx, 'all', 1)
  const returns = useNthShotsForPlayerWithContext(props.insights, props.playerIdx, 'all', 2)

  function createExploreUrl (typeId, playerId, paramList) {
    const types = ['in', 'net', 'out']
    const params = new URLSearchParams()

    // Add type parameter
    params.append('types', types[typeId])

    // Add sequences parameter
    params.append('sequences', paramList.sequences)

    // Add players[0] parameter
    params.append('players[0]', playerId)

    // Construct the full URL
    const url = `explore?${params.toString()}`
    return url
  }

  function onRedirectToShotExplorer (level, playerIdx, paramList) {
    const url = createExploreUrl(level, playerIdx, paramList)
    scrollToTopOfMain()
    navigate(`/video/${vid}/${url}`)
  }

  function handleSingleChartClick (counterObj, playerIdx, ref, title, sequences) {
    if (ref.current) {
      const playerName = userData.players[playerIdx]?.name || `Player ${playerIdx + 1}`
      const rect = ref.current.getBoundingClientRect()

      const buttonsForShotDialog = [
        {
          color: COLORS['Quality_scale-Excellent'],
          label: 'In',
          shotCount: counterObj.in,
          callback: onRedirectToShotExplorer
        },
        {
          color: COLORS.pink,
          label: 'Net',
          shotCount: counterObj.net,
          callback: onRedirectToShotExplorer
        },
        {
          color: COLORS['Quality_scale-Poor'],
          label: 'Out',
          shotCount: counterObj.out,
          callback: onRedirectToShotExplorer
        }
      ]

      // Calculate position above the clicked element
      setDialogPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width
      })
      setDialogData({ buttonsForShotDialog, playerIdx, playerName, title, sequences })
    }
  }

  const dialogDesktopStyle = {
    position: 'absolute',
    top: '50%', // Vertically center the dialog
    left: dialogPosition.left + (dialogPosition.width / 2), // Horizontally position based on clicked element
    transform: 'translateY(-50%) translateX(-50%)',
    margin: 0
  }

  function onCloseDialog () {
    setDialogData(null)
  }

  return (
    <Container>
      <div className='head'>
        <StatsSectionTitle title='Serves & Returns' />
      </div>
      <div className={cls('content', viewDirection)}>
        <SingleGraph title='Serves In / Out' dialogTitle='Serve' onOpenDialog={handleSingleChartClick} sequences='serve' shots={serves} player={props.player} playerIdx={props.playerIdx} className={viewDirection} />
        <SingleGraph title='Returns In / Out' dialogTitle='Return' onOpenDialog={handleSingleChartClick} sequences='return' shots={returns} playerIdx={props.playerIdx} className={viewDirection} />
      </div>
      <Dialog
        onBackdropClick={onCloseDialog}
        open={Boolean(dialogData)}
        PaperProps={{
          style: !isMobile ? dialogDesktopStyle : {}
        }}
      >
        <ShotsDialog buttons={dialogData?.buttonsForShotDialog} onClose={onCloseDialog} playerIdx={dialogData?.playerIdx} playerName={dialogData?.playerName} title={dialogData?.title} sequences={dialogData?.sequences} />
      </Dialog>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  gap: '32px',
  padding: '32px',
  '& .head': {
    ...row,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .select-view': {
      ...row,
      alignItems: 'center',
      gap: '10px',
      '& svg': {
        '& path': {
          fill: COLORS['neutral-300']
        },
        '&.selected path': {
          fill: COLORS['neutral-400']
        },
        '&:hover path': {
          fill: COLORS['neutral-400']
        }
      }
    }
  },
  '& .content': {
    ...row,
    gap: '30px',
    transition: 'flex-direction 0.5s ease',
    '&.column': {
      ...column
    }
  }
})

const SingleGraphContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column !important',
  flex: 1,
  gap: '8px',
  '& .head': {
    '& p': {
      fontSize: '14px',
      fontWeight: 700,
      color: COLORS['neutral-500']
    },
    '& strong': {
      color: COLORS['neutral-800']
    }
  }
})
