import React from 'react'
import { Navigate } from 'react-router-dom'

import { useIsLoggedIn } from '@/store/auth'

const RouteGuard = ({ children, redirectTo }) => {
  const isLoggedIn = useIsLoggedIn()

  return isLoggedIn ? children : <Navigate to={redirectTo} />
}

export default RouteGuard
