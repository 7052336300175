import { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Container } from './container'

import { TextButton } from '@/components/button/text-button'
import { signOut } from '@/store/auth'
import cls from '@/utils/classnames'

function Sidebar ({ items }) {
  const [activeId, setActiveId] = useState('account')
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [isSigningOut, setIsSigningOut] = useState(false)

  function signOutWrapper () {
    setIsSigningOut(true)
    signOut().finally(() => setIsSigningOut(false))
  }

  function onTabClickHandler (path) {
    setActiveId(path)
    navigate(`/settings/${path}`)
  }

  // Set the active tab based on the current path
  useLayoutEffect(() => {
    const path = window.location.pathname.split('/').pop()
    setActiveId(path)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  const renderItems = () => (
    <>
      {items.map((item) => {
        const { title, icon: Icon, id, badge } = item
        const isActive = id === activeId
        return (
          <div key={id} className={cls('item', isActive && 'active')} onClick={() => onTabClickHandler(id)}>
            <Icon className='icon' />
            <div className='titleSection'>
              <div className='title'>{title}</div>
              {badge}
            </div>
          </div>
        )
      })}
    </>
  )

  return (
    <Container className='sidebar'>
      <div className='items'>{renderItems()}</div>
      <TextButton
        className='signOut'
        onClick={() => {
          signOutWrapper()
        }}
      >
        Sign out
      </TextButton>
    </Container>
  )
}

export default Sidebar
