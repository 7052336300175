import CloseIcon from '@mui/icons-material/Close'
import Slider from '@mui/material/Slider'
import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'

import { Dialog } from './container'

import { Button } from '@/components/button'
import { useAvatar } from '@/hooks/use-avatar'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useSnackbar } from '@/store/providers/snackbar-provider'

const UPLOAD_IMAGE_VIEW = 'upload-image-view'
const EDIT_IMAGE_VIEW = 'edit-image-view'
const INITIAL_VIEW = UPLOAD_IMAGE_VIEW
const MINIMUM_IMAGE_SCALE = 1
/**
 * UserImageDialog component
 *
 * @exports
 * @param props {object} {open, onClose }
 * @returns {React.ReactElement}
 */
export function UserImageDialog (props) {
  const isMobile = useMobileDetect()
  const openSnackbar = useSnackbar()
  const { open } = props
  const editor = useRef(null)
  const [view, setView] = useState(INITIAL_VIEW)
  const title = view === UPLOAD_IMAGE_VIEW ? 'Upload your image' : 'Crop your image, then click save'
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageScale, setImageScale] = useState(MINIMUM_IMAGE_SCALE)
  const { UserAvatar, setAvatar, hasAvatar } = useAvatar()

  const onClose = (event, reason) => {
    props.onClose()
  }

  useEffect(() => {
    if (open) {
      setSelectedImage(null)
      setImageScale(MINIMUM_IMAGE_SCALE)
      setView(INITIAL_VIEW)
    }
  }, [open])

  const validateImage = (file) => {
    if (!file.type.startsWith('image/')) {
      openSnackbar('Selected file is not an image', 'error')
      return false
    } else {
      return true
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    if (validateImage(file)) {
      setSelectedImage(file)
      setView(EDIT_IMAGE_VIEW)
    }
  }

  const renderUploadImageView = () => {
    const hasAvatarImage = hasAvatar()
    return (
      <>
        <div className='upload-background'>
          <UserAvatar
            isPlaceholder={!hasAvatarImage}
            className={clsx([{ 'avatar-image': hasAvatarImage }])}
          />
        </div>
        <Button component='label' className='gray-filled'>
          Select profile pic
          <input
            type='file'
            accept='image/*'
            hidden
            onChange={handleImageChange}
          />
        </Button>
      </>
    )
  }

  const onSave = async () => {
    await setAvatar(editor.current.getImage())
    props.onClose()
  }

  const renderEditImageView = () => (
    <>
      <div className='editor-container'>
        <AvatarEditor
          ref={editor}
          image={selectedImage}
          width={260}
          height={260}
          borderRadius={130}
          border={150}
          color={[255, 255, 255, 0.6]}
          scale={imageScale}
          rotate={0}
        />
      </div>
      <div className='slider-container'>
        <Slider
          className='slider'
          min={MINIMUM_IMAGE_SCALE}
          max={3}
          step={0.005}
          value={imageScale}
          color='midnight'
          onChange={(event, newValue) => setImageScale(newValue)}
        />
      </div>
      <Button className='green' onClick={onSave}>
        Save
      </Button>
    </>
  )

  const render = () => (
    <>
      <div className='header'>
        <span className='title'>{title}</span>
        <CloseIcon className='close-icon' onClick={onClose} />
      </div>
      <div className='content'>
        {view === UPLOAD_IMAGE_VIEW
          ? renderUploadImageView()
          : renderEditImageView()}
      </div>
    </>
  )

  return (
    <Dialog
      open={open}
      onClose={props.onClose}
      classes={{
        paper: clsx([{ mobile: isMobile }, view])
      }}
    >
      {render()}
    </Dialog>
  )
}
