/* eslint-disable react/jsx-no-constructed-context-values */
import Slide from '@mui/material/Slide'
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'

import { AlertBanner } from '@/components/alerts/banner'

const AlertBannerContext = createContext()

export const useAlertBanner = () => useContext(AlertBannerContext)

/**
 * Provider component for alert banners.
 */
export function AlertBannerProvider ({ children }) {
  const [alerts, setAlerts] = useState([])
  const [dismissed, setDismissed] = useState([])
  const nodeRef = useRef(null)

  /**
   * Shows an alert banner with given parameters.
   *
   * @param {object} options - Options for the alert banner.
   * @param {string} options.id - The custom ID of the alert, default is dummy.
   * @param {string} options.title - The title of the alert.
   * @param {string} [options.message] - The descriptive message of the alert, default is an empty string.
   * @param {string} [options.type] - The type of the alert (e.g., 'info', 'warning', 'success'), default is 'info'.
   * @param {boolean} [options.autoDismiss] - Whether the alert should dismiss automatically - on screen change.
   * @param {boolean} [options.isAnimated] - Whether the alert should be animated
   */
  const showAlert = useCallback(
    ({ id = new Date(), title, message = '', type = 'info', actions, autoDismiss = false, permanent = false, dismissibleOnMobile = false, isAnimated = false }) => {
      // Prevent displaying the same message multiple times (fallback to title comparison if message is not string (react component))
      const isAlertExists = alerts.some((alert) => typeof alert.message === 'string' ? alert.message === message : alert.title === title)
      // Prevent displaying the same message after it was dismissed in the same session
      const isDismissed = dismissed.some((dismissedId) => dismissedId === id)
      if (isAlertExists || isDismissed) {
        return
      }
      setAlerts((currentAlerts) => [...currentAlerts, { id, type, title, message, actions, autoDismiss, permanent, dismissibleOnMobile, isAnimated }])
    },
    [alerts, dismissed]
  )

  const hideAlert = useCallback((id) => {
    setAlerts((currentAlerts) => currentAlerts.map((alert) => {
      if (alert.id === id) return { ...alert, isDismissed: true }
      return alert
    }))
  }, [])

  const removeAlert = useCallback((id) => {
    setAlerts((currentAlerts) => currentAlerts.filter((alert) => {
      if (alert.id !== id) return true
      if (!alert.isAnimated) return false
      return !alert.isDismissed
    }))
    setDismissed((currentDismissed) => [...currentDismissed, id])
  }, [])

  const intoView = () => {
    if (nodeRef?.current) {
      // Be sure banner has done with animation before showing it
      setTimeout(() => {
        const rect = nodeRef.current.getBoundingClientRect()
        const doc = document.documentElement
        // if element in viewport don't scroll into view
        if (
          !(rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || doc.clientHeight) &&
            rect.right <= (window.innerWidth || doc.clientWidth))
        ) {
          nodeRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    }
  }

  const ShowAlerts = useMemo(() => {
    return alerts.map((alert, _) => {
      if (alert.isAnimated) {
        return (
          <Slide key={alert.id} nodeRef={nodeRef} direction='down' in={!alert.isDismissed} onExited={() => removeAlert(alert.id)} onEntered={intoView} mountOnEnter unmountOnExit>
            <AlertBanner
              id={alert.id}
              ref={nodeRef}
              onRemove={hideAlert}
              title={alert.title}
              message={alert.message}
              actions={alert.actions}
              autoDismiss={alert.autoDismiss}
              dismissibleOnMobile={alert.dismissibleOnMobile}
              permanent={alert.permanent}
              type={alert.type}
              oneLine={alert.oneLine}
            />
          </Slide>
        )
      }
      return (
        <AlertBanner
          key={alert.id}
          id={alert.id}
          onRemove={hideAlert}
          title={alert.title}
          message={alert.message}
          autoDismiss={alert.autoDismiss}
          dismissibleOnMobile={alert.dismissibleOnMobile}
          permanent={alert.permanent}
          type={alert.type}
          oneLine={alert.oneLine}
        />
      )
    })
  }, [alerts, hideAlert, removeAlert])

  return (
    <AlertBannerContext.Provider value={{ alerts, showAlert, hideAlert, removeAlert }}>
      {ShowAlerts}
      {children}
    </AlertBannerContext.Provider>
  )
}
