import { CircularProgress } from '@mui/material'

export default function WebviewLoadingPage () {
  return (
    <CircularProgress
      variant='indeterminate'
      size='60%'
      sx={{ marginTop: '1rem', opacity: 0.5 }}
    />
  )
}
