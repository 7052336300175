import { styled } from '@mui/material/styles'

/**
 * Simple divider used to spread empty space in flexbox containers
 *
 * @exports
 * @returns {React.ReactElement}
 */
export const Spacer = styled('div')({
  flexGrow: 1
})
