import Page from './index'

import RouteGuard from '@/utils/route-guard'

export default [
  {
    path: '/library/:fid?',
    element: <RouteGuard redirectTo='/'><Page /></RouteGuard>
  },
  {
    path: '/library/public/:uid/:fid?',
    element: <Page />
  }
]
