import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useLocationChange = () => {
  const location = useLocation()
  const [previousLocation, setPreviousLocation] = useState(null)

  const pathnames = {
    '/': () => {
      if (location.pathname === '/') {
        if (previousLocation) {
          // navigating back to landing page - reload the page to avoid webflow errors
          // window.location.reload()
        }
      }
    }
  }

  useEffect(() => {
    setPreviousLocation(location)
    pathnames[location.pathname]?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}

export default useLocationChange
