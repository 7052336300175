import { ClickAwayListener } from '@mui/base'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import React, { useEffect, useState } from 'react'

import useMobileDetect from '@/hooks/use-mobile-detect'

export default function OurDateTimePicker (props) {
  const isMobile = useMobileDetect()
  const [current, setCurrent] = useState()

  function onAcceptChanges () {
    props.onAccept(current)
  }
  function onCancelChanges () {
    props.onCancel()
  }

  useEffect(() => {
    function handleKeyDown (event) {
      if (event.key === 'Enter') {
        onAcceptChanges()
      }
      if (event.key === 'Escape') {
        props.onCancel()
      }
    }
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  return (
    <ClickAwayListener onClickAway={() => !isMobile && onCancelChanges()}>
      <DateTimePicker
        autoFocus
        onChange={(date) => setCurrent(date)}
        value={props.value}
        closeOnSelect={false}
        onAccept={onAcceptChanges}
        onClose={onCancelChanges}
      />
    </ClickAwayListener>
  )
}
