import Plot from 'react-plotly.js'

import courtImgUrl from '@/assets/court-coverage.png?url'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { base64ToArrayBuffer } from '@/utils/helper'

// cspell:disable
window.genericLayout = {
  data: {
    histogram2dcontour: [
      {
        colorbar: {
          outlinewidth: 0,
          ticks: ''
        },
        type: 'histogram2dcontour'
      }
    ]
  },
  images: [
    {
      source: courtImgUrl,
      layer: 'below',
      opacity: 1,
      sizex: 127,
      sizey: 127,
      sizing: 'contain',
      x: 0,
      xref: 'x',
      y: 127,
      yref: 'y'
    }
  ],
  xaxis: {
    range: [0, 127],
    showline: false,
    showgrid: false,
    zeroline: false
  },
  yaxis: {
    range: [0, 127],
    showline: false,
    showgrid: false,
    zeroline: false
  },
  showlegend: false,
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
    pad: 0
  },
  plot_bgcolor: 'rgba(0,0,0,0)',
  paper_bgcolor: 'rgba(0,0,0,0)'
}

window.genericData = {
  colorscale: [
    [0, 'rgba(255,255,255,0)'],
    [0.01, 'rgba(255,255,255,0)'],
    [0.01, 'rgb(242,255,195)'],
    [0.1, 'rgb(242,255,195)'],
    [0.1, 'rgb(192,247,190)'],
    [0.15, 'rgb(192,247,190)'],
    [0.15, 'rgb(208, 235, 110)'],
    [0.25, 'rgb(208, 235, 110)'],
    [0.25, 'yellow'],
    [0.5, 'orange'],
    [0.95, 'red'],
    [1, 'rgb(255, 0, 255)']
  ],
  contours: {
    showlines: false
  },
  // ncontours: 100,
  opacity: 0.5,
  showscale: false,
  xaxis: 'x',
  yaxis: 'y',
  zmax: 30,
  zmin: 0.5,
  type: 'histogram2dcontour'
}
// cspell:enable

const mobileSize = {
  width: 300,
  height: 300
}

const desktopSize = {
  width: 300,
  height: 330
  // width: 500,
  // height: 500,
}

/**
 *
 * @param props
 */
export function CourtCoverage (props) {
  const isMobile = useMobileDetect()
  const {
    // eslint-disable-next-line camelcase
    data: { xs_encoded, ys_encoded }
  } = props
  const x = Array.from(new Int8Array(base64ToArrayBuffer(xs_encoded)))
  const y = Array.from(new Int8Array(base64ToArrayBuffer(ys_encoded)))
  const plotData = [{ ...window.genericData, x, y, type: 'histogram2dcontour' }] // cspell:disable-line
  const layout = { ...window.genericLayout, ...(isMobile ? mobileSize : desktopSize) }

  return <Plot data={plotData} layout={layout} config={{ displayModeBar: false, responsive: true, staticPlot: true }} />
}
