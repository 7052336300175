import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'

/**
 * Material UI Button styled to have an invisible ui, used to wrap icons or other elements
 *
 * @exports
 * @param {object} { MUIButton props }
 * @returns {React.ReactElement}
 */
export const SimpleButton = styled(MuiButton)({
  border: 'none',
  display: 'block',
  padding: 0,
  margin: 0,
  lineHeight: 1,
  background: 'transparent',
  minWidth: 'unset',

  '&:hover, &:active, &:focus': {
    background: 'transparent',
    opacity: '0.8'
  }
})

SimpleButton.defaultProps = {
  disableRipple: true
}
