import { styled } from '@mui/material/styles'

const midnightBlue = '#221d35'
const lightBlue = '#c7d9e5'
const green = '#00d824'

const Container = styled('div')({
  fontSize: '16px',
  lineHeight: '180%',
  color: midnightBlue,
  textAlign: 'left',
  fontFamily: 'Inter, sans-serif',
  fontWeight: '500',
  backgroundColor: '#fff',
  margin: '0',
  '& .section': {
    borderRadius: '6px',
    paddingTop: '93px',
    paddingBottom: '10px',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden'
  },
  '& .w-container': {
    maxWidth: '940px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:before, &:after': {
      content: '" "',
      gridArea: '1 / 1 / 2 / 2',
      display: 'table'
    }
  },
  '& .container': {
    flexFlow: 'column',
    alignItems: 'stretch',
    maxWidth: '1360px',
    paddingLeft: '132px',
    paddingRight: '132px',
    display: 'flex'
  },
  '& .center-text': {
    textAlign: 'center'
  },
  '& .center-content': {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    '&.center-text': {
      textAlign: 'center',
      '&.margin-top-bottom': {
        borderBottom: `1px solid ${lightBlue}`,
        marginBottom: '67px',
        paddingTop: '60px',
        paddingBottom: '60px'
      }
    }
  },
  '& .limit-large': {
    maxWidth: '620px',
    marginBottom: '0',
    fontFamily: 'Inter, sans-serif'
  },
  '& .opacity-dark-text': {
    color: '#221d35b3',
    paddingTop: '10px',
    paddingBottom: '20px',
    fontWeight: '400',
    lineHeight: '150%'
  },
  '& .small-text': {
    fontSize: '14px'
  },
  '& .medium-text': {
    fontSize: '18px',
    '&.opacity-dark-text.limit-large': {
      textAlign: 'center',
      alignSelf: 'center'
    }
  },
  '& h1': {
    letterSpacing: '-1px',
    borderBottom: '1px solid #fff',
    marginTop: '0',
    marginBottom: '24px',
    paddingBottom: '25px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '56px',
    fontWeight: '700',
    lineHeight: '110%'
  },
  '& .rich-text-block-2': {
    textAlign: 'left'
  },
  '& .w-richtext': {
    '&:before, &:after': {
      content: '" "',
      gridArea: '1 / 1 / 2 / 2',
      display: 'table'
    },
    '& ol, ul': {
      overflow: 'hidden'
    }
  },
  '& p': {
    marginTop: '0',
    marginBottom: '32px',
    color: midnightBlue
  },
  '& b, strong': {
    fontWeight: '700'
  },
  '& ul, ol': {
    marginTop: '0',
    marginBottom: '10px',
    paddingLeft: '40px'
  },
  [`& .list-item-3, .list-item-4, .list-item-5, .list-item-6,  .list-item-7,  .list-item-8,  .list-item-9, .list-item-10, 
      .list-item-11, .list-item-12, .list-item-13,  .list-item-14,  .list-item-15,  .list-item-16, .list-item-17, .list-item-18
  `]: {
    marginBottom: '20px'
  },
  '& a': {
    color: green,
    textDecoration: 'none',
    backgroundColor: '#0000'
  },
  '@media screen and (max-width: 991px)': {
    fontSize: '15px',
    '& h1': {
      fontSize: '48px'
    },
    '& .medium-text': {
      fontSize: '17px'
    },
    '& .container': {
      paddingLeft: '32px',
      paddingRight: '32px'
    }
  },
  '@media screen and (max-width: 767px)': {
    fontSize: '14px',
    '& h1': {
      fontSize: '40px'
    },
    '& .container': {
      paddingLeft: '24px',
      paddingRight: '24px'
    }
  },
  '@media screen and (max-width: 479px)': {
    '& .section': {
      marginTop: '0',
      paddingTop: '14px',
      paddingBottom: '14px'
    },
    '& .container': {
      zIndex: '1',
      paddingLeft: '15px',
      paddingRight: '15px',
      position: 'relative'
    }
  }
})

export default Container
