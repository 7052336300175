// Simple classNames filtering
/**
 *
 * @param {...any} input
 */
export default function cls (...input) {
  const filtered = input.filter((cond) => typeof cond === 'string').join(' ')
  // Prevent class attribute when there are no classes
  if (filtered === '') return undefined
  return filtered
}
