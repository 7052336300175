import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flex: 1,
  '& .icon': {
    width: '133.33px',
    height: '133.33px',
    marginBottom: '21px'
  },
  '& .icon.check': {
    color: COLORS['primary-500']
  },
  '& .icon.cancel': {
    color: COLORS['warning-300']
  },
  '& .yellow-box': {
    color: 'black',
    backgroundColor: COLORS['warning-050'],
    padding: '16px',
    fontSize: '18px',
    lineHeight: '27.9px',
    fontWeight: 400,
    marginBottom: '8px'
  },
  '& h2': {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: '40px',
    marginBottom: '8px'
  },
  '& .title': {
    color: 'black',
    fontSize: '18px',
    lineHeight: '29.88px',
    fontWeight: 600,
    marginBottom: 32
  },
  '& .buttons': {
    color: 'black',
    display: 'flex',
    gap: '10px'
  }
})

export default Container
