/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prefer-stateless-function */
import { Component, createElement } from 'react'

const initialState = {
  didCatch: false,
  error: null,
  details: null
}

// List of specific error messages that should trigger a page reload
const errorsWhichTriggerReload = [
  'Connection to Indexed Database server lost. Refresh the page to try again'
]

/**
 * The ErrorBoundary component is a higher-order component that catches JavaScript errors in its child component tree,
 * logs those errors(?), and displays a fallback UI instead of the component tree that crashed.
 * It is designed to catch errors during rendering.
 *
 * @class ErrorBoundary
 * @augments {Component}
 * @property {object} state - The state of the component that holds the error information.
 * @property {Function} resetErrorBoundary - Method to reset the state to its initial state.
 * @property {Function} fallbackRender - A render prop function that takes an error as an argument and returns a React element to render.
 */
class ErrorBoundary extends Component {
  constructor (props) {
    super(props)

    this.resetErrorBoundary = this.resetErrorBoundary.bind(this)
    this.state = initialState
  }

  static getDerivedStateFromError (errorInfo) {
    // Update state so the next render will show the fallback UI
    return { didCatch: true, error: errorInfo }
  }

  // When the location changes, reset the error boundary (case: when the user navigates to a different page)
  componentDidUpdate (prevProps) {
    const { location } = this.props
    const { location: prevLocation } = prevProps

    if (this.state.error !== null && location !== prevLocation) {
      this.resetErrorBoundary()
    }
  }

  componentDidCatch (error, details) {
    const errorDetails = {
      message: error.message,
      stack: error.stack,
      type: error.name,
      componentStack: details.componentStack
    }

    this.setState({ details: errorDetails })

    // Check if the error should trigger a page reload
    this.checkAndReload(error)
  }

  resetErrorBoundary (...args) {
    const { error } = this.state

    if (error !== null) {
      this.setState(initialState)
    }
  }

  checkAndReload (error) {
    // Check if the error message matches any specific errors that require a page reload
    if (errorsWhichTriggerReload.includes(error.message)) {
      window.location.reload()
    }
  }

  render () {
    const { children, fallbackRender } = this.props
    const { didCatch, error, details } = this.state

    let childToRender = children

    if (didCatch) {
      const props = {
        error,
        errorInfo: details,
        resetErrorBoundary: this.resetErrorBoundary
      }

      if (error) {
        childToRender = createElement(fallbackRender, props)
      }
    }

    return childToRender
  }
}

export default ErrorBoundary
