import { useSelector } from 'react-redux'

// Defines a slice whose content can be saved (in all or part) to local storage
export function createLocalStorageState ({
  sliceName,
  defaultState: { savable, ephemeral = {} },
  customizePreloadedStateFunction = preloadedState => preloadedState
}) {
  const localStorageKey = `pbv-${sliceName}`

  // the complete default state is the state to use the first time the app is
  // opened or if state is reset to the default; it includes both the savable
  // and ephemeral state
  const defaultState = {
    ...ephemeral,
    ...savable
  }

  // the preloaded state is the state when the app started with this session
  // and includes information from local storage, if any
  const preloadedState = customizePreloadedStateFunction({
    // data in the default state will be preferentially pulled from local
    // storage, but if it's not in local storage we'll just use the defaults
    ...savable,
    ...JSON.parse(window.localStorage.getItem(localStorageKey) ?? '{}'),

    // the ephemeral state always starts with its default values
    ...ephemeral
  })

  const savableKeys = Object.keys(savable)
  let pendingDataToSave = null
  function saveToLocalStorage (data) {
    window.localStorage.setItem(localStorageKey, JSON.stringify(data))
    pendingDataToSave = null
  }

  return {
    defaultState,
    preloadedState,

    useSliceState: selector => useSelector(state => selector(state[sliceName])),

    /**
     *
     * @param {object} sliceState the full state of the slice
     * @param {number} [debounceMillis] how long to wait before saving; this is
     *   useful if changes are happening very rapidly (we probably don't need
     *   to persist every change, and the browser may reject us if we try and
     *   even if it doesn't performance could suffer)
     */
    saveState: (sliceState, debounceMillis = 0) => {
      const dataToSave = {}
      for (const k of savableKeys) {
        dataToSave[k] = sliceState[k]
      }
      if (debounceMillis <= 0) {
        saveToLocalStorage(dataToSave)
      } else {
        pendingDataToSave = dataToSave
        setTimeout(() => {
          if (pendingDataToSave) {
            saveToLocalStorage(pendingDataToSave)
          }
        }, debounceMillis)
      }
    }
  }
}
