import CloseIcon from '@mui/icons-material/Close'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import { Button, TextField } from '@mui/material'
import clsx from 'clsx'
import { validate } from 'email-validator'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Container } from './container'

import { InlineEdit } from '@/components/inline-edit'
import { Spinner } from '@/components/spinner'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { tagUser, lookupUser } from '@/store/library'
import { useDialog } from '@/store/providers/dialog-provider'
import { useSnackbar } from '@/store/providers/snackbar-provider'

/**
 * AddUserEmailPopup component
 *
 * @exports
 * @param props {object} { open, anchorEl, onClose, playerName, vid, playerIds, aiEngineVersion }
 * @returns {React.ReactElement}
 */
export const AddUserEmailPopup = (props) => {
  const isMobile = useMobileDetect()
  const dispatch = useDispatch()
  const openSnackbar = useSnackbar()
  const { open, anchorEl, vid, playerIdx, aiEngineVersion } = props
  const [playerName, setPlayerName] = useState(props.playerName)
  const [email, setEmail] = useState('')
  const showDialog = useDialog()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setPlayerName(props.playerName)
  }, [props.playerName])

  useEffect(() => {
    if (!open) {
      setEmail('')
    }
  }, [open])

  const playerImages = []
  for (let imageIdx = 0; imageIdx < 4; imageIdx++) {
    playerImages.push(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/player${playerIdx}-${imageIdx}.jpg`)
  }

  const onClose = () => props.onClose()

  const normalizeUrl = (url) =>
    url.startsWith('http') || url.startsWith('data:image/') ? url : `data:image/png;base64,${url}`

  const doTagUser = async ({ name, uid }) => {
    try {
      await dispatch(tagUser({ vid, playerIdx, name, ...(uid ? { uid } : { addr: email.toLowerCase() }) }))
      openSnackbar('Email Invitation successfully sent')
      props.onClose({ success: true })
    } catch (error) {
      openSnackbar('An error occurred while tagging the user. Please try again', 'error')
    }
  }

  const sendEmailInvite = async () => {
    try {
      setIsLoading(true)
      const userLookup = await dispatch(lookupUser({ addr: email.toLowerCase() }))
      setIsLoading(false)
      const { uid, name } = userLookup
      if (uid && typeof name !== 'undefined' && name !== '' && name !== playerName) {
        showDialog({
          title: 'Existing User',
          description: `A user with email ${email} has already created an account. They entered their name as "${name}". Would you like to use ${name} instead of the text you entered?`,
          agreeText: 'Use preferred name',
          disagreeText: `Use "${playerName}"`,
          onAccept: async () => await doTagUser({ uid, name }),
          onCancel: async () => await doTagUser({ uid, name: playerName }),
          confirmButtonClasses: 'green',
          disagreeButtonClasses: 'gray'
        })
      } else {
        await doTagUser({ name: playerName })
      }
    } catch (error) {
      openSnackbar('An error occurred while tagging the user. Please try again', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const onEmailChange = (event) => {
    const value = event.target.value
    setEmail(value)
  }

  const onInputKeyDown = (event) => {
    const { key } = event
    if (key === 'Enter' && validate(email)) {
      sendEmailInvite()
    }
  }

  const onPlayerNameChange = ({ text }) => {
    setPlayerName(text)
  }

  return (
    <Container
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transitionDuration={0}
      className={clsx([{ mobile: isMobile }])}
    >
      <div className='header'>
        <div className='title-section'>
          <div className='avatar'>P</div>
          <InlineEdit text={playerName} onChange={onPlayerNameChange} mayEdit />
        </div>
        <CloseIcon className='close-icon' onClick={onClose} />
      </div>
      <div className='content'>
        <div className='player-images'>
          {playerImages.map((a, i) => (
            <img key={i} src={normalizeUrl(a)} alt='' />
          ))}
        </div>
        <div className='control-section'>
          <TextField
            id='email'
            autoFocus
            value={email}
            onChange={onEmailChange}
            placeholder='Enter Email'
            InputProps={{
              onKeyDown: onInputKeyDown,
              startAdornment: <MailOutlinedIcon />
            }}
          />
          <Button disabled={!validate(email)} onClick={sendEmailInvite} variant='contained'>
            Send Email Invite
          </Button>
        </div>
        {isLoading && <Spinner />}
      </div>
    </Container>
  )
}
