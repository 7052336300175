/* Avoid throwing errors when @react-three/fiber ThreeElement properties aren't found */
/* eslint react/no-unknown-property: "off" */
import { useFrame } from '@react-three/fiber'
import { sine, expo } from 'maath/easing'
import { remap, clamp } from 'maath/misc'
import React, { useRef } from 'react'
import { DoubleSide } from 'three'

function tri (t) {
  return 2 * Math.abs(Math.round(t) - t)
}

export function RippleAnimation ({ getFrameIndex, speed = 1, ringColor, ...props }) {
  const ringARef = useRef()
  const ringBRef = useRef()
  const indicatorRef = useRef()

  useFrame(() => {
    const f = getFrameIndex()

    const ringA = ringARef.current
    const ringB = ringBRef.current
    const indicator = indicatorRef.current
    if (!(ringA && ringB && indicator)) return

    ringA.scale.setScalar(expo.out(clamp(remap(f, [0, 60 * speed], [0, 1]), 0, 1)))
    ringA.material.opacity = sine.out(tri(clamp(remap(f, [0, 28 * speed], [0, 1]), 0, 1)))

    const fd = Math.max(f - 9 * speed, 0)
    ringB.scale.setScalar(expo.out(clamp(remap(fd, [0, 75 * speed], [0, 1]), 0, 1)))
    ringB.material.opacity = sine.out(tri(clamp(remap(fd, [0, 28 * speed], [0, 1]), 0, 1)))

    for (const child of indicator.children) {
      child.material.opacity = sine.out(tri(clamp(remap(f, [-8 * speed, 24 * speed], [0, 1]), 0, 1)))
    }
  })

  return (
    <group {...props}>
      <mesh ref={ringARef}>
        <ringGeometry args={[0.475, 0.5, 64]} />
        <meshBasicMaterial color={ringColor} transparent side={DoubleSide} />
      </mesh>
      <mesh ref={ringBRef}>
        <ringGeometry args={[0.475, 0.5, 64]} />
        <meshBasicMaterial color={ringColor} transparent side={DoubleSide} />
      </mesh>
      <group ref={indicatorRef} scale={[0.075, 0.075, 0.075]}>
        <mesh>
          <circleGeometry args={[0.5]} />
          <meshBasicMaterial color={ringColor} transparent side={DoubleSide} />
        </mesh>
        <mesh>
          <ringGeometry args={[0.5 * 12 / 16, 0.5, 24]} />
          <meshBasicMaterial color='#fff' transparent side={DoubleSide} />
        </mesh>
      </group>
    </group>
  )
}
