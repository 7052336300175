import CloseIcon from '@mui/icons-material/Close'
import { Button, TextField, FormControlLabel, Checkbox } from '@mui/material'
import clsx from 'clsx'
import { useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Container } from './container'

import { useAvatar } from '@/hooks/use-avatar'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { selectLoggedInUser, updateCurrentUser } from '@/store/auth'
import { tagUser } from '@/store/library'
import { useSnackbar } from '@/store/providers/snackbar-provider'

/**
 * TagPlayerPopup component
 *
 * @exports
 * @param props {object} {open, anchorEl, onClose, vid, playerIdx, aiEngineVersion}
 * @returns {React.ReactElement}
 */
export function SelfTagPopup (props) {
  const isMobile = useMobileDetect()
  const dispatch = useDispatch()
  const user = useSelector(selectLoggedInUser)
  const { refreshUser } = useAvatar()
  const { anchorEl, open, vid, playerIdx, aiEngineVersion } = props
  const [name, setName] = useState('')
  const [useImage, setUseImage] = useState(false)
  const openSnackbar = useSnackbar()
  const imageRef = useRef(null)

  const defaultImageUrl = useMemo(() => {
    return `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/player${playerIdx}-${0}.jpg`
  }, [vid, aiEngineVersion, playerIdx])

  const closePopup = (event, reason) => props.onClose()

  const onNameChange = (event) => {
    const value = event.target.value
    setName(value)
  }

  const isNameValid = (value) => {
    const trimmed = value?.trim()
    return trimmed?.length >= 2
  }

  const normalizeUrl = (url) =>
    url.startsWith('http') || url.startsWith('data:image/') ? url : `data:image/jpeg;base64,${url}`

  const saveChanges = async () => {
    if (isNameValid(name)) {
      const updatedUser = {
        displayName: name,
        ...(useImage ? { image: getBase64Image() } : {}),
        notificationsOff: {
          marketing: !!user.notificationsOff?.marketing,
          tagged: !!user.notificationsOff?.tagged,
          videoDone: !!user.notificationsOff?.videoDone
        }
      }
      try {
        await dispatch(updateCurrentUser(updatedUser))
        refreshUser()
        dispatch(tagUser({ vid, playerIdx, name, uid: user.uid }))
      } catch (error) {
        openSnackbar(error, 'error')
      } finally {
        closePopup()
      }
    }
  }

  function getBase64Image () {
    const canvas = document.createElement('canvas')
    const img = imageRef.current
    canvas.width = img.width
    canvas.height = img.width

    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)

    const base64ImageUrl = canvas.toDataURL('image/jpeg')
    const base64Image = base64ImageUrl.split(',')[1]
    return base64Image
  }

  const renderContent = () => (
    <>
      <div className='header'>
        <span className='title'>Tag Yourself</span>
        <CloseIcon className='close-icon' onClick={closePopup} />
      </div>
      <div className='content'>
        <TextField
          id='name'
          autoFocus
          value={name}
          onChange={onNameChange}
          placeholder='Enter Name'
        />
        <div className='image-section'>
          <div className='image-container'>
            <img src={normalizeUrl(defaultImageUrl)} alt='' crossOrigin='anonymous' ref={imageRef} />
          </div>
          <FormControlLabel
            classes={{ root: 'checkbox-form-control', label: 'label' }}
            control={
              <Checkbox
                className='checkbox'
                color='default'
                checked={useImage}
                onChange={(evt) => setUseImage(evt.target.checked)}
              />
            }
            label='Use this image as my photo'
          />
        </div>
        <Button disabled={!isNameValid(name)} onClick={saveChanges} variant='contained'>
          Save
        </Button>
      </div>
    </>
  )

  return (
    <Container
      open={open}
      onClose={props.onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      className={clsx([{ mobile: isMobile }])}
    >
      {renderContent()}
    </Container>
  )
}
