import { useEffect } from 'react'

import Container from './container'

const PrivacyPolicyPage = () => {
  useEffect(() => {
    const mainDiv = document.getElementsByClassName('main')[0]
    mainDiv.classList.add('converted-from-webflow')
    return () => {
      mainDiv.classList.remove('converted-from-webflow')
    }
  }, [])

  return (
    <Container>
      <div className='section'>
        <div className='container w-container'>
          <div className='center-content center-text margin-top-bottom'>
            <h1 className='limit-large'>Privacy Policy</h1>
            <div className='opacity-dark-text limit-large small-text'>Last updated: 25 April 2024</div>
            <div className='rich-text-block w-richtext'>
              <p>
                Pickleball Vision AI, Inc. (“<strong>PB Vision</strong>” or<strong> </strong>“<strong>We</strong>” or “<strong>Us</strong>”
                or<strong> </strong>“<strong>Our</strong>”) respects your privacy and is committed to protecting it
                through our compliance with this policy.
              </p>
              <p>
                This policy describes the types of information we may collect from you or that you may provide when you visit a PB Vision website, including&nbsp;
                <a href='https://www.pb.vision/'>https://www.pb.vision/</a> (our “<strong>Website</strong>”) or
                applications (together with the Website, our “<strong>Services</strong>”) and our practices for
                collecting, using, maintaining, protecting, and disclosing that information.
              </p>
              <p className='paragraph-2'>This policy applies to information we collect:</p>
              <ul role='list'>
                <li>From within our Services.</li>
                <li>In email, text, and other electronic messages between you and the Services.</li>
              </ul>
              <p className='paragraph-3'>It does not apply to information collected by:</p>
              <ul role='list'>
                <li>Us offline or through any other means, including on any other website operated by any third party
                  (including our affiliates and subsidiaries); or&nbsp;
                </li>
                <li>Any third party (including our affiliates and subsidiaries), including through any application or
                  content (including advertising) that may link to or be accessible from or on the Services
                </li>
              </ul>
              <p>
                Please read this policy carefully to understand our policies and practices regarding your information
                and how we will treat it. If you do not agree with our policies and practices, your choice is not to use
                our Services. By accessing or using the Services, you agree to this privacy policy. This policy may
                change from time to time (see Changes to Our Privacy Policy). Your continued use of the Services after
                we make changes is deemed to be acceptance of those changes, so please check the policy periodically for
                updates.
              </p>
              <p><strong>Information We Collect About You and How We Collect It</strong></p>
              <p className='paragraph-4'>
                We collect several types of information from and about users of our Services, including information:
              </p>
              <ul role='list'>
                <li>By which you may be personally identified, such as name and email address, or payment information (“<strong>personal information</strong>”);</li>
                <li>From the videos you upload, including analytics, audio, and metadata associated with the videos;</li>
                <li>Related to your game analytics, such as game statistics, player performance metrics, and 3d data models;</li>
                <li>That is about you but individually does not identify you; and</li>
                <li>About your internet connection, the equipment you use to access our Services, and usage details.</li>
              </ul>
              <p className='paragraph-5'>We collect this information:</p>
              <ul role='list'>
                <li>Directly from you when you provide it to us, including when you upload a video and provide information about it, such as player names.</li>
                <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through tracking technologies.</li>
                <li>From third parties, for example, our business partners.</li>
              </ul>
              <p className='paragraph-7'><em>Information You Provide to Us</em><strong>&nbsp;&nbsp;</strong></p>
              <p className='paragraph-6'>The information we collect on or through our Services may include:</p>
              <ul role='list'>
                <li>
                  Information that you provide by filling in forms on our Services. This includes information provided
                  at the time of registering to use our Services, subscribing to our service, or requesting further
                  services. We may also ask you for information when you report a problem with our Services.
                </li>
                <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
                <li>Your search queries on the Services.</li>
              </ul>
              <p className='paragraph-8'>
                <em>Information We Collect Through Automatic Data Collection Technologies</em><strong><em>&nbsp;&nbsp;</em></strong>
              </p>
              <p className='paragraph-9'>
                As you navigate through and interact with our Services, we may use automatic data collection technologies to collect
                certain information about your equipment, browsing actions, and patterns, including:
              </p>
              <ul role='list'>
                <li>Details of your visits to our Services, including traffic data, location data, logs, and other
                  communication data and the resources that you access and use on the Services.
                </li>
                <li>Information about your computer and internet connection, including your IP address, operating
                  system, and browser type.
                </li>
              </ul>
              <p className='paragraph-10'>
                The information we collect automatically is only statistical data and does not
                include personal information, but we may maintain it or associate it with personal information we
                collect in other ways or receive from third parties. It helps us to improve our Services and to deliver
                a better and more personalized service, including by enabling us to:
              </p>
              <ul role='list'>
                <li>Estimate our audience size and usage patterns.</li>
                <li>Store information about your preferences, allowing us to customize our Services according to your
                  individual interests.
                </li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you return to our Services.</li>
              </ul>
              <p className='paragraph-11'>The technologies we use for this automatic data collection may include:</p>
              <ul role='list'>
                <li className='list-item-2'><strong>Web Beacons.</strong> Parts of our Services may contain small
                  electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
                  gifs) that permit PB Vision, for example, to count users who have visited those pages, to store your
                  account settings and information about your uploads, to improve the functionality of our Services, and
                  for other related statistics (for example, recording the popularity of certain website content and
                  verifying system and server integrity). We share the data collected through web beacons with service
                  providers for analytics and website improvement purposes.
                </li>
              </ul>
              <p><strong>How We Use Your Information</strong></p>
              <p className='paragraph-12'>
                We use information that we collect about you or that you provide to us, including any personal information:
              </p>
              <ul role='list' className='list'>
                <li>To create and manage your PB Vision account.</li>
                <li>To process your payments through our third party payment processors.</li>
                <li>To present our Services and their contents to you.</li>
                <li>To provide you with information, products, or services that you request from us.</li>
                <li>To communicate with you about updates, promotions, and news.</li>
                <li>To perform analysis and generate game statistics.</li>
                <li>To improve our machine learning models using your video content and the descriptions you provide for
                  your video content.
                </li>
                <li>To provide insight into your game.</li>
                <li>To develop new features and functionalities.</li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>To carry out our obligations and enforce our rights arising from any contracts entered into between
                  you and us, including for billing and collection.
                </li>
                <li>To notify you about changes to our Services or any products or services we offer or provide through
                  it.
                </li>
                <li>To allow you to participate in interactive features on our Services.</li>
                <li>In any other way we may describe when you provide the information.</li>
                <li>For any other purpose with your consent.</li>
              </ul>
              <p><strong>Disclosure of Your Information</strong></p>
              <p className='paragraph-13'>
                We do not sell your
                personal information. We may disclose personal information that we collect or you provide as described in
                this privacy policy:
              </p>
              <ul role='list'>
                <li>To our subsidiaries and affiliates.</li>
                <li>To contractors, service providers (including payment processors), and other third parties we use to
                  support our business.
                </li>
                <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                  dissolution, or other sale or transfer of some or all of PB Vision’s assets, whether as a going
                  concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information
                  held by PB Vision about users of our Services is among the assets transferred.
                </li>
                <li>To fulfill the purpose for which you provide it.</li>
                <li>For any other purpose disclosed by us when you provide the information.</li>
                <li>With your consent.</li>
              </ul>
              <p className='paragraph-14'>We may also disclose your personal information:</p>
              <ul role='list' className='list-2'>
                <li>To comply with any court order, law, or legal process, including to respond to any government or
                  regulatory request.
                </li>
                <li>To enforce or apply our <a href='https://www.pb.vision/terms-of-service'>Terms of Service</a> and
                  other agreements, including for billing and collection purposes.
                </li>
                <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
                  PB Vision, our customers, or others.
                </li>
              </ul>
              <p><strong>Choices About How We Use and Disclose Your Information</strong></p>
              <p>
                We strive to provide you
                with choices regarding the personal information you provide to us. We have created mechanisms to provide
                you with the following control over your information:&nbsp;
              </p>
              <ul role='list'>
                <li>
                  <strong>Promotional Offers from PB Vision</strong>. If you do not wish to have your information used
                  by PB Vision to promote our own or third parties&apos; products or services, you can opt-out by sending us
                  an email stating your request to <a href='mailto:policy@pb.vision'>policy@pb.vision</a>. If we have
                  sent you a promotional email, you may send us a return email asking to be omitted from future email
                  distributions.&nbsp;
                </li>
                <li>
                  <strong>Targeted Advertising</strong>. If you do not want us to use information that we collect or
                  that you provide to us to deliver advertisements according to our advertisers&apos; target-audience
                  preferences, you can opt-out by sending us an email stating your request to&nbsp;
                  <a href='mailto:policy@pb.vision'>policy@pb.vision</a>.&nbsp;
                </li>
              </ul>
              <p>
                We do not control third parties’ collection or use of your information to serve interest-based
                advertising. However these third parties may provide you with ways to choose not to have your
                information collected or used in this way. You can opt out of receiving targeted ads from members of the
                Network Advertising Initiative (“<strong>NAI</strong>”) on the NAI&apos;s website.
              </p>
              <p>
                <strong>Accessing, Correcting, or Deleting Your Information</strong>
              </p>
              <p>
                You can review and change your personal information in your account. Please contact us at&nbsp;
                <a href='mailto:policy@pb.vision'>policy@pb.vision</a> if you wish to delete personal information that you
                have provided in your account. If you request to delete your account, your account will no longer be
                associated with the videos you have uploaded, however we will retain the videos for continued use in
                training and improving our AI model.
              </p>
              <p><strong>State Privacy Laws</strong></p>
              <p>
                We do not meet the
                conditions as outlined in Section 1798.140 of the California Consumer Privacy Act of 2018 (the “CCPA”) and
                therefore are not subject to CCPA requirements. We also do not meet the eligibility conditions outlined in
                the Virginia Consumer Data Protection Act, Colorado Privacy Act, Connecticut Data Privacy Act, Utah
                Consumer Privacy Act, the Oregon Consumer Privacy Act, the Texas Data Privacy and Security Act, and the
                Montana Consumer Data Privacy Act, or the Florida Digital Bill of Rights and are therefore are not subject
                to the requirements of these laws. For specific information related to your ability to access and correct
                your personal information, <em>see</em> Accessing and Correcting Your Information above.&nbsp;
              </p>
              <p><strong>Data Security</strong></p>
              <p>
                We have implemented measures designed to secure your personal
                information from accidental loss and from unauthorized access, use, alteration, and disclosure. The safety
                and security of your information also depends on you. Although we do our best to protect your personal
                information, we cannot guarantee the security of your personal information transmitted to our Services.
                Any transmission of personal information is at your own risk. We are not responsible for circumvention of
                any privacy settings or security measures contained on the Services.
              </p>
              <p><strong>Children Under the Age of 18</strong></p>
              <p>
                Our Services are not intended for children under 18 years of age. No one under age 18
                may provide any personal information to or on the Services. We do not knowingly collect personal
                information from children under 18. If you are under 18, do not use or provide any information on these
                Services, register on the Services, or provide any information about yourself to us, including your name,
                address, telephone number, email address, or any screen name or user name you may use. If we learn we have
                collected or received personal information from a child under 18 without verification of parental consent,
                we will delete that information. If you believe we might have any information from or about a child under
                18, please contact us at policy@pb.vision.&nbsp;
              </p>
              <p><strong>Changes to Our Privacy Policy</strong></p>
              <p>
                It is our policy to post any changes we make to our privacy policy on this page. If we make material
                changes to how we treat our users’ personal information, we will notify you by email or through a notice
                on the Website home page. The date the privacy policy was last revised is identified at the top of the
                page. You are responsible for ensuring we have an up-to-date active and deliverable email address for
                you, and for periodically visiting our Website and this privacy policy to check for any changes.
              </p>
              <p><strong>Contact Information</strong></p>
              <p>
                To ask questions or comment about this privacy policy and our privacy practices, contact us at:&nbsp;<a href='mailto:policy@pb.vision'>policy@pb.vision</a>&nbsp;
              </p>
              <p>&nbsp;</p>
              <p>&zwj;</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PrivacyPolicyPage
