import { useShowFreeTrialBanner } from '@/hooks/monetization'

/**
 * Provider component for wrapping banners. This component will be used for showing and removing banners.
 */
export function Banners ({ children }) {
  useShowFreeTrialBanner()

  return (
    <>
      {children}
    </>
  )
}
