import CloseIcon from '@mui/icons-material/Close'
import { Dialog, IconButton, styled } from '@mui/material'
import React, { useState } from 'react'

import { PricingPlan } from '../subscribe/pricing-plan'

import { SUBSCRIPTION_PLANS } from '@/hooks/monetization'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { usePurchaseFlow } from '@/hooks/use-purchase-flow'
import CreateAccount from '@/pages/create-account'
import { PRICING_PLANS_CARD_CONTENT } from '@/pages/pricing/sections/plans'
import { CheckoutForm } from '@/pages/stripe'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export default function UpgradeToAccessDialog ({ isOpen, setIsOpen, requiredSubscription }) {
  const isMobile = useMobileDetect()
  const purchaseFlow = usePurchaseFlow()
  const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false)

  function onClose () {
    setIsOpen(false)

    // Make sure to wait for the dialog animation to complete before resetting content
    setTimeout(() => {
      purchaseFlow.clearSKUToBuy()
      setShowCreateAccountDialog(false)
    }, 300)
  }

  const requiredPlans = Object.values(PRICING_PLANS_CARD_CONTENT).filter(plan => {
    // Return all plans for starter
    if (requiredSubscription === SUBSCRIPTION_PLANS.STARTER) {
      return plan.tier === SUBSCRIPTION_PLANS.STARTER || plan.tier === SUBSCRIPTION_PLANS.PREMIUM
    }

    // Return only premium for premium
    if (requiredSubscription === SUBSCRIPTION_PLANS.PREMIUM) {
      return plan.tier === SUBSCRIPTION_PLANS.PREMIUM
    }
    return false
  })

  return (
    <StyledDialog
      onClose={onClose}
      open={isOpen}
    >
      {showCreateAccountDialog && <CreateAccount dialog handleCloseDialog={onClose} />}
      {!showCreateAccountDialog && purchaseFlow.skuToBuy && <CheckoutForm productId={purchaseFlow.skuToBuy} />}
      {!showCreateAccountDialog && !purchaseFlow.skuToBuy &&
        <Container className={isMobile && 'mobile'}>
          <div className='head'>
            <IconButton className='close-button' onClick={onClose} title='Close'>
              <CloseIcon className='close-icon' />
            </IconButton>
            <h3>Upgrade to access</h3>
            <p className='body-md'>
              This feature requires a premium subscription to access. <br />
            </p>
          </div>
          <div className='plans'>
            {requiredPlans.map((plan, i) => (
              <PricingPlan key={i} plan={plan} purchaseFlow={purchaseFlow} decreasedSize promptToLogin={() => setShowCreateAccountDialog(true)} />
            ))}
          </div>
        </Container>}
    </StyledDialog>
  )
}

const Container = styled('div')(() => ({
  display: 'flex',
  gap: '4px',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px 22px 20px',
  minWidth: 300,
  textAlign: 'center',
  '& .plans': {
    ...row,
    gap: 16,
    marginTop: 16
  },
  '& .head': {
    ...column,
    width: '100%',
    '& .close-button': {
      marginLeft: 'auto',
      '& .close-icon': {
        width: 20
      }
    }
  },

  // responsive (mobile)
  '&.mobile': {
    '& .plans': {
      ...column
    }
  }
}))

const StyledDialog = styled(Dialog)(() => ({
  zIndex: 1389,
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    [theme.breakpoints.down('md')]: {
      margin: '16px'
    }
  }
}))
