import React, { useState, cloneElement, useMemo } from 'react'

import UpgradeToAccessDialog from '../dialog/upgrade-to-access'

import { SUBSCRIPTION_PLANS } from '@/hooks/monetization'
import { useMyMonetizationData } from '@/store/auth'

/**
 * Component designed to be wrapped around clickable elements to restrict access
 * to features based on the user's subscription level. If the user does not have
 * the required subscription, a dialog will be triggered instead of allowing the
 * original click action to proceed.
 *
 * @param {Object} props The properties for the FeatureGuard component.
 * @param {string} props.requiredSubscription The required subscription tier to access the feature.
 * @param {string} props.hasOpacity When this is true, component will have the opacity.
 * @param {React.ReactNode} props.children The clickable element(s) guarded by the subscription status.
 * @returns {React.ReactElement} A component that conditionally allows clicks based on the subscription level or triggers a dialog.
 *
 * @example
 * <FeatureGuard requiredSubscription={SUBSCRIPTION_PLANS.PREMIUM}>
 *   <MenuItem onClick={openRawDataDialog} disabled={!isWorkflowDone}>
 *     <ListItemIcon>
 *       <TextSnippetIcon fontSize="small" />
 *     </ListItemIcon>
 *     Download Raw Data
 *   </MenuItem>
 * </FeatureGuard>
 */

export default function FeatureGuard ({ requiredSubscription, hasOpacity, children }) {
  const { currentSubscription, hasPremiumAccess } = useMyMonetizationData()
  const [dialogOpen, setDialogOpen] = useState(false)

  const isPrevented = useMemo(() => {
    const PREMIUM_CONDITION = requiredSubscription === SUBSCRIPTION_PLANS.PREMIUM && !hasPremiumAccess
    const STARTER_CONDITION = requiredSubscription === SUBSCRIPTION_PLANS.STARTER && (currentSubscription.tier === SUBSCRIPTION_PLANS.STARTER || hasPremiumAccess)

    return Boolean(!currentSubscription) || PREMIUM_CONDITION || STARTER_CONDITION
  }, [currentSubscription, hasPremiumAccess, requiredSubscription])

  const handleChildClick = (event) => {
    // Prevent the original click action if the subscription is not appropriate
    if (isPrevented) {
      setDialogOpen(true)
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const renderChildren = () => {
    const originalOnClick = children.props?.onClick

    const onClickHandler = (event) => {
      // Call the original onClick if the event was not stopped
      if (isPrevented) {
        event.stopPropagation()
        return
      }
      originalOnClick && originalOnClick(event)
    }

    return (
      <div onClickCapture={handleChildClick}>
        {React.Children.map(children, child =>
          cloneElement(child, {
            onClick: onClickHandler,
            style: {
              ...child.props.style,
              opacity: hasOpacity && isPrevented ? 0.3 : 1,
              pointerEvents: hasOpacity && isPrevented ? 'none' : 'auto'
            }
          }))}
      </div>
    )
  }

  return (
    <>
      {renderChildren()}
      <UpgradeToAccessDialog isOpen={dialogOpen} setIsOpen={setDialogOpen} requiredSubscription={requiredSubscription} />
    </>
  )
}
