import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useVideoParams = () => {
  const [searchParams] = useSearchParams()

  const handleWithVideoParams = useCallback((muxPlayer) => {
    const startTime = Number(searchParams.get('t'))
    const loopLength = Number(searchParams.get('l'))
    const playbackRate = Number(searchParams.get('speed'))

    if (startTime) {
      muxPlayer.currentTime = startTime
    }

    if (playbackRate) {
      // Set the playback rate if it is specified in the URL, with a maximum of 5
      muxPlayer.playbackRate = Math.min(5, playbackRate)
    }

    if (loopLength) {
      const endTime = startTime + loopLength

      const handleProgress = () => {
        // If the user seeks outside the loop range, remove the progress listener to prevent them to live if this case occur
        if (muxPlayer.currentTime < startTime || muxPlayer.currentTime > endTime + 2) {
          clearInterval(intervalId)
          muxPlayer.removeEventListener('pause', handlePause)

          return
        }

        // loop back to the start time
        if (muxPlayer.currentTime > endTime) {
          muxPlayer.currentTime = startTime
        }
      }

      const handlePause = () => {
        clearInterval(intervalId)
        muxPlayer.removeEventListener('pause', handlePause)
      }

      muxPlayer.addEventListener('pause', handlePause)

      const intervalId = setInterval(() => {
        handleProgress()
      }, 500)
    }

    if (startTime || loopLength || playbackRate) {
      // Mute the video to increase the chance of autoplay working
      // https://docs.mux.com/guides/web-autoplay-your-videos#increase-your-chance-of-autoplay-working
      // muxPlayer.muted = true

      if (muxPlayer.paused || muxPlayer.ended) {
        muxPlayer.play()
      }
    }
  }, [searchParams])

  return { handleWithVideoParams }
}
