import styled from '@emotion/styled'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import COLORS from '../../utils/colors'

import PricingAmbassadorProgramSection from './sections/ambassador-program'
import PricingPlansSection from './sections/plans'

import { Button } from '@/components/button'
import { Divider } from '@/components/divider'
import { IosFooter } from '@/components/ios-footer'
import { UploadButton } from '@/components/upload/UploadButton'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useIsLoadingExtraUserData, useLoggedInUser } from '@/store/auth'
import { column, row } from '@/utils/flexGrid'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

export default function PricingPage () {
  const isMobile = useMobileDetect()
  const loggedInUser = useLoggedInUser()
  const isLoadingExtraUserData = useIsLoadingExtraUserData()

  const getTryPremiumText = () => {
    if (loggedInUser?.firstUploadEpoch || isLoadingExtraUserData) {
      return null
    }

    return (
      <div className='subtitle'>
        <h2>
          Try Premium free for 1 month.
        </h2>
        <div className='body'>
          <div className='get-started'>Just upload a video to get started and your free trial will begin automatically.</div>
          <div className='actions'>
            <UploadButton />
            {!isInMobileAppWebview() && (
              <Button className='primary-outline transparent' variant='outlined' LinkComponent={Link} to='/video/demo' target='_blank'>View demo</Button>
            )}
          </div>
          <div className='footer-info'><CreditCardIcon /> No credit card required!</div>
        </div>
      </div>
    )
  }

  return (
    <Container className={clsx([{ mobile: isMobile }])}>
      <h1 className='title'>
        Everything you need to level up your game
      </h1>
      {getTryPremiumText()}
      <PricingPlansSection />
      <Divider className='full-width' />
      <PricingAmbassadorProgramSection />
      <Divider className='full-width' />
      <div className='faq-footer'>
        Check out <a href='https://help.pb.vision/articles/6882251-subscriptions-and-credits' target='_blank' rel='noreferrer'>our documentation</a> for subscription-related FAQs
      </div>
      <IosFooter />
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100% !important',
  maxWidth: '1142px !important',
  paddingTop: '120px',
  paddingInline: '15px',
  paddingBottom: '50px',
  '& h1': {
    color: COLORS['neutral-800'],
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '51.84px',
    letterSpacing: '-2.619px',
    marginBottom: 60
  },
  '& h2': {
    color: COLORS['neutral-800'],
    textAlign: 'center',
    fontSize: '30px',
    lineHeight: '39.9px',
    fontWeight: 700
  },
  '& .subtitle': {
    ...column,
    justifyContent: 'center',
    maxWidth: '660px',
    marginBottom: 70,
    background: COLORS['primary-050'],
    padding: '16px 24px',
    alignItems: 'center',
    borderRadius: '10px',
    '& .body': {
      ...column,
      alignItems: 'center',
      textAlign: 'center',
      color: COLORS['neutral-800'],
      '& .get-started': {
        marginTop: 20
      },
      '& .actions': {
        ...row,
        gap: '10px',
        marginTop: 20
      },
      '& .footer-info': {
        ...row,
        marginTop: 20,
        '& svg': {
          marginRight: '0.2em'
        }
      }
    }
  },
  '& .faq-footer': {
    margin: '20px 0',
    fontSize: 24,
    lineHeight: '166%',
    color: COLORS['00-on-surface-high-emphasis']
  },
  '&.mobile': {
    paddingTop: '30px',
    width: '100% !important',
    gap: 40,
    '& h1': {
      fontSize: '24px',
      lineHeight: '26px',
      letterSpacing: 0
    },
    '& .faq-footer': {
      fontSize: 16
    }
  }
})
