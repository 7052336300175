// This slice stores data for anonymous users (state which is normally on the
// server but anonymous users do not have server state).
import { createSlice } from '@reduxjs/toolkit'

import { userChanged } from './auth'
import { createLocalStorageState } from './local-storage-state'

import { assert } from '@/utils'

export const sliceName = 'anonymous'
const { preloadedState, saveState } = createLocalStorageState({
  sliceName,
  defaultState: {
    savable: {
      vidsFromNative: [],
      unseenVIDs: [],
      uploadedVids: []
    }
  }
})

const anonymousSlice = createSlice({
  name: sliceName,
  initialState: preloadedState,
  reducers: {
    newUnseenAnonymousVideo: (state, action) => {
      const { isFromNative, vid } = action.payload
      if (isFromNative) {
        if (state.vidsFromNative.indexOf(vid) !== -1) {
          return // already received it before; don't process it again
        }
        state.vidsFromNative.push(vid)
      }
      assert(state.unseenVIDs.indexOf(vid) === -1, 'vid is already in unseen')
      state.unseenVIDs.push(vid)
      saveState(state)
    },
    sawUnseenAnonymousVideo: (state, action) => {
      const { vid } = action.payload
      const idx = state.unseenVIDs.indexOf(vid)
      assert(idx !== -1, 'missing unseenVID')
      state.unseenVIDs.splice(idx, 1)
      saveState(state)
    },
    newUploadedVideo: (state, action) => {
      const { vid } = action.payload
      state.uploadedVids.push(vid)
      saveState(state)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userChanged, state => {
      // when the user signs in or out, remove this state
      saveState(state)
    })
  }
})

export const { reducer } = anonymousSlice
export const { newUnseenAnonymousVideo, sawUnseenAnonymousVideo, newUploadedVideo } = anonymousSlice.actions

export const selectUnseenAnonymousVids = state => state[sliceName].unseenVIDs

export function addAnonymousVideosFromNativeApp (vids) {
  return dispatch => {
    for (const vid of vids) {
      dispatch(newUnseenAnonymousVideo({ vid, isFromNative: true }))
    }
  }
}
