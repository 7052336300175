import VideoCallIcon from '@mui/icons-material/VideoCall'
import { styled } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from '@/components/button'
import { UploadDialog } from '@/components/upload-dialog'
import { useCanUploadVideo } from '@/hooks/use-can-upload-video'
import { useIsLoggedIn } from '@/store/auth'
import { useQueueNewUpload } from '@/store/uploads'
import COLORS from '@/utils/colors'
import { isInMobileAppWebview, startNativeUploadInMobileApp } from '@/utils/mobile-app-communication'

/**
 * Button component as child component, needed for context to work
 *
 * @returns {React.ReactElement}
 */
export function UploadButton (props) {
  const { className = 'green', label = 'Upload Video', disableIcon = false } = props
  const queueNewUpload = useQueueNewUpload()
  const { canUploadNewVideo, alertUserForForbiddenUpload } = useCanUploadVideo()
  const { fid } = useParams()
  const isLoggedIn = useIsLoggedIn()
  const uploadRef = useRef()

  // Function to extract fid from URL if button is not inside navigation provider
  const getFidFromUrl = () => {
    const pathParts = window.location.pathname.split('/')
    return pathParts[pathParts.length - 1]
  }

  const folderID = Number(fid) || Number(getFidFromUrl()) || 0

  const handleFileSelected = (event) => {
    if (event?.target?.files?.length) {
      for (const file of event.target.files) {
        queueNewUpload(file, { fid: folderID })
      }
    }
    event.target.value = ''
  }

  useEffect(() => {
    const cancelUpload = () => {
      if (uploadRef.current) {
        uploadRef.current.value = null
      }
    }
    document.addEventListener('cancel-upload', cancelUpload)
    return () => {
      document.removeEventListener('cancel-upload', cancelUpload)
    }
  }, [])

  function onUploadClick () {
    if (!canUploadNewVideo()) {
      alertUserForForbiddenUpload()
      return false
    }

    if (!isInMobileAppWebview()) {
      uploadRef.current.click()
    } else {
      startNativeUploadInMobileApp(folderID)
    }
  }

  return (
    <ButtonStyled className={className} onClick={() => onUploadClick()}>
      <input ref={uploadRef} accept='video/*' type='file' multiple={isLoggedIn} hidden onChange={handleFileSelected} />
      <em>{label}</em>
      {!disableIcon && <VideoCallIcon />}
    </ButtonStyled>
  )
}

const ButtonStyled = styled(Button)({
  '&.outlined': {
    backgroundColor: COLORS.white,
    paddingBlock: '8px',
    border: `1px solid ${COLORS['neutral-300']}`,
    color: COLORS['neutral-700'],
    '&:hover': {
      borderColor: COLORS['neutral-400']
    }
  }
})

export const AnonymousUploadButton = (props) => {
  const { className = 'green', label = 'Upload Video', disableIcon = false, style } = props
  const [uploadDialogOpened, setUploadDialogOpened] = useState(false)
  const { canUploadNewVideo, alertUserForForbiddenUpload } = useCanUploadVideo()
  const isUploadAllowed = canUploadNewVideo()

  function onUploadClick () {
    if (!isInMobileAppWebview()) {
      if (isUploadAllowed) {
        setUploadDialogOpened(true)
      } else {
        alertUserForForbiddenUpload()
      }
    } else {
      startNativeUploadInMobileApp()
    }
  }

  useEffect(() => {
    if (isUploadAllowed === false && uploadDialogOpened === true) {
      setUploadDialogOpened(false)
    }
  }, [isUploadAllowed, uploadDialogOpened])

  return (
    <>
      <ButtonStyled className={className} style={style} onClick={onUploadClick}>
        <em>{label}</em>
        {!disableIcon && props.icon ? props.icon : <VideoCallIcon />}
      </ButtonStyled>
      <UploadDialog open={uploadDialogOpened} onClose={() => setUploadDialogOpened(false)} />
    </>
  )
}
