import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { useNavigate } from 'react-router-dom'

import CloudUploadIcon from '@/assets/cloud-upload.svg?react'
import { SimpleButton } from '@/components/button/simple-button'
import { UploadingDetails } from '@/components/upload'
import cls from '@/utils/classnames'
import transparentPixel from '@/utils/transparent-pixel'

const Message = ({ upload, loggedInUser }) => {
  if (['succeeded', 'failed'].includes(upload.status) && !loggedInUser) {
    return <div className='status success neutral-800'>👈 Get your results!</div>
  }
  if (upload.status === 'uploading' || !loggedInUser) {
    return <div className='status warning'>Do not close the browser window</div>
  }
  if (['succeeded', 'failed'].includes(upload.status) && loggedInUser) {
    return <div className='status success'>It’s ok to navigate away from this page</div>
  }
}

function UploadView ({ upload, loggedInUser }) {
  const navigate = useNavigate()
  if (!upload) return null
  // Link is enabled only after user completes registration process
  const buttonEnabled = Boolean(loggedInUser)
  const goToVideo = () => {
    // Navigate to the video only when user has completed login/registration and video finished uploading
    if (loggedInUser && upload?.status === 'succeeded') { navigate(`/video/${upload.vid}`, { replace: true }) }
  }

  return (
    <>
      <SimpleButton className={cls(buttonEnabled && 'enabled', 'link-button')} onClick={goToVideo}>
        <Card className='upload-card'>
          <div className='upload-img'>
            <CardMedia component='img' image={upload.thumbnail ?? transparentPixel} alt={upload.name} />
            {upload.status === 'uploading' ? <CloudUploadIcon /> : <CheckCircleOutlineIcon />}
          </div>
          <div className='column grow'>
            <CardContent>
              <div className='name'>{upload.name}</div>
              <UploadingDetails
                upload={upload}
                countdownPrefix='We’ll email you when your report is ready '
              />
            </CardContent>
          </div>
        </Card>
      </SimpleButton>
      <Message upload={upload} loggedInUser={loggedInUser} />
    </>
  )
}

export default UploadView
