import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

const Button = styled(MuiButton)({
  display: 'flex',
  backgroundColor: COLORS['primary-500'],
  color: '#fff !important',
  borderRadius: 8,
  minHeight: 40,
  padding: '4px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,

  '&:hover, &:active, &:focus': {
    backgroundColor: COLORS['primary-500'],
    opacity: '0.8'
  },
  '&.outlined': {
    borderRadius: 'var(--radius-sm, 8px)',
    border: `1px solid ${COLORS['neutral-300']}`,
    background: '#FFF',
    color: 'inherit !important',
    '&:hover, &:active, &:focus': {
      backgroundColor: COLORS['neutral-050']
    }
  }
})

export default Button
