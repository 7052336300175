import { useDispatch } from 'react-redux'

import { removeFolder, removeVideoFromLibrary } from '@/store/library'

export const useMultipleDelete = () => {
  const dispatch = useDispatch()

  async function deleteMultipleItems (items) {
    const videosToDelete = []
    const foldersToDelete = []

    items.forEach(item => {
      if (item?.vid) {
        videosToDelete.push(item.vid)
      } else {
        foldersToDelete.push(item.fid)
      }
    })

    const videoDeletePromises = videosToDelete.map(vid =>
      dispatch(removeVideoFromLibrary(vid))
    )

    const folderDeletePromises = foldersToDelete.map(fid =>
      dispatch(removeFolder(fid))
    )

    // Await all delete operations
    await Promise.all([...videoDeletePromises, ...folderDeletePromises])
  }

  return { deleteMultipleItems }
}
