import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

/**
 * Custom styled Material UI Button
 *
 * @exports
 * @param {object} { MUIButton props }
 * @returns {React.ReactElement}
 */
export const Button = styled(MuiButton)(({ theme }) => ({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  minHeight: 32,
  padding: '8px 14px',
  textTransform: 'none',
  backgroundColor: COLORS.white,

  '&.active': {
    backgroundColor: theme.palette.primary.main,
    '& em': {
      color: COLORS.white
    }
  },
  '&.neutral-outline': {
    borderColor: COLORS['neutral-300']
  },
  '&.primary-outline': {
    borderColor: COLORS['primary-300']
  },
  '&.bold': {
    fontWeight: 700
  },
  '&.danger': {
    color: COLORS['danger-700'],
    border: `1px solid ${COLORS['neutral-300']}`,
    '& em, & svg': {
      color: 'inherit'
    }
  },
  '&.gray': {
    color: COLORS['neutral-700'],
    border: `1px solid ${COLORS['neutral-300']}`,
    '& em, & svg': {
      color: 'inherit'
    }
  },
  '&.green': {
    color: COLORS.white,
    backgroundColor: COLORS['primary-500'],
    '& em, & svg': {
      color: 'inherit'
    }
  },
  '&.gray-filled': {
    color: COLORS.white,
    backgroundColor: `${COLORS['neutral-500']}`,
    '& em, & svg': {
      color: 'inherit'
    }
  },
  '&.transparent': {
    backgroundColor: 'transparent'
  },
  '& svg': {
    color: COLORS['primary-500'],
    marginLeft: '6px',
    fontSize: '20px'
  },
  '&.no-outline': {
    border: 'none !important'
  },
  '& em': {
    color: COLORS.black,
    fontStyle: 'normal'
  },
  '&.no-wrap': {
    textWrap: 'nowrap'
  },
  '&.Mui-disabled': {
    opacity: 0.6
  },

  '&.icon-left': {
    '& svg': {
      marginLeft: '0px',
      marginRight: '6px'
    }
  }
}))
