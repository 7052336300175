/**
 * Converts a given URL to a Data URL by fetching the resource and creating a Blob.
 *
 * This function fetches the resource from the specified URL, converts it to a Blob,
 * and then creates a Data (Blob) URL for that Blob. If fetching fails, the original URL serves as a fallback.
 *
 * @async
 * @function toDataURL
 * @param {string} url - The URL of the resource to fetch.
 * @returns {Promise<string>} A promise that resolves to the Data URL of the resource, or the original URL if fetching fails.
 * @throws {Error} If the URL is empty or undefined.
 */
export async function toDataURL (url) {
  if (!url) {
    return Promise.reject(new Error('Url is missing.'))
  }
  return fetch(url)
    .then((response) => {
      return response.ok ? response.blob() : url
    })
    .then(blob => {
      return URL.createObjectURL(blob)
    })
    .catch(() => {
      // If fetch failed (cors or other limitation) - fallback to url
      return url
    })
}

/**
 * Downloads a file from a specified URL.
 *
 * This function creates a temporary link element and initiates a download for the file
 * located at the given URL. The file can be downloaded directly or through a Data URL fallback.
 *
 * @async
 * @function downloadFile
 * @param {string} url - The URL of the file to download.
 * @param {string} [fileName] - The name to assign to the downloaded file. If not provided or set to null, the browser's default filename will be used.
 * @param {boolean} [forceDirectLink=false] - If true, the function will use the provided URL directly without trying to convert it to a Data URL.
 * @returns {Promise<void>} A promise that resolves once the download has been initiated.
 */
export async function downloadOrOpenLink (url, fileName, forceDirectLink = false) {
  try {
    const link = window.document.createElement('a')
    link.href = forceDirectLink ? url : await toDataURL(url)

    // Set download attribute only if fileName is defined
    if (fileName) {
      link.download = fileName
    }
    link.target = '_blank'
    link.rel = 'noreferrer'
    window.document.body.appendChild(link)
    link.click()
    link.remove()
    if (!forceDirectLink && link.href.startsWith('blob:')) {
      window.URL.revokeObjectURL(link.href)
    }
  } catch (error) {
    console.error(`Failed to download ${url}.`, error)
  }
}
