import ExperimentIcon from '@/assets/icons/experiment.svg?react'
import { Tooltip } from '@/components/tooltip'

export function ExperimentalIcon (props) {
  return (
    <Tooltip orange title={'This feature is new, so you may encounter some inaccuracies. We appreciate your patience and feedback as we improve our AI. Rest assured that every day we\'re working hard to make it better!'}>
      <ExperimentIcon />
    </Tooltip>
  )
}
