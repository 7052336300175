import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import useMobileDetect from '@/hooks/use-mobile-detect'

const Container = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'unset !important',
  '&.mobile': {
    position: 'static',
    marginTop: '30px'
  }
})

/**
 * Spinner component
 *
 * @exports
 * @param {object} props
 * @param {string} props.text  - progress text
 * @param {boolean} props.hide  - Toggle
 * @returns {React.ReactElement}
 */
export function Spinner ({ hide = false, text }) {
  const isMobile = useMobileDetect()

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!hide && (
        <Container className={clsx([{ mobile: isMobile }])}>
          <CircularProgress />
          {!!text && <div style={{ color: 'black', fontWeight: 500 }}>{text}</div>}
        </Container>
      )}
    </>
  )
}
