import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

export const Container = styled('div')({
  width: 240,
  '& .items': {
    display: 'flex',
    flexDirection: 'column',

    '& .item': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 12px',
      height: 40,
      cursor: 'pointer',

      '& .icon': {
        fontSize: 20,
        color: COLORS['neutral-700']
      },
      '& .titleSection': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: COLORS['neutral-700'],
        flex: 1,

        '& .title': {
          marginLeft: 20,
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '150%'
        },
        '& .icon': {
          display: 'none'
        }
      },
      '&.active': {
        backgroundColor: COLORS['neutral-100'],
        cursor: 'default',
        '& .titleSection': {
          '& .icon': {
            display: 'inline-block'
          }
        }
      }
    }
  },
  '& .signOut': {
    width: 'fit-content',
    paddingLeft: 12,
    marginTop: 24,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    color: COLORS['danger-500'],
    cursor: 'pointer'
  }
})
