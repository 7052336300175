import { useEffect } from 'react'

import Container from './container'

const TermsOfServicePage = () => {
  useEffect(() => {
    const mainDiv = document.getElementsByClassName('main')[0]
    mainDiv.classList.add('converted-from-webflow')
    return () => {
      mainDiv.classList.remove('converted-from-webflow')
    }
  }, [])

  return (
    <Container>
      <div className='section'>
        <div className='container w-container'>
          <div className='center-content center-text margin-top-bottom'>
            <h1 className='limit-large'>Terms of Service</h1>
            <div className='opacity-dark-text limit-large small-text'>
              Last updated: 9 September 2024
            </div>
            <div className='rich-text-block-2 w-richtext'>
              <p>
                The following PB Vision Terms of Service (“<strong>Terms</strong>”) are a legal, binding agreement
                between Pickleball Vision AI, Inc. (“<strong>PB Vision</strong>”) and you (“<strong>You</strong>” or
                “<strong>Your</strong>”) and govern Your access to and use of any and all products, features or services
                provided or made available by or on behalf of PB Vision to You (collectively, “<strong>Services</strong>”).
                Any reference to PB Vision will include any and all of its affiliates, parent companies and wholly-owned
                subsidiaries.
              </p>
              <p>
                <strong>PLEASE READ CAREFULLY:</strong> PB VISION PROVIDES YOU ACCESS TO THE SERVICES SOLELY ON THE
                TERMS AND CONDITIONS SET FORTH IN THESE TERMS AND ON THE CONDITION THAT YOU ACCEPT AND COMPLY WITH ALL
                TERMS AND CONDITIONS IN THESE TERMS. BY CLICKING “ACCEPT”, SIGNING UP FOR THE SERVICE, OR BY ACCESSING
                OR USING ANY PB VISION SERVICE, YOU ACCEPT THESE TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY THESE
                TERMS. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THESE TERMS, (A) PB VISION MAY IMMEDIATELY
                SUSPEND OR TERMINATE YOUR ACCESS TO THE SERVICES, AND (B) YOU MUST NOT ACCESS OR USE THE SERVICES. YOU
                REPRESENT AND WARRANT THAT YOU ARE AT LEAST 18 YEARS OF AGE. IF YOU ARE UNDER THE AGE OF 18, YOU
                REPRESENT THAT YOU HAVE YOUR PARENT OR GUARDIAN’S PERMISSION TO USE THE SERVICE. PLEASE HAVE THEM READ
                THESE TERMS WITH YOU. IF YOU ARE A PARENT OR LEGAL GUARDIAN OF A USER UNDER THE AGE OF 18, BY ALLOWING
                YOUR CHILD TO USE THE SERVICE, YOU ARE SUBJECT TO THESE TERMS AND RESPONSIBLE FOR YOUR CHILD’S ACTIVITY
                ON THE SERVICE.
              </p>
              <p>
                THESE TERMS CONTAIN AN ARBITRATION AGREEMENT AND A CLASS ACTION WAIVER. THESE TERMS ARE VERY IMPORTANT
                TO READ CAREFULLY BECAUSE THE IMPACT, RESTRICT OR ELIMINATE YOUR ABILITY TO FILE SUIT IN A
                COURT.&nbsp;
              </p>
              <ol role='list'>
                <li className='list-item-3'>
                  <strong>FEES</strong>. You will pay PB Vision the fees as specified in any
                  online order. Except as otherwise specified in the online order, (i) payment obligations are
                  non-cancellable and fees paid are nonrefundable (except as otherwise herein provided), (ii) quantities
                  purchased cannot be decreased during the Term; and (iii) fees are due upon purchase unless otherwise
                  set forth in the online order. All fees and other amounts payable under these Terms are exclusive of
                  any taxes, levies, duties, or similar government assessments of any nature, including without
                  limitation, sales, use, value-added, or withholding taxes (collectively, “<strong>Taxes</strong>”). PB
                  Vision may change its fees and payment policies for the Service from time to time and such changes
                  will be posted on PB Vision’s website. The changes to the fees or payment policies are effective upon
                  the posting of the change to PB Vision’s website. Unless otherwise stated, all fees are quoted in U.S.
                  Dollars. Any outstanding balance becomes immediately due and payable upon termination of this
                  agreement for any reason and any collection expenses (including attorneys’ fees) incurred by PB Vision
                  will be included in the amount owed, and may be charged to the credit card or other billing mechanism
                  associated with Your account.
                </li>
                <li className='list-item-4'>
                  <strong>AGE REQUIREMENT, MEMBER ACCOUNT, PASSWORD, AND SECURITY</strong>.
                  You must be at least 18 years old to use the Service. If You are under 18, You represent that You have
                  Your parent or guardian’s permission to use the Service. If You are a parent or legal guardian of a
                  user under the age of 18, by allowing Your child to use the Service, You are subject to these Terms
                  and responsible for Your child’s activity on the Service. To register for the Service, You must
                  complete the registration process by providing PB Vision with current, complete and accurate
                  information as prompted by the sign-up form, including Your email address and password. You shall
                  protect Your passwords and take full responsibility for Your own, and third party, use of Your
                  accounts. You are solely responsible for any and all activities that occur under Your Accounts.
                  “<strong>Account</strong>” refers to Your unique account for the Service You agree to notify PB Vision
                  immediately upon learning of any unauthorized use of Your Account or any other breach of security.
                  From time to time, PB Vision’s support staff may access the Service under Your customer account in
                  order to maintain or improve service, including to provide You assistance with technical or billing
                  issues. You hereby acknowledge and consent to such access.
                </li>
                <li className='list-item-5'>
                  <strong>NONEXCLUSIVE RIGHT TO ACCESS SERVICES AND YOUR WARRANTIES.</strong>
                  <ol role='list'>
                    <li>
                      <strong>Right to Access. </strong>PB Vision hereby grants You a limited, revocable,
                      non-exclusive, non-sublicensable, non-transferable right to access and use the
                      Services via Your Account. Your right to access and use the Service is conditioned upon Your
                      compliance of all of Your obligations in this Section 3.
                    </li>
                    <li>
                      <strong>Use Restrictions.</strong> You will not nor will You allow any third party to: (i) copy,
                      modify, adapt, translate or otherwise create derivative works of the Service or any underlying
                      software; (ii) reverse engineer, de-compile, disassemble or otherwise attempt to discover the
                      source code of the Service or any underlying software; (iii) rent, lease, sell, assign or
                      otherwise transfer rights in or to the Service; (iv) remove any proprietary notices or labels on
                      the Service; (v) use, post, transmit or introduce any device, software or routine which interferes
                      or attempts to interfere with the operation of the Service or any underlying software or
                      technology infrastructure; (vi) access the Service using any automated means (such as robots,
                      botnets or scrapers); or (vii) collect or harvest any information that might identify a person
                      (for example, usernames or faces); (viii) use the Service or any data obtained from it for any
                      commercial purposes without express written permission from PB Vision; (ix) engage in automated
                      data collection, scraping, or crawling of the site and its contents; (x) use the data and content
                      on the platform for anything other than personal, non-commercial use; (xi) use the Service to
                      develop, directly or indirectly, any product or service that competes with any of
                      PB Vision&apos;s products or services.&nbsp;
                    </li>
                    <li>
                      <strong>Personal Use.</strong> You will use the Service solely for Your own personal use, and
                      You will not make the Service available to any third-parties.
                    </li>
                    <li>
                      <strong>Compliance With Law.</strong> You will comply with all applicable laws and regulations
                      in Your use of and access to the Service.
                    </li>
                    <li>
                      <strong>Termination.</strong> Your rights to access and use the Service will terminate
                      immediately if You fail to comply with these terms or any other agreement between You and PB
                      Vision. Upon such termination, You must cease any further use of the Service without the express
                      written consent of PB Vision.
                    </li>
                    <li>
                      <strong>Your Content.</strong> You represent and warrant that You are the rightful owner of Your
                      Content (as defined in Section 5) and that You have the legal right to grant the licenses and
                      permissions as detailed in these Terms. You further represent that You have obtained the
                      appropriate legal rights and permissions for any individual appearing in Your Content and that
                      such individual(s) has given their express consent for their likeness and image to be used by PB
                      Vision, including but not limited to training PB Vision’s AI models. You may not upload any
                      content or material that is (i) fraudulent, (ii) infringing on the rights of any third party,
                      (iii) libelous, defamatory, obscene, pornographic, profane, indecent or otherwise inappropriate
                      (including videos or images of a sexual nature) or (iv) otherwise unlawful. You will defend,
                      indemnify and hold harmless PB Vision against any claim, demand, suit or proceeding made or
                      brought against PB Vision as a result of Your actual or alleged breach of this Section 3.6.&nbsp;
                    </li>
                  </ol>
                </li>
                <li className='list-item-6'>
                  <strong>CONFIDENTIALITY.</strong>
                  <ol role='list'>
                    <li>
                      <strong>Definition</strong>. “<strong>Confidential Information</strong>” means, without
                      limitation, any and all nonpublic or proprietary information, documentation, or knowledge
                      concerning any of the following, as it pertains to PB Vision: accounts and billings, business
                      (past, present or future) information or activities, computer software or hardware,
                      computer/software code(s) and coding, business or technical concepts, contemplated new products or
                      services, contracts, customer lists and information, data analyses and compilations, designs,
                      diagrams, discoveries, drawings, financial information, flow charts, forecasts, information
                      systems data and materials, inventions, innovations, know how, marketing plans and information,
                      physical or digital materials, methods, models, operations information and data, plans, business
                      policies and procedures, price lists and policies, procedures, processes, product and service
                      information, business programs, business records, research, sales data and documents,
                      specifications, suppliers, systems, technical data, techniques, and trade secrets. All information
                      furnished hereunder or made available in any manner pursuant to this confidentiality agreement is
                      deemed confidential information whether or not identified as such.
                    </li>
                    <li>
                      <strong>Exclusions</strong>. Confidential Information does not include that which (i) is or
                      becomes part of the public domain other than as a result of disclosure by one of the parties; (ii)
                      becomes available to You on a non-confidential basis from a source other than PB Vision, provided
                      that source is not bound with respect to that information by a confidentiality agreement with PB
                      Vision or is otherwise prohibited from transmitting that information by a contractual, legal or
                      other obligation; or (iii) was already lawfully known by and was reduced to writing by You prior
                      to Your agreement to these terms and access to any Confidential Information or services, or any
                      confidential discussions between the parties. In any dispute with respect to these exclusions, the
                      burden of proof will be on the party claiming the exclusion applies and such proof will be by
                      clear and convincing evidence.
                    </li>
                    <li>
                      <strong>Disclosure</strong>. You will not use or disclose Confidential Information without PB
                      Vision’s prior written consent except for the purpose of performing Your obligations under these
                      terms. If You are compelled to disclose Confidential Information by a court of competent
                      jurisdiction, You shall give PB Vision as much notice as is reasonably practicable prior to
                      disclosing such information.
                    </li>
                    <li>
                      <strong>Return</strong>. Upon termination or expiration of these terms, You will promptly either
                      return or destroy (at PB Vision’s direction) all Confidential Information and, upon request,
                      provide written certification of such.
                    </li>
                    <li>
                      <strong>Use</strong>. You are responsible for safeguarding the confidentiality of Your
                      password(s) and user name(s) issued to You by PB Vision, and for any use or misuse of Your account
                      resulting from any third party using a password or user name issued to You. You agree to
                      immediately notify PB Vision of any unauthorized use of Your account or any other breach of
                      security known to You.
                    </li>
                  </ol>
                </li>
                <li className='list-item-7'>
                  <strong>YOUR CONTENT</strong>. In connection with Your use of the Service,
                  You may be able to upload into the Service videos, recordings, data, documents, files, and any other
                  materials or information provided by You into the Service (“<strong>Your Content</strong>”). You
                  retain ownership rights to Your Content. PB Vision is under no obligation to host or receive any
                  content. Subject to PB Vision’s Privacy Policy, You hereby grant PB Vision a non-exclusive, worldwide,
                  sublicensable, transferable, royalty-free, fully paid up, perpetual and irrevocable right and license
                  to use, reproduce, publicly perform, publicly display, transmit, distribute, aggregate, translate,
                  alter, modify, and create derivative works of Your Content, in any and all media, whether now or
                  hereafter known or devised, and by any and all technologies and means of delivery, whether now or
                  hereafter known or devised (i) for the purposes of providing the Service to You; (ii) for PB Vision’s
                  own marketing, promotional, educational, or other similar uses; and (iii) to improve, enhance, and
                  train the Service or any underlying software or models, including for use with other users, and such
                  improvements, enhancements, and training may be by means of artificial intelligence, machine learning,
                  and other similar means. This license shall survive the termination of this Agreement. Any
                  improvement, enhancement, and/or change to the Service due to the training contemplated under this
                  section remains the sole and exclusive property of PB Vision.&nbsp;
                </li>
                <li className='list-item-8'>
                  <strong>PRIVACY.</strong>
                  <ol role='list'>
                    <li>
                      <strong>Privacy</strong>. You hereby acknowledge and agree that PB Vision will use, reproduce,
                      modify, distribute and display Your Content and Your Data as set forth in PB Vision’s Privacy
                      Policy available at: <a href='https://www.pb.vision/privacy-policy'>https://www.pb.vision/privacy-policy</a>
                      , as may be amended from time to time. “<strong>Your Data</strong>” means any and all data or information that
                      (i) You upload to the Service or otherwise provide to PB Vision via the Services or Your Account,
                      (ii) is derived from Your use of the Service, or (iii) is collected or made available to PB Vision
                      in connection with Your use of the Service. Your Data may include personal data.
                    </li>
                    <li>
                      <strong>Aggregated Statistics.</strong> Notwithstanding anything to the contrary in these terms,
                      PB Vision may monitor Your use of the Service and collect and compile Aggregated Statistics.
                      “<strong>Aggregated Statistics</strong>” means data and information related to Your use of the
                      Service that is used by PB Vision in an aggregate and anonymized manner, including to compile
                      statistical and performance information related to the provision and operation of the Service. As
                      between PB Vision and You, all right, title, and interest in Aggregated Statistics, and all
                      intellectual property rights therein, belong to and are retained solely by PB Vision. You
                      acknowledge that PB Vision may compile Aggregated Statistics based on Your Data input into the
                      Service. You agree that PB Vision may (i) make Aggregated Statistics publicly available in
                      compliance with applicable law, and (ii) use Aggregated Statistics to the extent and in the manner
                      permitted under applicable law; provided that such Aggregated Statistics do not identify You.
                    </li>
                  </ol>
                </li>
                <li className='list-item-9'><strong>INDEMNIFICATION</strong>.
                  <ol role='list'>
                    <li>
                      <strong>Indemnity</strong>. To the maximum extent permitted under applicable law, You agree to
                      indemnify, hold harmless and defend PB Vision and its officers, directors, employees, agents,
                      affiliates, successors and assigns at Your expense, for any and all claims, actions, proceedings,
                      and suits brought against PB Vision or any of its officers, directors, employees, agents,
                      affiliates, successors or assigns, and all related liabilities, damages, losses, settlements,
                      penalties, fines, costs or expenses (including, without limitation, reasonable attorneys’ fees and
                      other litigation expenses) incurred by PB Vision or any of its officers, directors, employees,
                      agents or affiliates, arising out of, relating to, alleging or based upon:
                      <ol role='list'>
                        <li>Your breach of any term or condition of these Terms;</li>
                        <li>Your use of the Service;</li>
                        <li>Your violation of any applicable law, rule or regulation;</li>
                        <li>Any representations or warranties made by You concerning the Service;</li>
                        <li>Any claims made by or on behalf of a third party pertaining to Your use of the Service;</li>
                        <li>Violations of Your obligations of privacy to any third party;</li>
                        <li>Any claims about acts or omissions of third parties in connection with the Service; or</li>
                        <li>Your Data.</li>
                      </ol>
                    </li>
                    <li>
                      <strong>Procedure</strong>. PB Vision will provide You with written notice of any such claim,
                      suit or action, provided, however, failure to do so does not relieve You of Your obligations in
                      Section 7.1. You shall cooperate as fully as reasonably required in the defense of any claim. PB
                      Vision reserves the right, at its own expense, to assume the exclusive defense and control of any
                      matter subject to indemnification by You. You shall not settle any indemnified claim in any manner
                      that requires PB Vision to admit fault, pay money or comply with any ongoing covenants.
                    </li>
                  </ol>
                </li>
                <li className='list-item-10'><strong>INTELLECTUAL PROPERTY</strong>.
                  <ol role='list'>
                    <li>
                      <strong>Definition of Intellectual Property</strong>. For purposes of these Terms “
                      <strong>Intellectual Property </strong>” will mean, without limitation: Business information, business methods,
                      codes and coding methods, compilations, copyrightable works, designs, developments, discoveries,
                      drawings, engineering, formulas, hardware configuration information, improvements, innovations,
                      inventions, machines, original works of authorship, processes, products, service marks, software,
                      technical data, technology, trade dress, trade secrets, trademarks, and writings.
                    </li>
                    <li>
                      <strong>Ownership</strong>. All Intellectual Property rights in the Service and any other
                      related services or products under these Terms, belong to PB Vision. The rights in the
                      Intellectual Property include, but are not limited to: all rights under U.S. and foreign
                      trademark, trade secret, patent and copyright laws; all reproduction, performance, display and
                      distribution rights; the right to modify, distort or alter; the right to produce derivative works;
                      and all so-called moral rights. Intellectual Property is deemed Confidential Information.
                    </li>
                    <li>
                      <strong>No Implied Licenses</strong>. Except for the limited, revocable rights to access and use
                      the Service expressly set forth in these Terms, no right or license is granted under these Terms
                      by or on behalf of PB Vision to or under any Intellectual Property or intellectual property right,
                      whether by express grant, implication, estoppel or otherwise.
                    </li>
                    <li>
                      <strong>Feedback</strong>. You hereby grant PB Vision a perpetual, irrevocable, worldwide
                      license to use any Feedback (as defined below) You communicate to PB Vision, without compensation,
                      without any obligation to report on such use, and without any other restriction. PB Vision’s
                      rights granted in the previous sentence include, without limitation, the right to exploit Feedback
                      in any and every way, as well as the right to grant sublicenses under copyright, patent, and any
                      other form of intellectual property. Feedback is deemed to be Confidential Information and not
                      Your confidential information or trade secret. “<strong>Feedback</strong>” refers to any
                      suggestion or idea for modifying any of PB Vision’s products, goods, services, software or
                      technologies.
                    </li>
                    <li>
                      <strong>Takedown</strong>. If Your copyright protected work was posted on the Service without
                      Your authorization, You can submit a copyright removal request to request that the content be
                      removed. Submitting a copyright removal request is a legal process. Please submit all copyright
                      removal requests to PB Vision’s address set forth below.
                    </li>
                  </ol>
                </li>
                <li className='list-item-11'>
                  <strong>DISCLAIMER OF WARRANTIES</strong>.
                  <ol role='list'>
                    <li>
                      <strong>Changes</strong>. PB Vision is constantly changing and improving the Service. As part of
                      this continual evolution, PB Vision may make modifications or changes (to all or part of the
                      Service) such as adding or removing features and functionalities, offering new digital content or
                      services or discontinuing old ones. PB Vision may also need to alter or discontinue the Service,
                      or any part of it, in order to make performance or security improvements, make changes to comply
                      with law, or prevent illegal activities on or abuse of PB Vision systems. These changes may affect
                      all users, some users or even an individual user. When the Service requires or includes
                      downloadable software, that software may update automatically on Your device once a new version or
                      feature is available, subject to Your device settings. The information and services included in or
                      available through the Service may include inaccuracies or typographical errors. Changes are
                      periodically added to the information herein.&nbsp;
                    </li>
                    <li>
                      <strong>Errors</strong>. PB Vision does not represent or warrant that the Service will be
                      uninterrupted or error- free, that defects will be corrected, or that the Service or any other
                      software used in connection with the Service are free of viruses or other harmful components. PB
                      Vision does not warrant or represent that the use of the Service will be correct, accurate, timely
                      or otherwise reliable.&nbsp;
                    </li>
                    <li>
                      <strong>Your Data</strong>. You specifically agree that PB Vision will not be responsible for
                      unauthorized access to or alteration of Your Data by third parties.
                    </li>
                    <li>
                      <strong>As-Is</strong>. The Service is provided “as is” and there are no warranties, claims or
                      representations made by PB Vision either express, implied, or statutory, with respect to the
                      Service, including warranties of quality, performance, non-infringement, merchantability, or
                      fitness for a particular purpose, nor are there any warranties created by course of dealing,
                      course of performance, or trade usage. PB Vision does not warrant the Service will meet Your needs
                      or be free from errors, or the operation of the service will be uninterrupted.
                    </li>
                    <li>
                      <strong>Basis of Bargain</strong>. The foregoing exclusions and disclaimers are an essential
                      part of these Terms and formed the basis for determining the price charged for the Service. Some
                      states do not allow exclusion of an implied warranty, so this disclaimer may not apply to You.
                    </li>
                    <li>
                      <strong>Third-Party Products</strong>. PB Vision may from time to time make Third-Party Products
                      available to You. “<strong>Third-Party Products</strong>” means any services, software or
                      technology provided or made available to You that is owned by a third party and subject to its own
                      terms and conditions as required by the owner of such services, software or technology. Such
                      Third-Party Products are subject to their own terms and conditions and the applicable flow-through
                      provisions. If You do not agree to abide by the applicable terms for any such Third-Party
                      Products, then You shall not install, access or use such Third-Party Products. The Service may
                      contain links to third-party websites and online services that are not owned or controlled by PB
                      Vision. PB Vision has no control over, and assumes no responsibility for, such websites and online
                      services. Be aware when You leave the Service; PB Vision suggests You read the terms and privacy
                      policy of each third-party website and online service that You visit.
                    </li>
                  </ol>
                </li>
                <li className='list-item-12'>
                  <strong>LIMITATION OF LIABILITY</strong>.
                  <ol role='list'>
                    <li>
                      <strong>CONSEQUENTIAL DAMAGES. </strong>PB VISION WILL NOT BE LIABLE TO YOU OR ANY USER OR
                      THIRD-PARTY CLAIMANT FOR ANY INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL (INCLUDING, WITHOUT
                      LIMITATION, LOST PROFITS OR LOST DATA COLLECTED THROUGH THE SERVICE), OR INCIDENTAL DAMAGES
                      ARISING OUT OF OR RELATING TO THE SERVICE, THE UNDERLYING SOFTWARE, THESE TERMS OR ANY
                      TRANSACTIONS CONTEMPLATED UNDER THESE TERMS, WHETHER BASED ON A CLAIM OR ACTION OF CONTRACT,
                      WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR OTHER TORT, BREACH OF ANY STATUTORY DUTY, INDEMNITY OR
                      CONTRIBUTION, OR OTHERWISE, EVEN IF PB VISION HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                      THE EXCLUSION CONTAINED IN THIS PARAGRAPH WILL APPLY REGARDLESS OF THE FAILURE OF THE EXCLUSIVE
                      REMEDY PROVIDED IN THE FOLLOWING PARAGRAPH.
                    </li>
                    <li>
                      <strong>LIMITATION OF DAMAGES. </strong>PB VISION’S AND ITS AFFILIATES’ TOTAL CUMULATIVE
                      LIABILITY TO YOU, YOUR AFFILIATES AND ANY AND ALL THIRD PARTIES ARISING OUT OF OR RELATING TO THE
                      SERVICE, THE UNDERLYING SOFTWARE, THESE TERMS OR ANY TRANSACTIONS CONTEMPLATED UNDER THESE TERMS
                      WILL NOT, IN THE AGGREGATE, EXCEED THE GREATER OF U.S. $500 AND THE AMOUNTS ACTUALLY PAID BY YOU
                      TO PB VISION FOR THE SERVICE IN THE 6 MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT CAUSING THE
                      DAMAGE RELATING TO YOUR FIRST CLAIM MADE AGAINST PB VISION UNDER THESE TERMS. THE FOREGOING IS AN
                      AGGREGATE CAP AND NOT A PER CLAIM, PER INCIDENT OR PER LIABILITY CATEGORY CAP.
                    </li>
                    <li>
                      <strong>NO RELIANCE. </strong>SOME STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
                      FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE LIMITATIONS OR EXCLUSIONS IN THIS SECTION 10 MAY
                      NOT APPLY TO YOU. YOU ACKNOWLEDGE AND AGREE THAT IN ENTERING INTO THESE TERMS YOU HAVE NOT RELIED
                      AND ARE NOT RELYING ON ANY REPRESENTATIONS, WARRANTIES OR OTHER STATEMENTS WHATSOEVER, WHETHER
                      WRITTEN OR ORAL (FROM OR BY PB VISION OR ANY OF ITS AFFILIATES OR REPRESENTATIVES) OTHER THAN
                      THOSE EXPRESSLY SET FORTH IN THESE TERMS AND THAT YOU WILL NOT HAVE ANY RIGHT OR REMEDY RISING OUT
                      OF ANY REPRESENTATION, WARRANTY OR OTHER STATEMENT NOT EXPRESSLY SET FORTH IN THESE TERMS. YOU
                      RECOGNIZE AND AGREE THAT THE WARRANTY DISCLAIMERS AND LIABILITY AND REMEDY LIMITATIONS IN THESE
                      TERMS ARE MATERIAL, BARGAINED FOR BASES OF THESE TERMS AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT
                      AND REFLECTED IN DETERMINING THE CONSIDERATION TO BE GIVEN BY EACH PARTY UNDER THESE TERMS AND IN
                      THE DECISION BY EACH PARTY TO ENTER INTO THESE TERMS.
                    </li>
                  </ol>
                </li>
                <li className='list-item-13'>
                  <strong>SERVICE LEVELS</strong>. PB Vision does not guarantee the Service
                  will be operable for any specific period of time. PB Vision does not guarantee that the Service will
                  be operable during any down time (i) caused by outages to any public Internet backbones, networks or
                  servers, (ii) caused by Your failure to use the Service in accordance with these Terms (iii) caused by
                  any failures of Your equipment, systems or local access services, (iv) for previously scheduled
                  maintenance or (v) relating to events beyond PB Vision’s control such as strikes, riots, insurrection,
                  fires, floods, explosions, war, governmental action, labor conditions, earthquakes, natural disasters,
                  or interruptions in Internet services to an area where PB Vision or Your servers are located or
                  co-located.
                </li>
                <li className='list-item-14'>
                  <strong>PROPRIETARY RIGHTS NOTICE</strong>. The Service and all
                  intellectual property rights in the Service are, and shall remain, the property of PB Vision. All
                  rights in and to the Service not expressly granted to You in these Terms are hereby expressly reserved
                  and retained by PB Vision and its licensors without restriction, including, without limitation, PB
                  Vision’s right to sole ownership of the Service. Without limiting the generality of the foregoing, You
                  agree not to (and to not allow any third party to): (a) sublicense, distribute, or use the Service;
                  (b) copy, modify, adapt, translate, prepare derivative works from, reverse engineer, disassemble, or
                  decompile the Service or otherwise attempt to discover any source code or trade secrets related to the
                  Service; (c) use, register, attempt or assist anyone else to register the trademarks, trade names,
                  service marks, logos, domain names and other distinctive brand features or any copyright or other
                  proprietary rights associated with the Service for any purpose without the express written consent of
                  PB Vision; or (d) remove, obscure, or alter any notice of copyright, trademark, or other proprietary
                  right appearing in or on any item included with the Service.
                </li>
                <li className='list-item-15'>
                  <strong>TERM AND TERMINATION</strong>.
                  <ol role='list'>
                    <li>
                      <strong>Term</strong>. These Terms shall commence upon Your use of the Service and shall
                      continue for as long as the Service is provided or until termination. PB Vision may suspend or
                      terminate Your access to or use of the Service at any time, for any reason or no reason, and with
                      or without notice.
                    </li>
                    <li>
                      <strong>Effect of Termination</strong>. Upon any termination or expiration of these Terms, PB
                      Vision will cease providing the Service to You and any and all rights granted to You under these
                      Terms shall cease. In the event of any termination (a) You will not be entitled to any refunds of
                      any usage fees or any other fees, and (b) any (i) outstanding balance for Service rendered through
                      the date of termination, and (ii) other unpaid payment obligations during the remainder of the
                      term will be immediately due and payable in full.&nbsp;
                    </li>
                  </ol>
                </li>
                <li className='list-item-16'>
                  <strong>MODIFICATIONS TO TERMS OF SERVICE AND OTHER POLICIES</strong>. PB
                  Vision reserves the right to change or modify any of the terms and conditions contained in these Terms
                  or any policy governing the Service, at any time, by posting the modified agreement to&nbsp;
                  <a href='https://www.pb.vision/terms-of-service'>https://www.pb.vision/terms-of-service</a> (or any of
                  its subdomains). You are responsible for regularly reviewing the policy. No amendment to or
                  modification of these Terms will be binding unless (i) in writing and signed by a duly authorized
                  representative of PB Vision, (ii) You accept updated terms online, or (iii) You continue to use the
                  Service after PB Vision has posted updates to these Terms at&nbsp;
                  <a href='https://www.pb.vision/terms-of-service'>https://www.pb.vision/terms-of-service</a>. Your use
                  of the Service after PB Vision has posted updates to these Terms will constitute Your acceptance of
                  any revised terms and conditions. If You object to any change to these Terms made by PB Vision in
                  accordance with this section, You should immediately cease use of the Services and remove Your Content
                  from the Services.
                </li>
                <li className='list-item-17'>
                  <strong>DISPUTE RESOLUTION</strong>.
                  <ol role='list'>
                    <li>
                      <strong>Choice of Law and Venue.</strong> These Terms will be governed by and construed under
                      the laws of the state of Delaware without reference to its conflict of law principles. In the
                      event of any conflicts between foreign law, rules, and regulations, and Delaware law, rules, and
                      regulations, Delaware law, rules and regulations shall prevail and govern. Subject to Sections
                      15.2 - 15.8, each party agrees to submit to the exclusive and personal jurisdiction of the courts
                      located in Delaware. The United Nations Convention on Contracts for the International Sale of
                      Goods (CISG) and the Uniform Computer Information Transactions Act (UCITA) shall not apply to
                      these Terms.
                    </li>
                    <li>
                      <strong>Rights to Litigate.</strong> YOU AND PB VISION ARE AGREEING TO GIVE UP ANY RIGHTS TO
                      LITIGATE CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE
                      ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE
                      UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
                    </li>
                    <li>
                      <strong>Mandatory Arbitration.</strong> ANY CLAIM, DISPUTE, OR CONTROVERSY (WHETHER IN CONTRACT,
                      TORT, OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT, OR FUTURE, AND INCLUDING STATUTORY, CONSUMER
                      PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU AND PB
                      VISION ARISING FROM OR RELATING IN ANY WAY TO THE SERVICE, THESE TERMS OR THE TRANSACTIONS
                      CONTEMPLATED HEREIN, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.
                    </li>
                    <li>
                      <strong>AAA Rules.</strong> The arbitration will be administered by the American Arbitration
                      Association (“<strong>AAA</strong>”) in accordance with the Commercial Arbitration Rules (the
                      “<strong>AAA Rules</strong>”) then in effect, except as modified by this Section 15. The Federal
                      Arbitration Act will govern the interpretation and enforcement of this section. The arbitration
                      will take place in Delaware.
                    </li>
                    <li>
                      <strong>Arbitrator Authority.</strong> The arbitrator will have exclusive authority to resolve
                      any dispute relating to arbitrability and/or enforceability of this arbitration provision,
                      including any unconscionability challenge or any other challenge that the arbitration provision or
                      the agreement is void, voidable, or otherwise invalid. The arbitrator will be empowered to grant
                      whatever relief would be available in court under law or in equity. Any award of the arbitrator(s)
                      will be final and binding on each of the parties and may be entered as a judgment in any court of
                      competent jurisdiction.
                    </li>
                    <li>
                      <strong>Class Action Waiver.</strong> You agree to an arbitration on an individual basis. In any
                      dispute, NEITHER YOU NOR PB VISION WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST
                      OTHER CUSTOMERS IN COURT OR ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS
                      REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. The arbitral tribunal may
                      not consolidate more than one person’s claims and may not otherwise preside over any form of a
                      representative or class proceeding. The arbitral tribunal has no power to consider the
                      enforceability of this class arbitration waiver and any challenge to the class arbitration waiver
                      may only be raised in a court of competent jurisdiction.
                    </li>
                    <li>
                      <strong>Arbitration Agreement Severability. </strong>If any provision of this arbitration
                      agreement is found unenforceable, the unenforceable provision will be severed, and the remaining
                      arbitration terms will be enforced.
                    </li>
                    <li>
                      <strong>Exceptions</strong>. Notwithstanding anything to the contrary herein, You and PB Vision:
                      (i) may still bring an individual action in small claims court; (ii) may still pursue an
                      enforcement action through a federal, state, or local agency if that action is available; and
                      (iii) must file suit in court to address an intellectual property rights infringement claim. Also,
                      nothing in this Section 15 bars either of us from bringing issues to the attention of federal,
                      state, or local agencies.
                    </li>
                  </ol>
                </li>
                <li className='list-item-18'><strong>MISCELLANEOUS</strong>.
                  <ol role='list'>
                    <li>
                      <strong>Force Majeure.</strong> PB Vision shall be excused from performance hereunder if it is
                      prevented from or delayed in performing its obligations under these Terms, or from carrying on its
                      business, by acts, events, omissions or accidents beyond its reasonable control, including, but
                      not limited to, labor disputes (whether involving the workforce of PB Vision or any other party),
                      strikes, lockouts, shortages of or inability to obtain labor, failure of a utility service or
                      telecommunications network, breakdown of plant or machinery, default of suppliers or
                      subcontractors, war, pandemic, epidemic, COVID-19 or its variants, terrorism, riot, civil
                      commotion, malicious damage, compliance with any law or governmental order, rule, regulation or
                      direction, accident, act of God, fire, flood or storm (each, a “<strong>Force Majeure Event</strong>”).&nbsp;
                    </li>
                    <li>
                      <strong>Entire Agreement.</strong> These Terms constitute the complete and exclusive
                      understanding and agreement between the parties regarding its subject matter and supersede all
                      prior or contemporaneous agreements or understandings, written or oral, relating to its subject
                      matter. Notwithstanding any language to the contrary therein, no terms or conditions stated in a
                      purchase order, vendor onboarding process or web portal, or any other documentation authored
                      and/or provided by You (excluding documents authored by PB Vision) will be incorporated into or
                      form any part of these Terms unless agreed to by an authorized representative of PB Vision in
                      writing, and all such terms or conditions will be null and void.&nbsp;
                    </li>
                    <li>
                      <strong>Severability</strong>. If any provision of these Terms is held to be unenforceable for
                      any reason, such provision shall be reformed to the extent necessary to make it enforceable to the
                      maximum extent permissible so as to affect the intent of the parties, and the remainder of these
                      Terms shall continue in full force and effect.
                    </li>
                    <li>
                      <strong>Headings</strong>. Section headings are not to be considered part of these Terms nor are
                      they intended to be a full or accurate description of the contents.
                    </li>
                    <li>
                      <strong>Notices</strong>. Any notices to PB Vision must be sent to:&nbsp;
                      <a href='mailto:legal@pb.vision'>legal@pb.vision</a>.
                    </li>
                    <li>
                      <strong>Waiver</strong>. A waiver of any right(s) is not a continuing waiver of that or any
                      other right(s).
                    </li>
                    <li>
                      <strong>Assignment and Relationship</strong>. You may not assign or otherwise transfer any of
                      Your rights or obligations hereunder without PB Vision’s prior written consent, and any such
                      attempt is null and void. PB Vision may assign, pledge or otherwise transfer (whether by operation
                      of law, acquisition or sale of stock or assets, merger, consolidation, transfer of control or
                      otherwise) or subcontract its rights and obligations under these Terms without the prior written
                      consent of You. The relationship between PB Vision and You is not one of a legal partnership
                      relationship, but is one of independent contractors. These Terms shall be binding upon and inure
                      to the benefit of the respective successors and assigns of the parties hereto.
                    </li>
                    <li>
                      <strong>Limitation on Legal Action</strong>. YOU AND PB VISION AGREE THAT ANY CAUSE OF ACTION
                      ARISING OUT OF OR RELATED TO THE SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
                      ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
                    </li>
                    <li>
                      <strong>Survival</strong>. The following sections of these Terms will survive any termination or
                      expiration of these Terms: 1, 2, 3.2-3.6 (inclusive), 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                      and 16.
                    </li>
                  </ol>
                </li>
              </ol>
              <p>&zwj;</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default TermsOfServicePage
