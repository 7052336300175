import { useLocation } from 'react-router-dom'

export const singlePathsToProxy = [
  '/blog',
  '/api-license-agreement'
]
export const nestedPathsToProxy = [
  '/post'
]

const webflowPaths = new Set([...singlePathsToProxy, ...nestedPathsToProxy])

export function useIsWebflowLocation () {
  const { pathname } = useLocation()
  const secondSlashIdx = pathname.indexOf('/', 1)
  const pathFirstElement = secondSlashIdx === -1
    ? pathname
    : pathname.substring(0, secondSlashIdx)
  return webflowPaths.has(pathFirstElement)
}
