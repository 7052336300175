import styles from './styles.module.css'

/**
 * ListView component
 *
 * @exports
 * @returns {React.ReactElement} ListView component
 */
function ListView () {
  return (
    <div className={styles.listView}>
      <h2>Library List View</h2>
    </div>
  )
}

export default ListView
