import PersonIcon from '@mui/icons-material/Person'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'

import { authSlice, getLoggedInUserToken, selectLoggedInUser, updateCurrentUser } from '@/store/auth'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import COLORS from '@/utils/colors'

const hasAvatar = (user) => {
  const version = user?.avatarVersion
  return (typeof version === 'number') && version > 0
}

const Container = styled('div')(({ url }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '100px',
  width: '1em',
  height: '1em',
  borderRadius: '0.5em',
  border: `3px solid ${COLORS['neutral-300']}`,
  backgroundImage: `url("${url}")`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  '& .MuiSvgIcon-root': {
    display: 'none'
  },
  '&.isPlaceholder': {
    backgroundImage: 'none',
    backgroundColor: COLORS['neutral-200'],
    '& .MuiSvgIcon-root': {
      display: 'inline-block',
      fontSize: '0.62em',
      color: COLORS['neutral-400']
    }
  }
}))

/**
 * UserAvatar component
 *
 * @exports
 * @param props {object} { className, isPlaceholder, imageUrl }
 * @returns {React.ReactElement}
 */
const UserAvatar = (props) => {
  const user = useSelector(selectLoggedInUser)
  const { uid } = user
  const isPlaceholder = props.isPlaceholder || (!props.imageUrl && !hasAvatar(user))
  const loggedInUserAvatarUrl = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/users/${uid}/avatar${user.avatarVersion}.jpg`
  const avatarUrl = props.imageUrl || loggedInUserAvatarUrl

  return (
    <Container
      url={avatarUrl}
      className={clsx([props.className, { isPlaceholder }])}
    >
      <PersonIcon />
    </Container>
  )
}

export const useAvatar = () => {
  const user = useSelector(selectLoggedInUser)
  const dispatch = useDispatch()
  const openSnackbar = useSnackbar()

  const getBase64Data = (image) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const maxBase64Size = 1024 * 1024

    // Set canvas width and height to image dimensions
    canvas.width = image.width
    canvas.height = image.height

    ctx.drawImage(image, 0, 0)

    let base64Data = canvas.toDataURL('image/jpeg')
    let quality = 1

    // If base64 string exceeds 1MB, progressively reduce the quality
    while (base64Data.length > maxBase64Size && quality > 0.1) {
      base64Data = canvas.toDataURL('image/jpeg', quality)
      quality -= 0.1
    }

    // If it's still too large, reduce image dimensions (downscale)
    while (base64Data.length > maxBase64Size) {
      canvas.width *= 0.9 // Reduce the canvas size by 10%
      canvas.height *= 0.9
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
      base64Data = canvas.toDataURL('image/jpeg', quality)
    }

    return base64Data.split(',')[1]
  }

  const refreshUser = async () => {
    const url = `${import.meta.env.VITE_API_SERVER}/user/me`
    const token = await getLoggedInUserToken()
    const resp = await window.fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'x-uid': user.uid,
        'x-token': token
      }
    })
    const respData = await resp.json()
    if (resp.status !== 200) {
      dispatch(authSlice.actions.failedToLoadCustomData(respData.message || 'unknown error'))
    } else {
      dispatch(authSlice.actions.loadedCustomData(respData))
    }
  }

  const setAvatar = async (imageEl) => {
    const payload = {
      image: imageEl ? getBase64Data(imageEl) : '',
      displayName: user.displayName,
      notificationsOff: {
        marketing: !!user.notificationsOff?.marketing,
        tagged: !!user.notificationsOff?.tagged,
        videoDone: !!user.notificationsOff?.videoDone
      }
    }
    try {
      dispatch(updateCurrentUser(payload))
    } catch (error) {
      openSnackbar(error, 'error')
    }
  }

  return {
    setAvatar,
    removeAvatar: async () => await setAvatar(),
    getBase64Data,
    UserAvatar,
    refreshUser,
    hasAvatar: () => hasAvatar(user)
  }
}
