/**
 * Request Picture in Picture of Video.
 *
 * @param {HTMLVideoElement} videoElement
 * @returns {boolean} true or false depending if pip was successful
 */
export function enterPictureInPicture (videoElement) {
  if (document.pictureInPictureEnabled && !videoElement.disablePictureInPicture) {
    try {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture()
      }

      videoElement.requestPictureInPicture()
      return true
    } catch (err) {
      console.error(err)
    }
  }
  return false
}
