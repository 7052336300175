import Page from '../anonymous-upload/index'

import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

// this page only exists to show to the native mobile app
export default isInMobileAppWebview()
  ? [
      {
        path: '/first_upload_started',
        element: <Page isNative />
      }
    ]
  : []
