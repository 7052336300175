import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useLoggedInUser, useMyMonetizationData } from '@/store/auth'
import { isInMobileAppWebview, notifySupportRequested } from '@/utils/mobile-app-communication'

export default function useZendesk () {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const { isLoading, subscriptions, creditsLeft, isFreeTrialExpired, currentSubscription } = useMyMonetizationData()
  const loggedInUser = useLoggedInUser()

  const updateZendeskURL = useCallback(() => {
    if (window.zE) {
      // ticket id: 22361439920148
      // field name/id: custom_field_originating_url 31103031668244
      // custom_field_credit 31708719095316
      // custom_field_user_type 31708760642708
      const userType = currentSubscription ? `Active ${currentSubscription.tier} plan` : !isFreeTrialExpired ? 'Active free trial' : subscriptions.length ? `Expired ${subscriptions[0].tier} plan` : 'None'

      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            ticketForms: [
              {
                id: 22361439920148,
                fields: [
                  { id: 31103031668244, prefill: { '*': window.location.href } },
                  { id: 31708719095316, prefill: { '*': creditsLeft } },
                  { id: 31708760642708, prefill: { '*': loggedInUser ? userType : 'Anonymous' } },
                  { id: 31726394869268, prefill: { '*': loggedInUser?.uid ?? '' } }
                ]
              }
            ]
          }
        }
      })
      // Prefill must run after the Zendesk widget has loaded, or it may not apply correctly.
      if (loggedInUser) {
        window.zE('webWidget', 'prefill', {
          name: { value: loggedInUser.displayName },
          email: { value: loggedInUser.email, readOnly: true }
        })
      } else {
        // Reset the generic fields when user logs out
        window.zE('webWidget', 'prefill', {
          name: { value: '' },
          email: { value: '', readOnly: false }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditsLeft, currentSubscription, isFreeTrialExpired, loggedInUser?.displayName, loggedInUser?.email, subscriptions])

  const hideCustomField = useCallback(() => {
    /* Zendesk - hide custom field */
    const iframe = document.getElementById('webWidget')

    if (iframe) {
      const style = document.createElement('style')
      style.innerHTML = `
          label[data-fieldid="key:31103031668244"], input[name="key:31103031668244"],
          label[data-fieldid="key:31708719095316"], input[name="key:31708719095316"],
          label[data-fieldid="key:31708760642708"], input[name="key:31708760642708"],
          label[data-fieldid="key:31726394869268"], input[name="key:31726394869268"] {
            display: none;
        }`
      document.getElementById('webWidget').contentDocument.head.appendChild(style)

      // Hide ticket form title
      const ticketFormTitle = iframe.contentDocument.querySelector('div[class*="TicketFormTitle"]')

      if (ticketFormTitle) {
        ticketFormTitle.style.display = 'none'
      }
    } else if (isOpen) {
      // Retry every 500ms until iframe is found or widget is closed
      setTimeout(hideCustomField, 500)
    }
  }, [isOpen])

  /**
   * usage of window.zE('webWidget', 'hide') prevent displaying default ZenDesk chat bubble.
   */

  function onScriptLoaded () {
    // Set up Zendesk widget settings
    if (window.zE) {
      window.zE('webWidget', 'hide') // initially prevent displaying bubble

      window.zESettings = {
        webWidget: {
          position: { horizontal: 'right', vertical: 'bottom' },
          zIndex: 10000
        }
      }

      // Subscribe to Zendesk onCLOSE widget events
      window.zE('webWidget:on', 'close', function () {
        // This function will be called when the Zendesk widget is closed by their natively event (click on -)
        window.zE('webWidget', 'hide')
        setIsOpen(false)
      })

      // Polling to ensure Zendesk widget is ready
      const waitForWidget = () => {
        if (window.zE) {
          // Call the prefill function once the widget is ready
          updateZendeskURL()
        } else {
          // Retry every 500ms until zE is available
          setTimeout(waitForWidget, 500)
        }
      }

      // Start the check when the component mounts
      waitForWidget()
    }
  }

  useEffect(() => {
    if (document.getElementById('ze-snippet') && !isLoading) {
      // Make sure to update the URL and custom fields on location or subscription data change
      updateZendeskURL()
    }
  }, [location, isLoading, updateZendeskURL])

  useEffect(() => {
    // Check if the script is already included
    const existingScript = document.getElementById('ze-snippet')
    if (existingScript) {
      // If it exists, call the onScriptLoaded function directly
      onScriptLoaded()
    } else if (!isInMobileAppWebview()) {
      const script = document.createElement('script')
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${import.meta.env.VITE_ZENDESK_KEY}`
      script.defer = true
      script.id = 'ze-snippet'
      script.addEventListener('load', onScriptLoaded)
      document.body.appendChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isOpen) {
      hideCustomField()
    }
  }, [hideCustomField, isOpen])

  function toggleZendeskWidget () {
    // Open email client if in mobile app webview
    // This is a workaround for the issue where the Zendesk widget is not working in the mobile app webview
    if (isInMobileAppWebview()) {
      notifySupportRequested()
      return
    }
    // Toggle Zendesk widget visibility
    if (window.zE) {
      if (isOpen) {
        window.zE('webWidget', 'hide')
      } else {
        window.zE('webWidget', 'show')
        window.zE('webWidget', 'open')
      }
      setIsOpen((state) => !state)
    }
  }

  return { toggleZendeskWidget }
}
