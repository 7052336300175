import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import { Box, styled } from '@mui/material'
import React, { useContext, useState, useEffect, useRef } from 'react'

import { PageContext } from '../context'
import RemoveMultipleFilesDialog from '../dialogs/remove-multiple-files-dialog'
import { displayItemsByFolder } from '../display-items-by-folder'

import { SimpleButton } from '@/components/button/simple-button'
import COLORS from '@/utils/colors'

export default function MultiSelectToolbar (props) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { bulkSelected, setBulkSelected, setIsMoveItemsOpen, setItemsForMoving, filteredVideoExcerpts } = useContext(PageContext)
  const [isFixed, setIsFixed] = useState(false)
  const [leftPosition, setLeftPosition] = useState(0)
  const elementRef = useRef(null)

  function clear () {
    setBulkSelected([])
  }

  function move () {
    setIsMoveItemsOpen(true)
    setItemsForMoving(bulkSelected)
  }

  function remove () {
    setIsDeleteDialogOpen(true)
  }

  function selectAll () {
    const all = displayItemsByFolder(filteredVideoExcerpts, props.fid)
    setBulkSelected(all)
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsFixed(false)
        } else {
          setIsFixed(true)
        }
      },
      { threshold: 0, rootMargin: '-46px 0px 0px 0px' }
    )

    if (props.positionRef.current) {
      observer.observe(props.positionRef.current)
    }

    return () => {
      if (props.positionRef.current) {
        observer.unobserve(props.positionRef.current)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const measurePosition = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect()
        setLeftPosition(rect.left)
      }
    }

    // Measure the position initially
    measurePosition()

    // Measure again when the window is resized
    window.addEventListener('resize', measurePosition)

    return () => {
      window.removeEventListener('resize', measurePosition)
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Container ref={elementRef} className={isFixed ? 'fixed' : ''} style={{ left: `${leftPosition}px` }}>
        <SimpleButton onClick={clear}>
          <CloseIcon />
        </SimpleButton>
        <strong>{bulkSelected.length} Selected</strong>
        <SimpleButton onClick={move}>
          <DriveFileMoveIcon />
        </SimpleButton>
        <SimpleButton onClick={remove}>
          <DeleteIcon />
        </SimpleButton>
        <RemoveMultipleFilesDialog isOpen={isDeleteDialogOpen} setIsOpen={setIsDeleteDialogOpen} />
      </Container>
      <SimpleButton sx={{ marginTop: '7px', marginLeft: '16px', textTransform: 'none' }} onClick={selectAll}>Select All</SimpleButton>
    </Box>
  )
}

const Container = styled('div')({
  display: 'flex',
  gap: '16px',
  backgroundColor: COLORS['neutral-200'],
  marginTop: '7px',
  width: 'fit-content',
  padding: '10px 16px',
  borderRadius: '16px',
  color: COLORS['neutral-700'],
  '& svg': {
    color: COLORS['neutral-700']
  },
  '&.fixed': {
    position: 'fixed',
    top: '7px',
    zIndex: 1200,
    margin: '0 auto',
    width: 'fit-content'
  }
})
