import { styled, useMediaQuery } from '@mui/material'
import React from 'react'

import JeremyPollack from '@/assets/images/marketing/jeremy-pollack.png'
import RyanWills from '@/assets/images/marketing/ryan-wills.png'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { row, column } from '@/utils/flexGrid'

const TESTIMONIALS = [
  {
    name: 'Ryan Wills',
    testimonial: 'We are excited to partner with a pickleball company that is investing in the technology required to be a leader in the analytics and video space at both the amateur and pro level.',
    organizations: ['Coachella Valley Scorpions', 'Team Manager & Assistant Coach'],
    image: RyanWills
  },
  {
    name: 'Jeremy Pollack',
    testimonial: 'PB Vision has been a game-changer in my pickleball development, providing personalized coaching through automated video analysis and actionable insights. It has sharpened my fundamentals, enhanced my strategic understanding, and focused my practice, driving significant improvement in every session.',
    organizations: ['Community Ambassador'],
    image: JeremyPollack
  }
]

export function TestimonialsLandingSection () {
  const supportedForDesktopVersion = useMediaQuery('(min-width:900px)')
  return (
    <Container className={cls(!supportedForDesktopVersion && 'mobile')}>
      {TESTIMONIALS.map((testimonial, index) => (
        <div key={index} className='testimonial'>
          <img src={testimonial.image} alt={testimonial.name} />
          <div className='content'>
            <p className='testimonial'>“{testimonial.testimonial}”</p>
            <strong className='name'>-{testimonial.name}</strong>
            <div>
              {testimonial?.organizations?.length > 0 && testimonial.organizations.map((organization, i) => (
                <p key={i} className='organization'>{organization}</p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '20px',
  marginTop: '300px',
  '& .testimonial': {
    ...row,
    gap: '10px',
    width: '45%',
    '& img': {
      width: '126px',
      minWidth: '126px',
      height: '126px',
      borderRadius: '50%',
      objectFit: 'cover',
      backgroundColor: 'black'
    },
    '& .content': {
      ...column,
      gap: '10px',
      color: COLORS.black,
      '& .testimonial': {
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 300,
        textWrap: 'balance'
      },
      '& .name': {
        fontSize: '14px',
        lineHeight: '16px'
      },
      '& .organization': {
        fontSize: '12px',
        lineHeight: '16px'

      },
      '*': {
        width: '100%'
      }
    }
  },
  '&.mobile': {
    ...column,
    marginTop: '64px',
    padding: '0 16px',
    '& .testimonial': {
      width: '100%'
    }
  }
})
