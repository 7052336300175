import { Button } from '@/components/button'

export function NoShotsFound (props) {
  return (
    <div className='no-shots'>
      <div>
        <div className='lead'>No shots found</div>
        <div className='detail'>Try removing filters or clearing all</div>
        <Button variant='outlined' color='midnight' className='neutral-outline' onClick={() => props.clear()}>
          <em>Clear filters</em>
        </Button>
      </div>
    </div>
  )
}
