import CreditCard from '@mui/icons-material/CreditCard'
import LinkIcon from '@mui/icons-material/Link'
import PersonIcon from '@mui/icons-material/Person'
import Star from '@mui/icons-material/Stars'
import { styled } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { AlertBanner } from '@/components/alerts/banner'
import { Button } from '@/components/button'
import Credits from '@/components/credits'
import { SUBSCRIPTION_PLANS } from '@/hooks/monetization'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { callVideoReprocess, useCreditsAvailable, useIsLoggedIn, useIsSubscriber, useMyMonetizationData } from '@/store/auth'
import { useDialog } from '@/store/providers/dialog-provider'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { useMayEditVideo } from '@/store/video'
import { isProd } from '@/utils'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'
import { copyToClipboard } from '@/utils/helper'

const CreateAccountActions = () => {
  const isMobile = useMobileDetect()

  return (
    <Container className={isMobile && 'mobile'}>
      <Button LinkComponent={Link} to='/create-account' className='green'>
        Create a Free Account <PersonIcon />
      </Button>
      <span>No credit card required!</span>
      <Button LinkComponent={Link} to='/login' className={cls('sing-in-button', 'transparent')}>Sign in</Button>
    </Container>
  )
}
const ContactOwnerActions = () => {
  const { vid } = useParams()
  const openSnackbar = useSnackbar()
  function copyLink () {
    let shareURL = isProd ? 'https://share.pb.vision' : import.meta.env.VITE_API_SERVER
    shareURL += `/video/share/${vid}`
    copyToClipboard(shareURL)
      .then(() => {
        openSnackbar('Link copied to clipboard')
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }
  return (
    <Container>
      <Button onClick={copyLink} className='green'>
        Copy Link <LinkIcon style={{ transform: 'rotateZ(-45deg)' }} />
      </Button>
    </Container>
  )
}

const ReprocessVideoActions = ({ video }) => {
  const dispatch = useDispatch()
  const showDialog = useDialog()
  function onVideoReprocessHandler () {
    dispatch(callVideoReprocess(video.vid)).then().catch(({ data }) => {
      /**
         * The API will return a 400 error code in two cases:
         * 1. The user does not have enough credits. The response body will look like {"message": "insufficient credits"}.
         * 2. The video is already being reprocessed. The response body will look like {"message": "reprocessing already pending"}.
        */
      if (data?.message === 'insufficient credits') {
        showDialog({
          title: 'Not enough credits to proceed.'
        })
      }
      if (data?.message === 'reprocessing already pending') {
        showDialog({
          title: 'Reprocessing is already in progress.'
        })
      }
    })
  }
  return (
    <Container>
      <Button onClick={onVideoReprocessHandler} className='green'>
        Analyze Video Now for <Credits>1</Credits>Credit
      </Button>
    </Container>
  )
}

const SubscriberActions = () => {
  const { currentSubscription } = useMyMonetizationData()
  return (
    <Container>
      <Button LinkComponent={Link} to='/settings/credits' className='green'>
        Buy Credits <CreditCard />
      </Button>
      {currentSubscription === SUBSCRIPTION_PLANS.STARTER &&
        <Button className={cls('gray')} LinkComponent={Link} to='/subscribe'>Upgrade to Pro for 3x more credits <Star style={{ color: COLORS['primary-500'] }} /></Button>}
    </Container>
  )
}

const NonSubscriberActions = () => {
  return (
    <Container>
      <Button LinkComponent={Link} to='/subscribe' className='green'>
        Explore Subscription Plans <PersonIcon />
      </Button>
    </Container>
  )
}

export function useWorkflowAborted (workflow, video) {
  const isMobile = useMobileDetect()
  const isLoggedIn = useIsLoggedIn()
  const md = useMyMonetizationData()
  const numCreditsAvailable = useCreditsAvailable()
  const isSubscriber = useIsSubscriber()
  const isOwner = useMayEditVideo(video.vid)

  function alertWhenWorkflowIsAborted () {
    if (!workflow?.abort) {
      return null
    }
    const reason = workflow.abort.reason
    let title = `This workflow was aborted for an unknown reason: [${reason}]`
    let description = 'Please reach out to support for help.'
    let action

    // Anonymous and non-logged-in users are prompted to log in or create an account
    if (!isLoggedIn) {
      title = 'Create an Account to Analyze Your Video'
      description = 'This video has not been analyzed yet. To analyze it, please log in or create a new account. '
      action = <CreateAccountActions />
    }

    if (reason === 'no-video-credits' || reason === 'anonymous-upload') {
      if (md.isLoading) {
        return null
      }

      /**
       * Three cases:
       * 1) The user has one or more VCs and can use it to process their video (or if
       * user is not a owner, but have subscription they also can to reprocess).
       * 2) The user has no VCs AND is a subscriber. Ask if they want to buy VCs.
       * 3) The user has no VCs AND is not a subscriber. Ask if they subscribe.
       *
       */

      // Case 1
      if ((isOwner && numCreditsAvailable > 0) || (!isOwner && isSubscriber && numCreditsAvailable > 0)) {
        title = 'Analyze Your Video Now'
        description = 'This video has not been analyzed yet. Use one of your Credits to process and analyze this video. '
        action = <ReprocessVideoActions video={video} />
      }

      // Cases 2 & 3
      if (numCreditsAvailable <= 0) {
        // Case 2
        if (isSubscriber) {
          title = 'Buy More Credits to Analyze Your Video'
          description = `This video has not been analyzed yet. As a subscriber, your monthly Credits will refill on ${new Date(md.currentSubscription.end).toLocaleString()}. If you'd like to analyze your video now, you can purchase more Credits.`
          action = <SubscriberActions video={video} />
        }
        // Case 3
        if (!isSubscriber) {
          title = 'Subscribe to Analyze Your Video'
          description = 'This video has not been analyzed yet. You can subscribe to unlock full access or earn credits through our Ambassador Program to continue uploading and analyzing your videos.'
          action = <NonSubscriberActions />
        }
      }
    }

    // Non-owners can reprocess the video only if they are subscribers, otherwise we notify them to contact the owner
    if (!isOwner && !isSubscriber) {
      title = 'Video Not Yet Analyzed'
      description = 'This video has not yet been analyzed. Please ask the owner the visit this page so they can request that this video be analyzed. Alternatively, if you subscribe you can use a Credit to analyze the video.'
      action = <ContactOwnerActions />
    }

    return (
      <AlertBanner title={title} message={description} actions={action} style={{ position: 'absolute', bottom: 0, zIndex: 999, top: isMobile ? 0 : 'unset' }} contentStyle={isMobile ? { maxHeight: '100%', overflowY: 'scroll' } : {}} permanent />
    )
  }

  return { alertWhenWorkflowIsAborted }
}

const Container = styled('div')({
  ...row,
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '16px',
  '& span': {
    fontSize: '14px',
    fontStyle: 'italic',
    color: COLORS['neutral-700']
  },
  '& .sing-in-button': {
    marginLeft: 'auto',
    color: COLORS['neutral-700'],
    border: `1px solid ${COLORS['warning-500']}`
  },
  '& .upgrade-button': {
    border: `1px solid ${COLORS['neutral-300']}`
  },
  '&.mobile': {
    '& span': {
      order: 3
    },
    '& .sing-in-button': {
      marginLeft: 0
    }
  }
})
