/**
 * Creates an object composed of the object properties not present in omit
 *
 * @param {object} obj The source object
 * @param {Array} omit The property paths to omit
 * @returns {object} object with filtered properties
 */
function omit (obj, omit) {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => !omit.includes(key)))
}

export default omit
