import Page from './index'

import { isProd } from '@/utils'

export default [
  !isProd &&
  {
    path: '/testing',
    element: <Page />
  }
]
