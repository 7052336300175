import styled from '@emotion/styled'
import { Switch as MuiSwitch } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip'

import COLORS from '@/utils/colors'

const Switch = styled(MuiSwitch)({
  '&.MuiSwitch-root': {
    width: '46px',
    height: '24px',
    padding: '0',
    alignItems: 'center',

    borderRadius: '20px'
  },
  '& .MuiButtonBase-root': {
    padding: '0',
    height: '100%',
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: '16px',
      height: '16px',
      margin: '4px',
      color: COLORS['neutral-400']
    },
    '&.Mui-checked': {
      '&+.MuiSwitch-track': {
        backgroundColor: COLORS['primary-500'],
        border: 'none',
        opacity: 1
      },
      '& .MuiSwitch-thumb': {
        color: COLORS.white
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: '16px',
    opacity: '1',
    border: `1px solid ${COLORS['neutral-400']}`,
    backgroundColor: COLORS['neutral-050'],
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)'
    }
  }
})

const Container = styled(FormControlLabel)({
  marginRight: '0',
  '& .MuiFormControlLabel-label': {
    marginLeft: '10px',
    color: COLORS['neutral-800'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px'
  }
})

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0]
            }
          }
        ]
      }
    }}
    classes={{ popper: className }}
    {...props}
  >
    {props.children}
  </MuiTooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS['background-tooltip'],
    fontSize: '14px'
  }
})

/**
 *
 * @param props {object} { checked, onChange, label, tooltip }
 * @returns {JSX.Element}
 * @constructor
 */
export function Toggle (props) {
  const { checked, onChange, label, tooltip } = props
  return (
    <Tooltip title={tooltip}>
      <Container
        control={<Switch checked={checked} onChange={() => onChange(!checked)} />}
        label={label}
      />
    </Tooltip>
  )
}
