/**
 * In banners.js, we should define all the banners that
 * display in our app. This way, we have a centralized
 * place for them, making it easier to avoid repetition
 * and simplify usage and editing.
 */

export const MONETIZATION_BANNER_KEYS = {
  UNSUBSCRIBED_OUT_OF_CREDIT: 'unsubscribed_out_of_credit',
  SUBSCRIBED_OUT_OF_CREDIT: 'subscribed_out_of_credit',
  ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT: 'anonymous_prompt_to_create_account',
  UPGRADE_BEFORE_FREE_TRIAL: 'upgrade_before_free_trial',
  FREE_TRIAL_EXPIRED: 'free_trial_expired',
  TRY_FOR_FREE: 'try_for_free',
  TRIAL_HAVENT_START: 'trial_havent_start'
}

const monetizationAlertsList = {
  [MONETIZATION_BANNER_KEYS.SUBSCRIBED_OUT_OF_CREDIT]: {
    id: MONETIZATION_BANNER_KEYS.SUBSCRIBED_OUT_OF_CREDIT,
    type: 'info',
    dismissibleOnMobile: true,
    title: 'You\'re Out of Credits',
    isAnnoying: true
  },
  [MONETIZATION_BANNER_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT]: {
    id: MONETIZATION_BANNER_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT,
    type: 'info',
    dismissibleOnMobile: true,
    title: 'Subscribe to Upload and Analyze More Videos',
    message: 'To unlock video uploads and access advanced analysis tools, please subscribe to PB Vision. Take your game analysis to the next level.',
    isAnnoying: true
  },
  [MONETIZATION_BANNER_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT]: {
    id: MONETIZATION_BANNER_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT,
    type: 'info',
    dismissibleOnMobile: true,
    title: 'Create an Account to Upload More Videos',
    message: 'As an anonymous user, you\'re limited to one video upload. To continue uploading and analyzing your videos, please log in or create an account.',
    isAnnoying: true
  },
  [MONETIZATION_BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL]: {
    id: MONETIZATION_BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL,
    type: 'info',
    dismissibleOnMobile: true,
    title: 'Trial mode',
    isAnnoying: true,
    oneLine: true
  },
  [MONETIZATION_BANNER_KEYS.FREE_TRIAL_EXPIRED]: {
    id: MONETIZATION_BANNER_KEYS.FREE_TRIAL_EXPIRED,
    type: 'warning',
    dismissibleOnMobile: true,
    title: 'Trial mode',
    message: 'Your free trial has expired',
    isAnnoying: true,
    oneLine: true
  },
  [MONETIZATION_BANNER_KEYS.TRY_FOR_FREE]: {
    id: MONETIZATION_BANNER_KEYS.TRY_FOR_FREE,
    dismissibleOnMobile: true,
    title: 'Try PB Vision for free now!',
    message: '',
    isAnnoying: true,
    oneLine: true
  },
  [MONETIZATION_BANNER_KEYS.TRIAL_HAVENT_START]: {
    id: MONETIZATION_BANNER_KEYS.TRIAL_HAVENT_START,
    dismissibleOnMobile: true,
    title: 'Trial mode',
    message: 'You can analyze three games for free. Your 30 day trial will begin when you upload your first video.',
    isAnnoying: true,
    oneLine: true
  }
}

const VIDEO_BANNER_KEYS = {
  NOT_YOUR_VIDEO: 'not_your_video',
  UNSUPPORTED_VIDEO_FORMAT: 'unsupported_video_format',
  LOGIN_TO_FINISH_ACTION: 'login_to_finish_action'
}

const videoAlertsList = {
  [VIDEO_BANNER_KEYS.NOT_YOUR_VIDEO]: {
    id: VIDEO_BANNER_KEYS.NOT_YOUR_VIDEO,
    type: 'warning',
    dismissibleOnMobile: true,
    title: 'Not Your Video',
    message: 'Only the person who uploaded this video may edit it.',
    isAnnoying: true
  },
  [VIDEO_BANNER_KEYS.LOGIN_TO_FINISH_ACTION]: {
    id: VIDEO_BANNER_KEYS.LOGIN_TO_FINISH_ACTION,
    type: 'warning',
    dismissibleOnMobile: true,
    title: 'Please login to finish this action',
    message: 'Access to this feature requires you to be logged in. Please login or create an account to proceed with this action.',
    isAnnoying: true
  },
  [VIDEO_BANNER_KEYS.UNSUPPORTED_VIDEO_FORMAT]: {
    id: VIDEO_BANNER_KEYS.UNSUPPORTED_VIDEO_FORMAT,
    type: 'warning',
    dismissibleOnMobile: true,
    title: 'Unsupported Video',
    message: 'This browser does not support the requested video format. Please try a different browser. For additional help, please click the help icon in the top right corner of this page.',
    isAnnoying: true
  }
}

const SYSTEM_BANNER_KEYS = {
  DEV_ENV: 'dev_env'
}

const systemAlertsList = {
  [SYSTEM_BANNER_KEYS.DEV_ENV]: {
    id: SYSTEM_BANNER_KEYS.DEV_ENV,
    permanent: true,
    dismissibleOnMobile: true,
    title: 'You are on the Test Server',
    message:
      'This environment is unstable and will often change. Data is periodically deleted.'
  }
}

export const BANNER_KEYS = {
  ...MONETIZATION_BANNER_KEYS,
  ...VIDEO_BANNER_KEYS,
  ...SYSTEM_BANNER_KEYS
}

export const bannerList = {
  ...monetizationAlertsList,
  ...videoAlertsList,
  ...systemAlertsList
}

/** Free trial related banners. */
export const FREE_TRIAL_BANNER_IDS = [
  BANNER_KEYS.FREE_TRIAL_EXPIRED,
  BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL
]
