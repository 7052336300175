/* Avoid throwing errors when @react-three/fiber ThreeElement properties aren't found */
/* eslint react/no-unknown-property: "off" */
import { useFrame } from '@react-three/fiber'
import { sine } from 'maath/easing'
import { clamp } from 'maath/misc'
import React, { useRef } from 'react'

export function FadeAnimation ({ getFrameIndex, map, duration, ...props }) {
  const ref = useRef()

  useFrame(() => {
    const f = getFrameIndex()
    if (ref.current) {
      ref.current.material.opacity = sine.in(clamp(1 - f / duration, 0, 1))
    }
  })

  return (
    <group {...props}>
      <sprite ref={ref}>
        <spriteMaterial
          map={map}
          color='#EF39FF'
          depthTest={false}
          depthWrite={false}
        />
      </sprite>
    </group>
  )
}
