import { Box } from '@mui/material'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { useEffect, createContext, useMemo } from 'react'
import safeJsonStringify from 'safe-json-stringify'

import { getLoggedInUserToken, isAnonymousUserId, selectIsAuthInitialized, selectUserId, useLoggedInUserCredentials } from '../store/auth'

import { assert } from '.'

import { VideoDoesNotExist } from '@/components/error/VideoDoesNotExist'
import { CircularLoadingIndicator } from '@/components/loading-indicator/CircularLoadingIndicator'

/**
 * @typedef {object} APIDefinition
 * @property {string} path the path to the API on our servers (excluding the hostname and initial slash)
 * @property {boolean} [isUserAPI=true] true if this is an admin API, otherwise it's just a regular API (no auth required)
 * @property {function} options to pass to rtk query builder; must include providesTags for queries and invalidatesTags for mutations
 */
/**
 * Creates an RTK Query redux slice.
 * @param {object} config
 * @param {string} config.name the name of the slice in redux (will be suffixed with APIs)
 * @param {APIDefinition} config.apis information about the APIs to build
 * @param {Array<String>} config.tagTypes the tag types produced by these APIs
 * @param {object} config.options other options to pass to rtk query createApi()
 * @returns {ReduxSlice}
 */
export function buildAPIsSlice ({ name, apis, tagTypes, ...options }) {
  assert(tagTypes.length >= 1)
  const endpoints = builder => {
    const endpoints = {}
    for (const [k, api] of Object.entries(apis)) {
      const { path, ...optionsForThisAPI } = api
      const f = isMutationAPI(api) ? buildAPIMutation : buildAPIQuery
      endpoints[k] = f(builder, path, optionsForThisAPI)
    }
    return endpoints
  }
  const slice = buildAPIs(name, endpoints, { tagTypes, ...options })
  for (const [k, api] of Object.entries(apis)) {
    const funcNamePieces = ['use']
    const isMutation = isMutationAPI(api)
    if (!isMutation) {
      funcNamePieces.push('Lazy')
    }
    funcNamePieces.push(`${k[0].toUpperCase()}${k.substring(1)}`)
    funcNamePieces.push(isMutation ? 'Mutation' : 'Query')
    slice[funcNamePieces.join('')].isUserAPI = api.isUserAPI ?? true
  }
  return slice
}

function isMutationAPI (api) {
  return Boolean(api.invalidatesTags || api.onQueryStarted)
}

function buildAPIs (name, endpoints, options = {}) {
  const { baseUrl, ...createApiOptions } = options
  return createApi({
    reducerPath: `${name}APIs`,
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl ?? (import.meta.env.VITE_API_SERVER + '/')
    }),
    ...createApiOptions,
    endpoints
  })
}

function buildAPIQuery (builder, path, options = {}) {
  assert(options.providesTags, `${path} query must provide the providesTags option`)
  const { method } = options
  delete options.method
  return builder.query({
    ...options,
    query: (data) => {
      const { headers, __pathParams, ...body } = data
      return {
        url: typeof path === 'function' ? path(__pathParams) : path,
        method: method ?? 'POST',
        body: method === 'GET' ? undefined : body,
        headers
      }
    }
  })
}

function buildAPIMutation (builder, path, options = {}) {
  assert(isMutationAPI(options), `${path} mutation must provide the invalidatesTags or onQueryStarted option`)
  return builder.mutation({
    ...options,
    query: (data) => {
      const { headers, ...body } = data
      return {
        url: path,
        method: 'POST',
        body,
        headers
      }
    }
  })
}

export function useLazyQueryWrapper (useLazyQuery, inputs, { preferCacheValue = true } = {}) {
  const { isUserAPI } = useLazyQuery
  assert(isUserAPI === Boolean(isUserAPI), 'isUserAPI should be set')
  const { uid, token, getTokenError } = useLoggedInUserCredentials()

  const lazyQuery = useLazyQuery()

  const [trigger, results, lastPromiseInfo] = lazyQuery
  const wrappedTrigger = useMemo(() => {
    if (isUserAPI && (!token || getTokenError || !uid)) {
      return () => {}
    } else {
      const headers = {}
      if (isUserAPI) {
        headers['x-uid'] = uid
        headers['x-token'] = token
      }
      const retFunc = () => {
        if (inputs !== undefined) {
          assert(!inputs?.headers, 'cannot have inputs field named "headers"')
          // note: prefer cache value only applies to queries not mutations
          trigger({ headers, ...(inputs ?? {}) }, preferCacheValue)
        }
      }
      return retFunc
    }
  }, [getTokenError, inputs, isUserAPI, preferCacheValue, token, uid, trigger])

  const customResults = useMemo(() => {
    if (isUserAPI && (!token || getTokenError)) {
      // result matches the type UseQueryStateResult (plus isFetchingToken)
      return {
        ...results,
        error: getTokenError,
        isLoading: !getTokenError && results.isLoading,
        isFetching: !getTokenError,
        isSuccess: false,
        isError: Boolean(getTokenError),
        isFetchingToken: !token && !getTokenError
      }
    } else {
      return results
    }
  }, [getTokenError, isUserAPI, results, token])

  return useMemo(
    () => [wrappedTrigger, customResults, lastPromiseInfo],
    [wrappedTrigger, customResults, lastPromiseInfo])
}

export function useAPI (useSomeAPI, argsToUse, needsWrap = false) {
  // needsWrap must NEVER change for a given call
  // eslint-disable-next-line
  const f = needsWrap ? args => useLazyQueryWrapper(useSomeAPI, args) : useSomeAPI
  const [trigger, result] = f(argsToUse)
  useEffect(trigger, [trigger])
  const isLoading = result?.isLoading || result?.isFetching
  const isLoaded = !isLoading && result?.isSuccess
  const { data, error } = result
  const isReady = !error && isLoaded
  return { data, error, isLoaded, isLoading, isReady }
}

export function usePublicLibraryAPI (useSomeAPI, argsToUse, needsWrap = false) {
  // eslint-disable-next-line
  const f = needsWrap ? args => useLazyQueryWrapper(useSomeAPI, args) : useSomeAPI
  const [trigger, result] = f(argsToUse)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(trigger, [])

  const isLoading = result?.isLoading || result?.isFetching
  const isLoaded = !isLoading && result?.isSuccess
  const { data, error } = result
  const isReady = !error && isLoaded
  return { data, error, isLoaded, isLoading, isReady }
}

/**
 * @typedef {object} {APIToCall}
 * @property {function} api the API hook to call (should be a wrapper of useLazyQueryWrapper)
 * @property {*} args the args to pass to the API function (often an object, or a scalar value)
 * @property {string} [name] name to label the output data with (if omitted uses api.name instead)
 * @property {function} [handleResult] APIs result is passed to this function; if it returns something, that will be the JSX output instead of the default
 * @property {boolean} [waitForLoad=true] whether to return the children JSX before data is loaded or (the default) not
 * @property {function} [addContext] optional function which takes in the context and returns a modified copy (e.g., transform it)
 */
/**
 * @param {object} props
 * @param {Array<APIToCall>} props.apis
 */
export function APIWrapper ({ children, apis, addContext = x => x }) {
  const results = []
  for (const { api, args, needsUseAPI = true } of apis) {
    // eslint-disable-next-line
    results.push(needsUseAPI ? useAPI(api, args) : api(args))
  }

  const context = { isLoading: false, error: undefined }
  for (let i = 0; i < results.length; i++) {
    const { isLoading, error } = results[i]
    context.isLoading = context.isLoading || isLoading
    context.error = context.error || error
  }
  for (let i = 0; i < results.length; i++) {
    const { handleResult, waitForLoad } = apis[i]
    const name = apis[i].name ?? apis[i].api.name
    const result = results[i]
    context[name] = result.data
    const earlyResult = handleResult?.(result, context)
    if (earlyResult) {
      // show the return value from handle result instead
      children = earlyResult
      break
    }

    const waitForThisAPI = (typeof waitForLoad === 'boolean') ? waitForLoad : waitForLoad[i]
    if (result.isLoading && waitForThisAPI) {
      return <CircularLoadingIndicator label='Loading...' fullscreen />
    }
    if (result.error) {
      return (
        <VideoDoesNotExist />
      )
    }
    if (!result.isLoaded && waitForThisAPI) {
      return <CircularLoadingIndicator label='Pending...' fullscreen />
    }
  }
  return (
    <APIContext.Provider value={addContext(context)}>
      {children}
    </APIContext.Provider>
  )
}

export function MutationAPIWrapper ({ children, api, args, shouldTrigger }) {
  function handleResult (result) {
    if (!shouldTrigger && !result.isLoaded) {
      return children
    }
    if (result.isLoading) {
      return <CircularLoadingIndicator label='Saving...' fullscreen />
    }
    if (result.error) {
      return (
        <Box>
          Failed to save: <pre>{safeJsonStringify(result.error, null, 4)}</pre>
        </Box>
      )
    }
  }

  return (
    <APIWrapper
      apis={[{
        api,
        args: shouldTrigger ? args : undefined,
        handleResult,
        waitForLoad: true
      }]}
    >
      {children}
    </APIWrapper>
  )
}

export const APIContext = createContext()

export async function callAPIFromThunk (dispatch, getState, api, inputs, { forceRefetch = false, isUserAPI = true } = {}) {
  assert(inputs !== undefined, 'missing inputs for api call', api.name) // null is okay; that means "no inputs"

  const headers = {}
  if (isUserAPI) {
    const initialState = getState()
    const isAuthReady = selectIsAuthInitialized(initialState)
    const userId = selectUserId(initialState)
    const isAnonymous = isAnonymousUserId(userId)
    const uid = isAnonymous ? null : userId
    assert(isAuthReady, 'auth should be ready before calling user api', api.name)
    assert(uid, 'user should be signed in before calling user api', api.name)
    const token = await getLoggedInUserToken()
    headers['x-uid'] = uid
    headers['x-token'] = token
  }

  const args = { headers, ...(inputs ?? {}) }
  const promise = dispatch(api.initiate(args, { forceRefetch })).unwrap()
  const result = await promise
  if (promise.unsubscribe) {
    promise.unsubscribe()
  }
  return result
}
