import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setVideoField } from '@/store/video'

export function useRenamePlayer () {
  const dispatch = useDispatch()
  return useCallback((video, playerIndex, newName) => {
    const { userData } = video
    // players might have fewer than playerIndex+1 players (they aren't set
    // until they're needed)
    const playersCopy = userData.players.map(x => ({ ...x }))
    if (newName && playersCopy[playerIndex]?.name !== newName) {
      while (playersCopy.length <= playerIndex) {
        playersCopy.push({})
      }
      playersCopy[playerIndex].name = newName
      dispatch(setVideoField(video.vid, 'players', playersCopy))
    }
  }, [dispatch])
}
