/**
 * @file ShareFolderDialog.js
 * @description A dialog component for sharing a folder.
*/

import CloseIcon from '@mui/icons-material/Close'
import LinkIcon from '@mui/icons-material/Link'
import { Checkbox, FormControlLabel, styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { forwardRef, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { PageContext } from '../context'

import PublicFolderIcon from '@/assets/icons/public-folder-icon.svg?react'
import ShareFolderIcon from '@/assets/icons/share-icon.svg?react'
import { Button } from '@/components/button'
import { CircularLoadingIndicator } from '@/components/loading-indicator/CircularLoadingIndicator'
import { Toggle } from '@/components/toggle'
import useKeypress from '@/hooks/use-keypress'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useLoggedInUserCredentials } from '@/store/auth'
import { updateFolder } from '@/store/library'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { isProd } from '@/utils'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { copyToClipboard } from '@/utils/helper'
import { isInMobileAppWebview, notifyShareRequested } from '@/utils/mobile-app-communication'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

/**
 * ShareFolderDialog component for sharing a folder.
 * @function
 * @param {object} props
 * @param {boolean} props.folder - Represent folder for changing public status.
 * @param {function} props.setIsOpen - Function to set the dialog open state.
 * @returns {JSX.Element} The ShareFolderDialog component.
 */

export default function ShareFolderDialog (props) {
  const isMobile = useMobileDetect()
  const openSnackbar = useSnackbar()
  const dispatch = useDispatch()
  const { uid } = useLoggedInUserCredentials()
  const { isRootFolderPublic } = useContext(PageContext)

  const [isCurrentFolderPublic, setIsCurrentFolderPublic] = useState(false)
  const [doubleCheck, setIsDoubleChecked] = useState(true)
  const [isDifferent, setIsDifferent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isRootPublic = props.isRootPublic
  const rootFolder = props?.folder?.root
  const libraryOrFolderLabel = rootFolder ? 'Entire Library' : 'Folder'
  const isPublic = isRootPublic || isCurrentFolderPublic

  const handleClose = () => {
    props.setIsOpen(false)
    setIsDoubleChecked(false)
  }

  const handleCopyLink = async () => {
    let shareURL = ''
    shareURL = isProd ? 'https://pb.vision' : 'https://pbv-dev.web.app'
    shareURL += `/library/public/${uid}`
    if (props.folder.fid && isCurrentFolderPublic) {
      shareURL += `/${props.folder.fid}`
    }

    if (isInMobileAppWebview()) {
      notifyShareRequested(shareURL)
    } else {
      copyToClipboard(shareURL)
        .then(() => {
          openSnackbar('Link copied to clipboard')
        })
        .catch(() => {
          openSnackbar('Failed to copy то clipboard', 'error')
        })
    }
  }

  const handleAccept = async (status) => {
    let objForUpdate = {}

    // We update the Entire Library when we are inside it or
    // when it is public.
    // Otherwise we will update the current folder
    if (rootFolder || isRootFolderPublic) {
      objForUpdate = {
        public: status
      }
    } else {
      objForUpdate = {
        public: status,
        fid: props.folder.fid
      }
    }

    try {
      setIsLoading(true)
      await dispatch(updateFolder(objForUpdate))

      // If we're in a folder and the root folder gets
      // unpublished, current folder status should be updated with
      // a it's default value.
      if (isRootFolderPublic && !rootFolder) {
        setIsCurrentFolderPublic(props.folder.public)
      } else {
        setIsCurrentFolderPublic(status)
      }
    } catch (error) {
      openSnackbar('An error occurred while updating the folder. Please try again.', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const updateFolderStatus = async (status) => {
    await handleAccept(status)
    setIsDoubleChecked(false)
    if (status) {
      openSnackbar(`${libraryOrFolderLabel} successfully updated.`)
    } else {
      openSnackbar(`${libraryOrFolderLabel} successfully updated.`)
    }
  }

  const onChangePublicStatus = async (status) => {
    if (!rootFolder) {
      updateFolderStatus(status)
    } else {
      isRootFolderPublic ? updateFolderStatus(status) : setIsCurrentFolderPublic(status)
    }
  }

  useKeypress('Enter', () => {
    rootFolder && isDifferent && handleAccept(isCurrentFolderPublic)
  })

  useEffect(() => {
    if (props?.folder?.root) {
      !rootFolder.public && setIsDoubleChecked(false)
      setIsCurrentFolderPublic(isRootFolderPublic)
      setIsDifferent(isRootFolderPublic !== isCurrentFolderPublic)
    } else {
      setIsCurrentFolderPublic(props.folder?.public || isRootFolderPublic)
      setIsDifferent(props.folder.public !== isCurrentFolderPublic)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.folder])

  useEffect(() => {
    if (rootFolder) {
      setIsDifferent(isRootFolderPublic !== isCurrentFolderPublic)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentFolderPublic, isRootFolderPublic])

  // A double check is only needed when we want to make the folder public
  const isDoubleChecked = !isCurrentFolderPublic || doubleCheck
  const isOpened = rootFolder ? (!isRootFolderPublic && isCurrentFolderPublic) : isCurrentFolderPublic
  const isCopyLinkVisible = rootFolder ? isRootFolderPublic : isPublic

  return (
    <>
      <StyledDialog
        open={Boolean(props?.folder)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <div className={cls('content', isMobile && 'mobile', (rootFolder) && 'root-folder-size', isOpened && 'opened')}>
          <div className='head'>
            <p>{`Share "${props.folder.name || 'Entire Library'}"`}</p>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className='toggle-container'>
            {isPublic ? <PublicFolderIcon /> : <ShareFolderIcon />}
            <div className='description'>
              <strong className='title'>{isRootFolderPublic ? 'Your entire Library' : libraryOrFolderLabel} {isPublic ? ' is public' : ' is private '}</strong>
              <strong className='subtitle'>{isPublic ? 'Can be viewed by anyone with the link' : 'Can only be viewed by owner'} </strong>
            </div>
            {isLoading
              ? <CircularLoadingIndicator className='loading-indicator' label='Saving...' size={16} />
              : <Toggle tooltip={isCurrentFolderPublic ? 'Unshare' : 'Share'} onChange={(checked) => onChangePublicStatus(checked)} checked={isCurrentFolderPublic} />}
          </div>

          {isCurrentFolderPublic && rootFolder && isDifferent && !isRootFolderPublic &&
            <FormControlLabel
              classes={{ root: 'formControl', label: 'label' }}
              control={
                <Checkbox
                  className='checkbox'
                  color='primary'
                  checked={doubleCheck}
                  onChange={(evt) => {
                    setIsDoubleChecked(evt.target.checked)
                  }}
                />
          }
              label='I understand that all videos and folders will be public'
            />}

          <div className='bottom-part'>
            <div className='actions'>
              {isCopyLinkVisible &&
                <Button className='green copy-link' onClick={handleCopyLink}>Copy link<LinkIcon /></Button>}
              {rootFolder && !isRootFolderPublic &&
                <Button className={(isDifferent && isDoubleChecked) ? 'green' : 'gray-filled'} disabled={!isDifferent || isLoading || !isDoubleChecked} onClick={() => updateFolderStatus(isCurrentFolderPublic)}>Save</Button>}
            </div>
          </div>
        </div>
      </StyledDialog>
    </>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    margin: 0,
    maxWidth: 'unset'
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '16px 24px',
    width: '600px',
    height: '133px',
    overflow: 'hidden',
    transition: 'height 0.5s ease',
    '&.root-folder-size': {
      height: '160px',
      '&.mobile': {
        height: '200px'
      }
    },
    // .content div animation start
    '&.root-folder-size.opened': {
      height: '210px',
      '&.mobile': {
        height: '258px'
      }
    },
    '&.opened': {
      height: '160px',
      '&.mobile': {
        height: '200px'
      }
    },
    // .content div animation end

    '& .formControl': {
      alignSelf: 'flex-end',
      flexDirection: 'row-reverse',
      marginRight: '-10px !important'
    },
    '& .toggle-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      border: `1px solid ${COLORS['neutral-300']}`,
      padding: '8px 16px',

      '& .loading-indicator': {
        marginLeft: 'auto',
        gap: '0px'
      },

      '& .description': {
        display: 'flex',
        flexDirection: 'column',
        '& .subtitle': {
          fontSize: '12px'
        }
      },
      '& label': {
        marginLeft: 'auto'
      }
    },
    '& .head': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& svg': {
        cursor: 'pointer'
      },
      '& p': {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: COLORS['neutral-800']
      }
    },
    '& input': {
      padding: '8px'
    },
    '& .bottom-part': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .copy-link svg': {
        transform: 'rotateZ(315deg)'
      },
      '& .actions': {
        display: 'flex',
        marginLeft: 'auto',
        gap: '8px',
        '& button': {
          maxHeight: '32px'
        }
      }
    },
    '&.mobile': {
      width: '90vw'
    }
  }

}))
