import { styled } from '@mui/material'
import React from 'react'

import HelpIcon from '@/assets/help.svg?react'
import { Tooltip } from '@/components/tooltip'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'

export default function StatsSectionTitle (props) {
  return (
    <Container className='stats-section-title'>
      {props.tooltip && (
        <Tooltip
          title={props.tooltip}
        >
          <HelpIcon />
        </Tooltip>
      )}
      <strong>
        {props.title}
      </strong>
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  alignItems: 'center',
  gap: '10px',
  '& strong': {
    color: COLORS.black,
    marginBottom: '5px'
  }
})
