import { styled } from '@mui/material/styles'

import ArrowDropUpIcon from '@/assets/triangle-to-up.svg?react'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'

/**
 * getNumberWithOrdinal - calculate number ordinal
 *
 * @param {number} n
 * @returns {string}
 */
function getNumberWithOrdinal (n) {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

/**
 * Container component
 *
 * @exports
 * @param props {object} {children, value, color}
 * @returns {React.ReactElement}
 */
export const Container = styled('div', {
  shouldForwardProp: (prop) => ['children', 'value', 'color'].includes(prop)
})(({ value, color }) => ({
  width: 100,
  alignSelf: 'center',

  '& .progress': {
    ...row,
    height: 11,
    position: 'relative',
    background: COLORS['neutral-300']
  },
  '& .fill': {
    width: `${value}%`,
    background: color || (value < 33 ? 'red' : value >= 67 ? COLORS['primary-500'] : COLORS.player3)
  },
  '& .arrow': {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 9,
    left: `${value}%`,
    lineHeight: 1,
    fontSize: 12,
    transform: 'translateX(-50%)',

    '& svg': {
      alignSelf: 'center',
      marginBottom: 2
    }
  }
}))

/**
 * Percentile component, horizontal percentage bar with arrow and value beneath it
 *
 * @exports
 * @param props {object} {value, color, sx, suffix} - When suffix is set - show it instead of number ordinal
 * @returns {React.ReactElement}
 */
export function Percentile (props) {
  const { value, color, sx, suffix, invertFill } = props

  return (
    <Container value={invertFill ? 100 - value : value} color={color} sx={sx} className='percentile'>
      <div className='progress'>
        <div className='fill' />
        <div className='arrow'>
          <ArrowDropUpIcon />
          {suffix ? `${value}\u00A0${suffix}` : getNumberWithOrdinal(value)}
        </div>
      </div>
    </Container>
  )
}
