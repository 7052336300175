import styled from '@emotion/styled'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from '../button/index'
import { TextButton } from '../button/text-button'
import { Divider } from '../divider'

import BugIcon from '@/assets/bug-icon.svg?react'
import emojiGrimacing from '@/assets/emoji-grimacing.png'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export function VideoDoesNotExist (props) {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const { toggleZendeskWidget } = useZendesk()

  const location = useLocation()
  const videoId = location.pathname.split('/').pop()

  function onBackToLibraryHandler () {
    navigate('/library')
  }

  function onContactUsHandler () {
    toggleZendeskWidget()
  }

  return (
    <Container className={isMobile && 'mobile'}>
      <img src={emojiGrimacing} alt='grimacing emoji' />
      <strong>The video you are looking for (<code>{videoId}</code>) does not exist</strong>
      <Divider />
      <div className='buttons'>
        <Button className='gray' onClick={() => onBackToLibraryHandler()}>
          Back to Library
        </Button>
        <Button className='gray' onClick={() => onContactUsHandler()}>
          Contact Us <BugIcon />
        </Button>
      </div>
      <div className='support-message'>
        <p>Having trouble? </p>
        <TextButton className='underline' onClick={() => onContactUsHandler()}>Message Support</TextButton>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  alignSelf: 'center',
  marginBlock: 'auto',
  padding: 32,
  alignItems: 'center',
  borderRadius: '12px',
  border: `1px solid ${COLORS['warning-500']}`,
  background: COLORS['warning-050'],
  color: COLORS['neutral-700'],
  ...column,
  '& img': {
    height: 72
  },
  '& strong': {
    fontSize: 24,
    marginBlock: 16,
    textAlign: 'center',
    '& code': {
      fontWeight: 'normal'
    }
  },
  '& p': {
    fontSize: 16
  },
  '& .buttons': {
    gap: 16,
    marginBlock: 32,
    ...column,
    '& .back': {
      color: COLORS.white,
      backgroundColor: COLORS['success-700']
    }
  },
  '& .support-message': {
    marginBlock: 16,
    fontSize: 14,
    gap: 4,
    color: COLORS.black,
    ...row,
    '& p': {
      fontSize: 14
    }
  },
  '&.mobile': {
    paddingInline: '8px !important',
    '& strong': { fontSize: 18 },
    '& img': {
      height: 62
    }
  }
})
