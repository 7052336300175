import { useDispatch } from 'react-redux'

import { moveVideosToNewFolder, updateFolder } from '@/store/library'

export const useMoveToFolder = () => {
  const dispatch = useDispatch()

  function moveItemsToFolder (items, desiredFid) {
    const videosToMove = []
    const foldersToMove = []

    items.forEach(item => {
      if (item?.vid) {
        videosToMove.push({ vid: item.vid, fid: desiredFid })
      } else {
        foldersToMove.push({ fid: item.fid, parent: desiredFid })
      }
    })

    if (videosToMove.length) {
      dispatch(moveVideosToNewFolder(videosToMove))
    }
    if (foldersToMove.length) {
      foldersToMove.forEach(folderToMove => {
        dispatch(updateFolder(folderToMove))
      })
    }
  }

  return { moveItemsToFolder }
}
