import { createSlice, nanoid } from '@reduxjs/toolkit'

import { createLocalStorageState } from './local-storage-state'

import { bannerList } from '@/utils/banners'
import { popupList } from '@/utils/popups'

/**
 * This slice is designed to store information about global application controls
 * such as banners, in-app notifications, states, dialogs, snackbars, and more.
 *
 * The idea is to centralize these functions and migrate from context to the Redux store,
 * allowing for easier access and usage across the app.
 */

const defaultState = {
  savable: {
    vidsFromNative: []
  },
  ephemeral: {
    banners: {
      alerts: [],
      dismissed: []
    },
    popups: {
      stack: []
    }
  }
}

export const sliceName = 'controls'
const { preloadedState /* saveState */ } = createLocalStorageState({
  sliceName,
  defaultState
})

const controlsSlice = createSlice({
  name: sliceName,
  initialState: preloadedState,
  reducers: {
    /**
     * Banners reducers
     */
    showBanner: {
      reducer: (state, action) => {
        const payload = action.payload

        // Prevent displaying the same message multiple times (fallback to title comparison if message is not string (react component))
        const isAlertExists = state.banners.alerts.some((alert) => typeof alert.message === 'string' ? alert.message === payload.message : alert.title === payload.title)
        // Prevent displaying the same message after it was dismissed in the same session
        const isDismissed = state.banners.dismissed.some((dismissedId) => dismissedId === payload.id)

        if (isAlertExists || isDismissed) {
          return
        }
        state.banners.alerts.push(payload)
      },
      prepare: (bannerKey) => {
        const { id = nanoid(), title, message = '', type = 'info', autoDismiss = false, permanent = false, dismissibleOnMobile = false, isAnnoying = false, oneLine = false } = bannerList[bannerKey]

        return {
          payload: { id, title, message, type, autoDismiss, permanent, dismissibleOnMobile, isAnnoying, oneLine }
        }
      }
    },
    hideBanner: (state, action) => {
      // banner will be disabled in current app iteration
      const index = state.banners.alerts.findIndex(alert => alert.id === action.payload)
      if (index !== -1) {
        state.banners.alerts[index].isDismissed = true
      }
    },
    removeBanner: (state, action) => {
      state.banners.alerts = state.banners.alerts.filter(alert => alert.id !== action.payload)
    },

    /**
     * Popup reducers
     */
    showPopup: {
      reducer: (state, action) => {
        const payload = action.payload

        state.popups.stack.push(payload)
      },
      prepare: (popupKey) => {
        const { id = nanoid(), title = '', message = '', type = 'info' } = popupList[popupKey]

        return {
          payload: { id, title, message, type }
        }
      }
    },
    removePopup: (state, action) => {
      state.popups.stack = state.popups.stack.filter(popup => popup.id !== action.payload)
    }

  },
  extraReducers: (builder) => {}
})

export const { reducer } = controlsSlice
export const { showBanner, hideBanner, removeBanner, showPopup, removePopup } = controlsSlice.actions
