/**
 * @file RemoveFolderDialog.js
 * @description A dialog component for removing a new folder.
*/

import CloseIcon from '@mui/icons-material/Close'
import { Checkbox, FormControlLabel, styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { forwardRef, useContext, useState } from 'react'

import { PageContext } from '../context'
import { useMultipleDelete } from '../hooks/use-multiple-delete'

import { Button } from '@/components/button'
import { Spinner } from '@/components/spinner'
import useKeypress from '@/hooks/use-keypress'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import COLORS from '@/utils/colors'
import { pluralize } from '@/utils/helper'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

/**
 * RemoveMultipleFilesDialog component for removing a multiple files.
 * @function
 * @param {object} props
 * @param {boolean} props.isOpen - Indicates if the dialog is open.
 * @param {function} props.setIsOpen - Function to set the dialog open state.
 * @returns {JSX.Element} The RemoveFolderDialog component.
 */

export default function RemoveMultipleFilesDialog (props) {
  const { bulkSelected, setBulkSelected } = useContext(PageContext)
  const { deleteMultipleItems } = useMultipleDelete()
  const isMobile = useMobileDetect()
  const openSnackbar = useSnackbar()

  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const haveFolderForDelete = bulkSelected.filter(item => item.fid).length
  const haveVideosForDelete = bulkSelected.filter(item => item.vid).length

  const handleClose = () => {
    props.setIsOpen(false)
    setIsChecked(false)
  }

  const handleAccept = async () => {
    try {
      setIsLoading(true)
      await deleteMultipleItems(bulkSelected)
      setBulkSelected([])
      openSnackbar('Items successfully removed.')
    } catch (error) {
      openSnackbar('An error occurred while removing the items. Please try again.', 'error')
    } finally {
      handleClose()
      setIsLoading(false)
    }
  }

  useKeypress('Enter', () => {
    isChecked && handleAccept()
  })

  return (
    <>
      <StyledDialog
        open={Boolean(props.isOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <div className={`content ${isMobile && 'mobile'}`}>
          <div className='head'>
            <p>Delete {haveFolderForDelete ? `${haveFolderForDelete} ${pluralize('folder', haveFolderForDelete)} ${haveVideosForDelete ? 'and ' : ''}` : ''} {haveVideosForDelete ? `${haveVideosForDelete} ${pluralize('video', haveVideosForDelete)}` : ''}?</p>
            <CloseIcon onClick={handleClose} />
          </div>
          <p>Are you sure you want to delete selected items from your PB Vision library?</p>
          {haveFolderForDelete
            ? (
              <p>Videos contained in this folder will also be deleted.</p>

              )
            : ''}

          <div className='bottom-part'>
            <FormControlLabel
              classes={{ root: 'formControl', label: 'label' }}
              control={
                <Checkbox
                  className='checkbox'
                  color='primary'
                  checked={isChecked}
                  onChange={(evt) => {
                    setIsChecked(evt.target.checked)
                  }}
                />
          }
              label='Check to confirm'
            />
            <div className='actions'>
              <Button className='gray no-outline' onClick={handleClose}>Cancel</Button>
              <Button
                className={isChecked ? 'green' : 'gray-filled'} disabled={!isChecked || isLoading} onClick={handleAccept}
              >Delete
              </Button>
            </div>
          </div>
        </div>
        {isLoading && <Spinner />}
      </StyledDialog>
    </>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    margin: 0,
    maxWidth: 'unset'
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px 24px',
    width: '600px',

    '& .head': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& svg': {
        cursor: 'pointer'
      },
      '& p': {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: COLORS['neutral-800']
      }
    },
    '& input': {
      padding: '8px'
    },

    '& .checkbox': {
      '& span': {
        textWrap: 'nowrap',
        color: COLORS['warning-800'],
        fontSize: 14
      },
      '& path': {
        backgroundColor: 'white'
      }
    },
    '& .bottom-part': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .actions': {
        display: 'flex',
        gap: '8px',
        '& button': {
          maxHeight: '32px'
        }
      }
    },
    '&.mobile': {
      width: '90vw'
    }
  }

}))
