import Page from './index'

import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

// this page only exists to show to the native mobile app
export default isInMobileAppWebview()
  ? [
      {
        path: '/welcome',
        element: <Page />
      }
    ]
  : []
