export const DeviceOS = {
  IOS: 'iOS',
  ANDROID: 'Android',
  OTHER: 'other'
}

export function useDeviceOS () {
  const ua = navigator.userAgent
  const isIOS = /iPad|iPhone|iPod/.test(ua)
  const isAndroid = /android/i.test(ua)

  let deviceOS
  if (isIOS) {
    deviceOS = DeviceOS.IOS
  } else if (isAndroid) {
    deviceOS = DeviceOS.ANDROID
  } else {
    deviceOS = DeviceOS.OTHER
  }

  return {
    isIOS,
    isAndroid,
    deviceOS
  }
}
