import SettingsMobileMain from './mobile/main'
import { Account } from './tabs/account'
import Ambassador from './tabs/ambassador'
import Credits from './tabs/credits'
import Subscription from './tabs/subscriptions'
import Test from './tabs/test'

import Page from './index'

import { isProd } from '@/utils'

export default [
  {
    path: '/settings/*',
    element: <Page />,
    children: [
      {
        path: 'choose',
        element: <SettingsMobileMain />
      },
      {
        path: 'main',
        element: <Account />
      },
      {
        path: 'credits',
        element: <Credits />
      },
      {
        path: 'subscription',
        element: <Subscription />
      },
      {
        path: 'ambassador',
        element: <Ambassador />
      },
      !isProd && {
        path: 'test',
        element: <Test />
      }
    ]
  }
]
