const baseCourt = {
  Y_MIN: 0,
  Y_NVZ_NEAR: 15,
  Y_NET: 22,
  Y_NVZ_FAR: 29,

  X_MIN: 0,
  X_MID: 10,

  // Width Region definitions
  WIDTH_OFFSET: 5, // A reusable offset for width calculations
  ZONE_OFFSET: 5 // A reusable offset for zone calculations
}

// Now create the COURT object by extending the baseCourt object
const COURT = {
  ...baseCourt,

  Y_MAX: baseCourt.Y_NET * 2,

  X_MAX: baseCourt.X_MID * 2,

  // Width Region definitions
  LEFT_THRESH: baseCourt.X_MID - baseCourt.WIDTH_OFFSET,
  RIGHT_THRESH: baseCourt.X_MID + baseCourt.WIDTH_OFFSET,

  // Zone definitions
  SHORT_LINE_NEAR: baseCourt.Y_NVZ_NEAR - baseCourt.ZONE_OFFSET,
  SHORT_LINE_FAR: baseCourt.Y_NVZ_FAR + baseCourt.ZONE_OFFSET,
  MID_LINE_NEAR: (baseCourt.Y_NVZ_NEAR - baseCourt.ZONE_OFFSET) - baseCourt.ZONE_OFFSET,
  MID_LINE_FAR: (baseCourt.Y_NVZ_FAR + baseCourt.ZONE_OFFSET) + baseCourt.ZONE_OFFSET
}

/**
 * Determines if a shot is likely a bad shot based on shot index and its start and end positions.
 *
 * @param {number} shotIdx - The index of the shot (0 for serve, 1 for return, etc.).
 * @param {Object} start - The starting location of the shot.
 * @param {Object} start.location - The coordinates of the starting location.
 * @param {number} start.location.y - The Y-coordinate of the start location of the shot.
 * @param {Object} end - The ending location of the shot.
 * @param {Object} end.location - The coordinates of the ending location.
 * @param {number} end.location.y - The Y-coordinate of the end location of the shot.
 *
 * @returns {boolean} - Returns true if the shot is likely to be a bad shot, false otherwise.
 */
export function isLikelyBadShotData (shotIdx, start, end) {
  // If a serve shot starts inside of 5 ft (mid-zone edge) or closer
  if (shotIdx === 0 && (COURT.MID_LINE_NEAR < start.location.y && start.location.y < COURT.MID_LINE_FAR)) {
    return true
  }

  // If serve/return/third shot starts in the kitchen
  if (shotIdx <= 2 && (COURT.Y_NVZ_NEAR < start.location.y && start.location.y < COURT.Y_NVZ_FAR)) {
    return true
  }

  // If the serve/return/third shot starts and ends before the nearest kitchen line
  if (shotIdx <= 2 && (
    (start.location.y > COURT.Y_NVZ_FAR && end.location.y > COURT.Y_NVZ_FAR) ||
      (start.location.y < COURT.Y_NVZ_NEAR && end.location.y < COURT.Y_NVZ_NEAR)
  )) {
    return true
  }

  return false
}

// normalize shot directions so all shots start from the same side of the court
export function normalizeDirection (shot) {
  const width = 20
  const height = 44

  return {
    ...shot,
    ...(
      shot.start.location.y > shot.end.location.y
        ? {
            start: {
              ...shot.start,
              location: {
                ...shot.start.location,
                x: width - shot.start.location.x,
                y: height - shot.start.location.y
              }
            },
            peak: {
              ...shot.peak,
              x: width - shot.peak.x,
              y: height - shot.peak.y
            },
            end: {
              ...shot.end,
              location: {
                ...shot.end.location,
                x: width - shot.end.location.x,
                y: height - shot.end.location.y
              }
            }
          }
        : {}
    )
  }
}

export default COURT
