/* eslint-disable max-classes-per-file */

class VideoInfo {
  constructor (durationSecs, fps, width, height) {
    this.durationSecs = durationSecs
    this.fps = fps
    this.width = width
    this.height = height
  }

  numPixels () {
    return this.width * this.height
  }
}

class EstimatedDuration {
  constructor ({ fixedBefore = 0, fixedDuring = 0, perSecPerPixel = 0, perSec = 0, scalesWithFPS = false }) {
    this.fixedBefore = fixedBefore
    this.fixedDuring = fixedDuring
    this.perSecPerPixel = perSecPerPixel
    this.perSec = perSec
    this.scalesWithFPS = scalesWithFPS
  }

  dependsOnVideo () {
    return this.perSecPerPixel || this.perSec
  }

  estimateTotalSecs (videoInfo, excludeFixedBefore) {
    let ret = 0
    if (!excludeFixedBefore) {
      ret += this.fixedBefore
    }
    ret += this.fixedDuring
    if (this.dependsOnVideo()) {
      // istanbul ignore else
      const multiplier = !this.scalesWithFPS ? 1 : (videoInfo.fps / 30)
      if (this.perSecPerPixel) {
        ret += multiplier * this.perSecPerPixel * videoInfo.numPixels() * videoInfo.durationSecs
      } else if (this.perSec) {
        ret += multiplier * this.perSec * videoInfo.durationSecs
      } else {
        throw new Error('should not get here')
      }
    }
    return ret
  }
}

class State {
  constructor ({ state, kind = null, name, estimatedDuration }) {
    this.states = new Set(typeof state === 'string' ? [state] : state)
    this.kind = kind
    this.name = name
    this.estimatedDuration = estimatedDuration
  }

  isUpdateForThisState (update) {
    if (!this.states.has(update.state)) {
      return false
    }
    if (this.kind && update.data?.kind !== this.kind) {
      return false
    }
    return true
  }

  estimateTotalSecs (videoInfo, excludeFixedBefore) {
    return this.estimatedDuration.estimateTotalSecs(videoInfo, excludeFixedBefore)
  }
}

function getCurrentEpoch () {
  return new Date().getTime() / 1000
}

// if there's no update for 5min, don't keep extrapolating progress
const maxSecsToExtrapolate = 300

function computePercentDone (isDone, currentEstimatedTotalSecs, elapsedSecs, secsToNotExtrapolate) {
  if (isDone) {
    return 1
  }
  // don't estimate higher than 99% done if it isn't actually done yet
  const maxReturnValue = 0.99
  if (currentEstimatedTotalSecs > 0) {
    return Math.min(
      maxReturnValue,
      Math.max(
        0,
        (elapsedSecs - secsToNotExtrapolate) / (currentEstimatedTotalSecs - secsToNotExtrapolate)))
  } else {
    return maxReturnValue
  }
}

function makeProgressReport (start, endOriginal, endCurrent, name, isDone, lastUpdateEpoch) {
  const now = getCurrentEpoch()
  const cappedNow = Math.min(now, lastUpdateEpoch + maxSecsToExtrapolate)
  const secsToNotExtrapolate = now - cappedNow
  const currentEstimatedTotalSecs = endCurrent - start
  const elapsedSecs = now - start
  const percentDone = computePercentDone(
    isDone, currentEstimatedTotalSecs, elapsedSecs, secsToNotExtrapolate)
  return { name, start, endOriginal, endCurrent, percentDone }
}

class ProcessProgress {
  constructor (processingStartEpoch, videoInfo, states) {
    this.videoInfo = videoInfo
    this.states = states
    this.stateIdxOn = 0
    this.startEpoch = processingStartEpoch
    this.lastUpdateEpoch = processingStartEpoch
    this.stateStarted = this.startEpoch
    this.originalFinishEpochEstimate = this.startEpoch + this.estimateSecsLeft()
    this.stateOriginalFinishEpochEstimate = this.startEpoch + this.states[0].estimateTotalSecs(this.videoInfo, false)
    this.stateCurrentFinishEpochEstimate = this.stateOriginalFinishEpochEstimate
  }

  isDone () {
    return this.stateIdxOn === this.states.length - 1
  }

  estimateSecsLeft () {
    if (this.isDone()) {
      return 0
    }
    let totalSecsLeft = 0
    for (let i = this.stateIdxOn; i < this.states.length; i++) {
      const secsForThisState = this.states[i].estimateTotalSecs(this.videoInfo, i <= this.stateIdxOn && i !== 0)
      if (i === this.stateIdxOn) {
        if (!this.stateDoneEpoch) {
          const currentEpoch = Math.min(getCurrentEpoch(), this.lastUpdateEpoch + maxSecsToExtrapolate)
          const secsElapsed = currentEpoch - this.stateStarted
          const secsLeft = Math.max(0, secsForThisState - secsElapsed)
          totalSecsLeft += secsLeft
        }
      } else {
        totalSecsLeft += secsForThisState
      }
    }
    return totalSecsLeft
  }

  updateProgress (update) {
    this.vid = update.vid
    this.lastUpdateEpoch = update.epoch
    for (let i = 0; i < this.states.length; i++) {
      const state = this.states[i]
      if (state.isUpdateForThisState(update)) {
        if (this.stateIdxOn !== i) {
          this.stateName = update.state
          this.stateIdxOn = i
          this.stateStarted = update.epoch
          this.stateProgressStarted = null
          this.stateOriginalFinishEpochEstimate = this.stateStarted + state.estimateTotalSecs(this.videoInfo, true)
          this.stateCurrentFinishEpochEstimate = this.stateOriginalFinishEpochEstimate
          this.stateDoneEpoch = false
        }
        // states which don't communicate percent done just report when they
        // are completely done
        const percentDone = update.data?.percentDone ?? update.data?.stage?.percent_done ?? 1
        if (percentDone > 0 && update.state !== 'waiting for rallies') {
          if (this.stateProgressStarted === null) {
            this.stateProgressStarted = update.epoch
            this.stateProgressStartedPercent = percentDone
          } else if (percentDone > this.stateProgressStartedPercent) {
            const secsElapsed = update.epoch - this.stateProgressStarted
            const remainingPercentDone =
              (percentDone - this.stateProgressStartedPercent) / (1 - this.stateProgressStartedPercent)
            const totalSecsEstimated = secsElapsed / remainingPercentDone
            const secsLeft = totalSecsEstimated - secsElapsed
            this.stateCurrentFinishEpochEstimate = update.epoch + secsLeft
          }
          if (percentDone >= 1) {
            this.stateDoneEpoch = update.epoch
          }
        }
        return
      }
    }
    if (update.state !== 'mux_stream') {
      console.warn('got update for unknown state', update)
    }
  }

  getProgress () {
    const offset = this.stateDoneEpoch ? 1 : 0
    const state = this.states[this.stateIdxOn + offset]
    const totalSecsLeft = this.estimateSecsLeft()
    const ret = {
      vid: this.vid,
      overall: makeProgressReport(
        this.startEpoch,
        this.originalFinishEpochEstimate,
        this.lastUpdateEpoch + totalSecsLeft,
        state?.name,
        this.isDone(),
        this.lastUpdateEpoch
      ),
      step: null
    }
    if (state) {
      const stateStart = offset ? this.stateDoneEpoch : this.stateStarted
      const stateEndOrig = offset
        ? stateStart + state.estimateTotalSecs(this.videoInfo, false)
        : this.stateOriginalFinishEpochEstimate
      const stateEndCur = offset ? stateEndOrig : this.stateCurrentFinishEpochEstimate
      ret.step = makeProgressReport(
        stateStart,
        stateEndOrig,
        stateEndCur,
        state.name,
        this.isDone(),
        this.lastUpdateEpoch)
    }
    ret.lastUpdateEpoch = this.lastUpdateEpoch
    return ret
  }
}

const transcodeFixedBefore = 85

const mainProcess = [
  new State({
    state: 'verified_upload',
    name: 'Verifying Upload',
    estimatedDuration: new EstimatedDuration({
      fixedBefore: 2.0,
      fixedDuring: 0.5
    })
  }),
  new State({
    state: 'transcoding',
    kind: 'cv',
    name: 'Preparing video for AI',
    estimatedDuration: new EstimatedDuration({
      fixedBefore: transcodeFixedBefore,
      perSecPerPixel: 9.3137e-8,
      scalesWithFPS: true
    })
  }),
  new State({
    state: 'cv-download',
    name: 'Starting AI',
    estimatedDuration: new EstimatedDuration({
      fixedBefore: 305,
      perSecPerPixel: 1.0402e-90
    })
  }),
  new State({
    state: 'cv-detect rallies',
    name: 'Detecting rallies and dead time',
    estimatedDuration: new EstimatedDuration({
      fixedDuring: 19.1,
      perSec: 1.0707
    })
  }),
  new State({
    state: 'cv-detect court',
    name: 'Detecting the court lines and net',
    estimatedDuration: new EstimatedDuration({
      fixedBefore: 0.7,
      fixedDuring: 37.7
    })
  }),
  new State({
    state: 'cv-insights',
    name: 'Analyzing gameplay',
    estimatedDuration: new EstimatedDuration({ perSec: 2.3767 })
  }),
  new State({
    state: 'de',
    name: 'Analyzing gameplay',
    estimatedDuration: new EstimatedDuration({
      fixedBefore: 62,
      perSec: 0.05778
    })
  }),
  new State({
    state: 'cleanup',
    name: 'Finalizing analysis',
    estimatedDuration: new EstimatedDuration({
      fixedDuring: 0.5
    })
  }),
  new State({
    state: ['failed', 'succeeded'],
    name: 'All done!',
    estimatedDuration: new EstimatedDuration({
      fixedDuring: 0.5
    })
  })
]
const secondaryProcess = [
  // secondary cannot start until verified_upload is done from main process
  new State({
    state: 'verified_upload',
    name: '',
    estimatedDuration: mainProcess[0].estimatedDuration
  }),
  new State({
    state: 'transcoding',
    kind: 'max-scale',
    name: 'Optimizing your video for streaming (part 1 of 2)',
    estimatedDuration: new EstimatedDuration({
      // secondary can't start until first step in the main process is done too
      fixedBefore: transcodeFixedBefore + mainProcess[0].estimatedDuration.estimateTotalSecs(),
      perSecPerPixel: 8.6514e-8
    })
  }),
  new State({
    state: 'transcoding',
    kind: 'max-watermark',
    name: 'Optimizing your video for streaming (part 2 of 2)',
    estimatedDuration: new EstimatedDuration({
      fixedDuring: 0.9,
      perSecPerPixel: 8.4176e-8
    })
  }),
  new State({
    state: 'waiting for rallies',
    name: 'Waiting for rally detection to finish',
    estimatedDuration: new EstimatedDuration({
      // this is the sum of the cv steps in the primary process before this can
      // be processed
      fixedDuring: 304.5,
      perSec: 1.0707
    })
  }),
  new State({
    state: 'ready to slice',
    name: 'Preparing to cut out dead time from the video',
    estimatedDuration: new EstimatedDuration({
      fixedDuring: 0.5
    })
  }),
  new State({
    state: 'mux_stream',
    name: 'Setting up video streaming',
    estimatedDuration: new EstimatedDuration({
      perSecPerPixel: 3.3e-7,
      scalesWithFPS: true
    })
  })
]
const processes = [mainProcess, secondaryProcess]

export class ProgressTracker {
  constructor ({ durationSecs, fps, width, height, processingStartEpoch }) {
    this.videoInfo = new VideoInfo(durationSecs, fps, width, height)
    this.progress = processes.map((x) => new ProcessProgress(
      processingStartEpoch, this.videoInfo, x))
  }

  onProcessingProgress (part, update) {
    if (!update) {
      return
    }
    const process = this.progress[part]
    process.updateProgress(update)
    if (update.state === 'verified_upload') {
      this.progress[1].updateProgress(update)
    }
  }

  getProgress () {
    return this.progress.map((x) => x.getProgress())
  }
}
