/**
 * This slice tracks the progress of a video which is being processed by the
 * backend. Typically, the user monitors videos which they uploaded from this
 * device, as well as others which appear in their library (these others may
 * have been uploaded from another device or even from a partner like a
 * facility instead of by the user).
 */
import { createSlice } from '@reduxjs/toolkit'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { apisSlice } from './apis'
import { userChanged } from './auth'

import { assert, sleep } from '@/utils'
import { callAPIFromThunk } from '@/utils/api'
import { ProgressTracker } from '@/utils/estimate-time-left'
import { getDB } from '@/utils/firebase'

export const sliceName = 'processing'

const processingSlice = createSlice({
  name: sliceName,
  initialState: {
    /**
     * Maps vid to their most recent workflow ID.
     * @type {Array<vid>}
     */
    vidToLatestWorkflowId: {},

    /** List of vids whose progress reports have been requested without a workflow ID. */
    workflowIdsRequestedForVids: [],

    /**
     * @typedef {object} ProcessingProgress
     * @property {int} estimatedFinishEpoch current estimate of when processing will be done
     * @property {int} percentDone percent complete as of the last update we received
     * @property {int} lastUpdateEpoch when info progress made was last updated (server-side update time, not when this web app received that update)
     * @property {string} state the name of the state of the latest progress
     */
    /**
     * This map records which processing progress we're watching (for which
     * vid and workflow ID pairs). Includes videos that have finished
     * processing so we don't start watching them again during this session.
     *
     * If we've not yet received the first update, the value will be null.
     *
     * @type {Map<id, ProcessingProgress|null>}
     */
    watching: {} // map of vid + workflow id to processing progress
  },
  reducers: {
    requestedProgressForVidWithoutWorkflowId: (state, action) => {
      const { vid } = action.payload
      state.workflowIdsRequestedForVids.push(vid)
    },
    rememberLatestWorkflowIdForVid: (state, action) => {
      const { vid, workflowId } = action.payload
      state.vidToLatestWorkflowId[vid] = workflowId
    },
    startedWatching: (state, action) => {
      const { vid, workflowId } = action.payload
      const key = makeWatchingKey(vid, workflowId)
      assert(state.watching[key] === undefined, 'should not already be watching')
      state.watching[key] = null
    },
    processingProgressReceived: (state, action) => {
      const { vid, workflowId, progress, lastUpdateEpoch } = action.payload
      const key = makeWatchingKey(vid, workflowId)
      if (!inMemoryState.unsubscribeFunctions[key]) {
        return // no longer watching this
      }
      state.watching[key] = {
        lastUpdateEpoch,
        estimatedFinishEpoch: progress.endCurrent,
        percentDone: progress.percentDone,
        state: progress.name
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userChanged, state => {
      // when the user signs in or out, clear what we're watching
      state.watching = {}
      Object.values(inMemoryState.unsubscribeFunctions).forEach(f => f())
      assert(Object.keys(inMemoryState.unsubscribeFunctions).length === 0, 'should have been cleared')
    })
  }
})

export const { reducer } = processingSlice

const inMemoryState = {
  unsubscribeFunctions: {} // vid to function to call to unsubscribe from updates
}

function makeWatchingKey (vid, workflowId) {
  assert(vid)
  assert(workflowId)
  return `${vid}-${workflowId}`
}

/**
 * Returns information about how videos in processing are progressing for their
 * LATEST workflow. Only includes videos we've asked to watch progress for.
 * This includes videos we have fetched data for, uploads completed during this
 * session and videos recently added to the library.
 *
 * @returns {Map<vid, ProcessingProgress|null>} value is null if we don't
 *   yet have processing progress information
 */
export function useAllLatestProcessingProgress () {
  const latest = useSelector(s => s[sliceName].vidToLatestWorkflowId)
  const watching = useSelector(s => s[sliceName].watching)
  const pending = useSelector(s => s[sliceName].workflowIdsRequestedForVids)
  return useMemo(() => {
    const ret = {}
    for (const vid of pending) {
      // if we were asked to watch progress of a video, then that should mean
      // it is in progress (or JUST finished)
      ret[vid] = null
    }
    for (const [vid, workflowId] of Object.entries(latest)) {
      if (!workflowId) {
        ret[vid] = null // don't have the progress data yet, but it's coming
      } else {
        ret[vid] = watching[makeWatchingKey(vid, workflowId)]
      }
    }
    return ret
  }, [latest, pending, watching])
}

/**
 * Returns progress information for a specific workflow. Returns null if
 * progress information is still loading.
 *
 * @param {string} vid if this is omitted, this function call is a no-op
 * @param {string} [workflowId] if omitted (undefined), gets progress for latest (if null, then this function will do nothing and just return null)
 * @returns {ProcessingProgress|null|undefined} Returns progress, unless vid is
 *   not provided (in which case undefined is return) or we haven't received
 *   progress data from the server yet (in which case null is returned until
 *   that data is received).
 */
export function useProcessingProgressForVideo (vid, workflowId) {
  const dispatch = useDispatch()
  const latestWorkflowId = useSelector(s => s[sliceName].vidToLatestWorkflowId[vid])
  workflowId = workflowId ?? latestWorkflowId
  const key = workflowId ? makeWatchingKey(vid, workflowId) : null
  const progress = useSelector(s => s[sliceName].watching[key])
  // ask to load progress if we haven't started loading it yet
  useEffect(() => {
    if (vid && progress === undefined && workflowId !== null) {
      dispatch(watchVideoProgress(vid, workflowId))
    }
  }, [dispatch, progress, vid, workflowId])
  if (!vid) {
    return undefined
  }
  return progress ?? null
}

export function watchVideoProgress (vid, workflowId = undefined, isLatestWorkflow = false) {
  if (workflowId === undefined) {
    isLatestWorkflow = true
  }
  return async (dispatch, getState) => {
    if (!workflowId) {
      const initialState = getState()[sliceName]
      workflowId = initialState.vidToLatestWorkflowId[vid]
      if (!workflowId) {
        // if we need to do an API call to figure out the workflowId then
        // remember that this is in progress
        if (initialState.workflowIdsRequestedForVids.indexOf(vid) !== -1) {
          return // another watch call is already doing this
        }
        dispatch(processingSlice.actions.requestedProgressForVidWithoutWorkflowId({ vid }))
        workflowId = await waitForWorkflowId(dispatch, getState, vid)
      }
      if (!workflowId) {
        console.error('failed to get workflow id for', vid)
        return
      }
    }

    const watchingKey = makeWatchingKey(vid, workflowId)
    const stateAfterGettingWorkflowId = getState()[sliceName]
    if (stateAfterGettingWorkflowId.watching[watchingKey] !== undefined) {
      // already watching this video
      return
    }

    // update the latest workflow associated with this video
    if (isLatestWorkflow) {
      dispatch(processingSlice.actions.rememberLatestWorkflowIdForVid({
        vid, workflowId
      }))
    }
    dispatch(processingSlice.actions.startedWatching({ vid, workflowId }))

    // get processing start time (in parallel with the next fetch)
    const startEpochPromise = waitForWorkflowStartEpoch(workflowId)

    // get the video's metadata so we can estimate it's processing time
    const { metadata, ...rest } = await callAPIFromThunk(
      dispatch, getState, apisSlice.endpoints.getVideoMetadata, { vid }, { isUserAPI: false })
    if (!metadata) {
      console.warn('failed to get video metadata', { vid, ...rest })
      return
    }
    const { fps, secs, width, height } = metadata

    // get when the video was uploaded
    const processingStartEpoch = await startEpochPromise

    const unsubscribeFunc = onVideoProcessingProgressGetSummary(
      vid, workflowId, fps, secs, width, height, processingStartEpoch, mainProcessingBranchProgress => {
        dispatch(processingSlice.actions.processingProgressReceived({
          vid,
          workflowId,
          progress: mainProcessingBranchProgress.overall,
          lastUpdateEpoch: mainProcessingBranchProgress.lastUpdateEpoch
        }))
        if (mainProcessingBranchProgress.overall.percentDone === 1) {
          // refetch the video when processing is done and stop listening for
          // new progress
          dispatch(apisSlice.util.invalidateTags([{ type: 'Video', id: vid }]))
          inMemoryState.unsubscribeFunctions[watchingKey]()
        }
      })
    inMemoryState.unsubscribeFunctions[watchingKey] = unsubscribeFunc
  }
}

/**
 * Wait for `func` to return a truthy value. If the workflow has not yet
 * started, then `func` should return undefined and we will wait and try again.
 * Eventually, give up and return null if we never get a positive result.
 * Usually one will be assigned within a few seconds though.
 */
async function waitForWorkflowToStart (func) {
  let tries = 0
  const secsToWait = [3, 2, 5, 30, 60]
  while (tries < secsToWait.length) {
    const ret = await func()
    if (ret) {
      return ret
    }
    await sleep(secsToWait[tries] * 1000)
    tries += 1
  }
  return null
}

/**
 * Get the workflow ID of the latest workflow for a video. If the video does
 * not yet have a workflow, then wait and try again. Eventually, give up and
 * return null if one can't be found. Usually one will be assigned within a
 * few seconds though.
 * @returns {string|null} the workflow id, or
 */
async function waitForWorkflowId (dispatch, getState, vid) {
  return waitForWorkflowToStart(async () => {
    const ret = await callAPIFromThunk(
      dispatch, getState, apisSlice.endpoints.getVideo, { vid }, { forceRefetch: true, isUserAPI: false })
    if (ret.workflows.length) {
      const latestWorkflow = ret.workflows[0]
      return latestWorkflow.workflowId
    }
  })
}

/**
 * Get the time processing started for a video. If it has not yet started, then
 * wait and try again. Eventually, give up and return null if one can't be
 * found. Usually one will be assigned within a few seconds though.
 * @returns {string|null} the workflow id, or
 */
async function waitForWorkflowStartEpoch (workflowId) {
  const docRef = doc(getDB(), 'ProcessingStarted', workflowId)
  const processingStartedDoc = await getDoc(docRef)
  if (processingStartedDoc.exists()) {
    return processingStartedDoc.data().epoch
  }
  console.warn('missing workflow start epoch', workflowId)
  return null
}

/**
 * This callback function is called to report progress in processing a video.
 *
 * @callback VideoProcessingProgressCallback
 * @param {VideoProcessingProgress} progress
 * @param {integer} part either 0 (primary) or 1 (secondary processing branch)
 */

/**
 * Listen for updates in the processing of some file uploaded to GCS.
 *
 * @param {string} vid
 * @param {string} workflowId the workflow ID that we want to get progress updates for
 * @param {VideoProcessingProgressCallback} callback to call when a progress update is received
 * @param {Array<int>} [parts] which pipeline branches to listen to updates for; by default, just the main branch (0)
 * @returns {Function} Returns a function which detaches this listener.
 */
function onVideoProcessingProgress (vid, workflowId, callback, parts = [0]) {
  const unsubscribeFunctions = []
  const db = getDB()

  for (const part of parts) {
    const docId = `${vid}\0${workflowId}\0${part}`
    const docRef = doc(db, 'ProcessingProgress', docId)
    let lastData = null
    const unsubscribeOnSnapshot = onSnapshot(docRef, (document) => {
      if (document.exists()) {
        lastData = document.data()
        callback(lastData, part)
      }
    })
    unsubscribeFunctions.push(unsubscribeOnSnapshot)
    const cancelInterval = setInterval(() => {
      if (lastData) {
        callback(lastData, part)
      }
    }, 5000)
    unsubscribeFunctions.push(() => clearInterval(cancelInterval))
  }
  return () => {
    unsubscribeFunctions.forEach((unsubscribeFunction) => {
      unsubscribeFunction()
    })
    delete inMemoryState.unsubscribeFunctions[makeWatchingKey(vid, workflowId)]
  }
}

/**
 * This callback function is called to report progress in processing a video.
 *
 * @callback EstimatedVideoProcessingProgressCallback
 * @param {EstimatedVideoProcessingProgress} progress
 */
/**
 * Data describing the most recent update in processing a video.
 *
 * @typedef {object} EstimatedVideoProcessingProgress
 * @property {string} [vid] the Video ID (absent if not yet assigned)
 * @property {Array<ProgressPart>} progress the first element is the main
 *   processing progress; the second element has secondary processing
 *   progress; a null element means that part is done (secondary will always
 *   finish first)
 */
/**
 * @typedef {object} ProgressPart
 * @property {string} vid video ID
 * @property {ProgressInfo} overall overall progress
 * @property {ProgressInfo} step progress for the current step (null if all done)
 * @property {int} lastUpdateEpoch when progress was last reported by the server
 */
/**
 * @typedef {object} ProgressInfo
 * @property {string} name the name of the step we're on (null if this
 *   part is done)
 * @property {number} start when processing started for this
 * @property {number} endOriginal our original guess for the finish time
 * @property {number} endCurrent our current guess for the finish time
 * @property {number} percentDone how far done we are [0, 1]
 */
/**
 * Listen for updates in the processing of some file uploaded to GCS and
 * get estimates of completion time and a summary of the current work in
 * progress.
 *
 * @param {string} vid
 * @param {string} workflowId the workflow ID that we want to get progress updates for
 * @param {number} fps frame rate of the input video
 * @param {number} durationSecs duration in seconds
 * @param {number} width width of the video
 * @param {number} height height of the video
 * @param {int} processingStartEpoch when processing started
 * @param {EstimatedVideoProcessingProgressCallback} callback reports progress
 * @returns {Function} Returns a function which detaches this listener.
 */
function onVideoProcessingProgressGetSummary (vid, workflowId, fps, durationSecs, width, height, processingStartEpoch, callback) {
  const tracker = new ProgressTracker({ durationSecs, fps, width, height, processingStartEpoch })
  return onVideoProcessingProgress(vid, workflowId, (progress, part) => {
    tracker.onProcessingProgress(part, progress)
    // only sending overall progress on the main branch (0) of processing
    const mainProcessingBranchProgress = tracker.getProgress()[0]
    callback(mainProcessingBranchProgress)
  })
}
