import Countdown from 'react-countdown'

import { formatDuration } from '@/utils/time'

function renderCountdown ({ hours, minutes, seconds, completed }) {
  if (completed) {
    return '<1m remaining'
  }
  const secsLeft = hours * 3600 + minutes * 60 + seconds
  return formatDuration(secsLeft, { suf: ' remaining' })
}

export function CountdownRemaining ({ epoch }) {
  if (!epoch) {
    return 'TBD remaining'
  }
  return <Countdown date={1000 * epoch} renderer={renderCountdown} />
}
