/**
 * Gets the color corresponding to the quality of a shot's trajectory based on a given value.
 *
 * @param {number} value - The quality value of the shot's trajectory, ranging from 0 to 1. (-1 if unknown)
 * @returns {string} The hexadecimal color code representing the shot's trajectory quality.
 * @example
 * // returns '#FF2C2C'
 * getShotTrajectoryColorByValue(0);
 */
export function getShotTrajectoryColorByValue (value) {
  const colors = [
    '#FF2C2C', // Poor
    '#FF6816', // Fair
    '#FFA500', // Average
    '#B4E100', // Good
    '#11EB05' // Excellent
  ]

  // Ensure the value is higher then 0
  if (value < 0) {
    return '#8B8B8B'
  }

  // Determine the index based on the value
  const index = Math.min(Math.floor(value * colors.length), colors.length - 1)

  return colors[index]
}

export function getColorForShot (shot) {
  const isFault = shot.is_final && Boolean(shot.errors?.faults)
  if (isFault) {
    return '#FF0000'
  }
  const quality = shot.quality.overall ?? -1
  return getShotTrajectoryColorByValue(quality)
}
