import { Dialog as MuiDialog } from '@mui/material'
import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

/**
 * UserImageDialog container
 *
 * @returns {React.ReactElement}
 */
export const Dialog = styled(MuiDialog)({
  '& .MuiPaper-root': {
    zIndex: 1300,
    width: '510px',
    height: '398px',
    padding: '16px 24px',
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    borderColor: '#cdd7e1',
    borderRadius: '12px',
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: COLORS['neutral-050'],
    boxShadow: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '& .header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .title': {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '166%',
        textAlign: 'left',
        color: COLORS['neutral-700']
      },
      '& .close-icon': {
        cursor: 'pointer',
        fontSize: '22px',
        color: '#636b74'
      }
    },
    '& .content': {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '8px',
      overflow: 'hidden',
      backgroundColor: COLORS['neutral-050']
    },
    '&.upload-image-view .content': {
      '& .upload-background': {
        width: '100%',
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        border: `1px solid ${COLORS['neutral-200']}`,
        '& .avatar-image': {
          fontSize: '260px'
        }
      }
    },
    '&.edit-image-view': {
      height: '456px',
      '& .content': {
        '& .editor-container': {
          width: '100%',
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden'
        },
        '& .slider-container': {
          display: 'flex',
          alignItems: 'center',
          height: '50px',
          width: '100%',
          padding: '0 35px'
        }
      }
    }
  }
})
