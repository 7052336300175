import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import PopupAlert from './popup'
import { BuyMoreCreditsBannerActions, BuyMoreCreditsMessage, CreateAccountActions, ExploreSubscriptionPlansActions, UpgradeBeforeFreeTrialEndsMessage } from './variations/monetization-actions'

import { removePopup } from '@/store/controls'
import { POPUP_KEYS } from '@/utils/popups'

const messageVariations = {
  [POPUP_KEYS.SUBSCRIBED_OUT_OF_CREDIT]: <BuyMoreCreditsMessage />,
  [POPUP_KEYS.UPGRADE_BEFORE_FREE_TRIAL]: <UpgradeBeforeFreeTrialEndsMessage />
}

const actionsVariations = {
  [POPUP_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT]: <ExploreSubscriptionPlansActions />,
  [POPUP_KEYS.SUBSCRIBED_OUT_OF_CREDIT]: <BuyMoreCreditsBannerActions />,
  [POPUP_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT]: <CreateAccountActions />
}

export const PopupAlertDispatcher = () => {
  const location = useLocation()
  const popups = useSelector(state => state.controls.popups.stack)
  const dispatch = useDispatch()

  function onRemovePopup (id) {
    dispatch(removePopup(id))
  }

  useEffect(() => {
    popups[0] && onRemovePopup(popups[0].id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (popups.length) {
    const popup = popups[0]

    return (
      <PopupAlert
        {...popup}
        message={messageVariations[popup.id] || popup.message}
        actions={actionsVariations[popup.id]}
        onRemove={() => onRemovePopup(popup.id)}
      />
    )
  }
  return null
}
