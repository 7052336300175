/**
 * Format bytes as human-readable text.
 *
 * @param {number} bytes Number of bytes.
 * @param {boolean} si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param {number} dp Number of decimal places to display.
 *
 * @returns {string} Formatted string.
 */
export function humanFileSize (bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024
  // Make sure bytes is a valid number, fallback to 0 otherwise
  if (isNaN(bytes)) {
    bytes = 0
  }
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh
    u += 1
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return `${bytes.toFixed(dp)} ${units[u]}`
}

export function getFileExt (file) {
  const localFilename = file.name
  const pieces = localFilename.split('.')
  return pieces[pieces.length - 1].toLowerCase()
}
