import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import { thumbPhoneWidth, thumbTabletWidth, thumbDesktopWidth } from '../video-thumb/container'

import { UploadButton } from '@/components/upload/UploadButton'
import { useCanUploadVideo } from '@/hooks/use-can-upload-video'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 16px 12px 16px',
  width: '32%',
  aspectRatio: '1.3/1',
  border: `1px solid ${COLORS['neutral-200']}`,
  borderRadius: '6px',
  backgroundColor: COLORS['neutral-100'],
  '&.phone': {
    width: thumbPhoneWidth,
    aspectRatio: '1.4/1'
  },
  '&.tablet': {
    width: thumbTabletWidth
  },
  '&.desktop': {
    width: thumbDesktopWidth
  }
})

/**
 */
function UploadVideoThumb () {
  const isMobile = useMobileDetect()
  const isTablet = useMobileDetect('tablet')
  const isDesktop = useMobileDetect('desktop')
  const { canUploadNewVideo } = useCanUploadVideo()

  if (!canUploadNewVideo()) {
    return
  }
  return (
    <Container className={clsx([{
      phone: isMobile,
      tablet: isTablet,
      desktop: isDesktop
    }])}
    >
      <UploadButton className='outlined' />
    </Container>
  )
}

export default UploadVideoThumb
