import CloseIcon from '@mui/icons-material/Close'
import MuiDialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

import DiscordLogo from '@/assets/icons/discord-logo.svg?react'
import { Button } from '@/components/button'
import { SimpleButton } from '@/components/button/simple-button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useGetVideo } from '@/store/video'
import COLORS from '@/utils/colors'
import { downloadOrOpenLink } from '@/utils/download'

const Dialog = styled(MuiDialog)({
  '.MuiPaper-root': {
    color: COLORS['neutral-700'],
    height: 440,
    maxWidth: 756,
    minWidth: 756,
    padding: '32px 40px'
  },
  '& h6': {
    fontSize: 24,
    fontWeight: 600,
    display: 'flex',
    '& .close-btn': {
      color: COLORS['neutral-500'],
      marginLeft: 'auto'
    }
  },
  '& .sub-title': {
    fontSize: 16,
    fontWeight: 300
  },
  '& .download-table': {
    border: '1px solid  rgba(99, 107, 116, 0.30)',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    fontWeight: 400,
    margin: '27px 0',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',

      '&.row-1': {
        backgroundColor: COLORS['neutral-100']
      },
      '&:not(:last-of-type)': {
        borderBottom: '1px solid  rgba(99, 107, 116, 0.30)'
      },
      '& > div': {
        padding: '8px 12px'
      },
      '& .file-name': {
        display: 'flex',
        fontSize: 16,
        fontWeight: 700,
        maxWidth: 120,
        minWidth: 120
      },
      '& .desc': {
        display: 'flex',
        flexGrow: 1
      },
      '& .download .MuiButtonBase-root': {
        background: '#fff',
        border: `1px solid ${COLORS['neutral-300']}`,
        borderRadius: 8,
        color: 'inherit',
        '&:hover': {
          backgroundColor: 'unset'
        }
      }
    },
    '& .slink': {
      marginLeft: 4
    }
  },
  '& .footer': {
    fontSize: 14
  },
  '& .discord-logo': {
    background: '#5865F2',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    marginRight: 24,
    height: 48,
    width: 149,
    minWidth: 149
  },
  '& a': {
    color: COLORS['primary-500'],
    cursor: 'pointer',
    textDecoration: 'none'
  },
  '&.mobile': {
    '.MuiPaper-root': {
      height: 'auto',
      maxHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      overflow: 'auto'
    },
    '& .download-table > div': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '& .footer': {
      display: 'flex',
      flexDirection: 'column',
      '& > .desc': {
        order: 1,
        marginBottom: 8
      },
      '& > a': {
        order: 2
      }
    }
  }
})

const tableData = [
  {
    file: 'stats.json',
    link: 'https://pbv-public.github.io/stats/?s=~stats~game',
    text: 'A comprehensive set of advanced statistics.'
  },
  {
    file: 'insights.json',
    link: 'https://pbv-public.github.io/insights',
    text: 'The data used to power the PB Vision app.'
  },
  {
    file: 'cv.json',
    link: 'https://pb-vision.github.io/schemas-cv',
    text: 'The low-level frame-by-frame computer vision data.'
  }
]

const DownloadRawDataModal = ({ vid, open, onClose }) => {
  const videoDetails = useGetVideo(vid)
  const isMobile = useMobileDetect('sm')
  const aiEngineVersion = videoDetails?.data?.workflows?.[0]?.aiEngineVersion

  const getLink = (file) => {
    if (file === 'stats.json' || file === 'insights.json') {
      downloadOrOpenLink(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/${file}`, file, true)
    }
    downloadOrOpenLink(`${import.meta.env.VITE_API_SERVER}/video/data/cv?vid=${vid}`, file, true)
  }

  // This is needed to prevent click from escaping into the video thumb
  // and triggering the navigation to the video page
  const prevent = (e) => {
    e.stopPropagation()
  }

  return (
    <Dialog open={Boolean(open)} onClose={onClose} onClick={prevent} className={isMobile ? 'mobile' : undefined}>
      <h6>
        Download Raw Data
        <SimpleButton className='close-btn' onClick={onClose}>
          <CloseIcon />
        </SimpleButton>
      </h6>
      <div className='sub-title'>
        Want to dive deeper into your data? You&apos;ve come to the right place! <a href='https://help.pb.vision/articles/2010377-advanced-insights-with-pb-visions-data-exports' target='_blank' rel='noreferrer'>Learn more</a>
      </div>
      <div className='download-table'>
        {tableData.map((row, indx) => (
          <div className={`row-${indx}`} key={row.file}>
            <div className='file-name'>{row.file}</div>
            <div className='desc'>
              {row.text}
              {!isMobile && <a className='slink' href={row.link} target='_blank' rel='noreferrer'>Schema</a>}
            </div>
            {!isMobile && (
              <div className='download'>
                <Button onClick={() => getLink(row.file)} target='_blank' variant='contained' disableElevation>
                  Download
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='footer'>
        <a href='https://discord.gg/4QDcaTCEUh' className='discord-logo' target='_blank' rel='noreferrer'>
          <DiscordLogo />
        </a>
        <div className='desc'>
          Questions or recommendations? Join us in the <b>#pickleball_stats</b> channel.
        </div>
      </div>
    </Dialog>
  )
}

export default DownloadRawDataModal
