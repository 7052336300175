import { styled } from '@mui/material'
import React from 'react'

import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'

export default function MultiWayToggle (props) {
  function isSelected (value) {
    return value === props.selected
  }
  function onChange (value) {
    props.onChange(value)
  }

  return (
    <Container>
      {props.options.map((option, i) => (
        <div onClick={() => onChange(option.value)} className={cls('option', isSelected(option.value) && 'selected')} key={i}>
          <span>{option.label}</span>
        </div>
      ))}
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  backgroundColor: COLORS['neutral-200'],
  borderRadius: '8px',
  overflow: 'hidden',
  '& .option': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '8px',
    fontSize: '12px',
    transition: '300ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS['neutral-400'],
      color: COLORS.white
    },
    '&.selected': {
      backgroundColor: COLORS['neutral-500'],
      color: COLORS.white
    }
  }

})
