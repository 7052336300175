import { useEffect, useRef, useMemo } from 'react'

import { players } from './helpers'

/**
 * Custom React hook to manage skipping dead time in a video player based on rally timestamps.
 * @param {boolean} disabled - Flag indicating if the hook functionality should be disabled.
 * @param {React.MutableRefObject<HTMLVideoElement>} muxPlayerRef - Ref to the mux video player element.
 * @param {Array<Object>} rallies - Array of rally objects containing start and end times.
 * @param {number} rallyNumber - Current rally number being played.
 * @param {Function} setRallyNumber - Function to update the current rally number.
 * @param {boolean} [skipDeadTime=true] - Flag indicating whether to skip dead time between rallies.
 */
export function usePlaySkipDeadTime (disabled, muxPlayerRef, rallies, rallyNumber, setRallyNumber, skipDeadTime = true) {
  const intervalId = useRef(null)

  useEffect(() => {
    if (disabled) {
      // If there is initialized interval, clear it up when disabled is set
      if (intervalId.current) {
        clearInterval(intervalId.current)
      }
      return
    }
    const extraMillisBeforeStart = 1000 // play this amount before the rally starts
    const extraMillisAfterEnd = 2000 // play for this amount beyond the rally ends
    const millisBeforeToWait = 2000 // don't jump unless more than 2sec of dead time
    let lastMillis = 0 // to track the last time checked for rewind detection

    intervalId.current = setInterval(() => {
      const muxPlayer = muxPlayerRef.current
      if (!muxPlayer || !rallies || muxPlayer.paused) {
        return
      }

      let curMillis = muxPlayer.currentTime * 1000

      // Check for rewind action. If the user rewinds into dead time,
      // take them to the previous rally instead of returning them to the current rally.

      const currentRally = rallies[rallyNumber - 1]
      if (!currentRally) {
        return
      }
      // Check does user rewind inside current rally, and when break into the dead time
      // continue to the next step to take them back to the previous rally
      if (curMillis < lastMillis && curMillis < currentRally.start_ms) { // Check if current time is less than last recorded time (rewind action)
        // After the first rally, we can go back to the previous rally
        if (rallyNumber > 1) {
          const previousRally = rallies[rallyNumber - 2]
          // Calculate current time ignoring dead time
          const newCurrentTime = previousRally.end_ms - (currentRally.start_ms - curMillis)
          muxPlayer.currentTime = newCurrentTime / 1000
          curMillis = newCurrentTime
          lastMillis = curMillis
          return
        }
      }

      lastMillis = curMillis // Update lastMillis for the next interval check

      // find the earliest rally which has not yet finished (could binary
      // search or even pre-compute this so it's much faster)
      // Note: To pre-compute, compute the rally that should be viewed at every
      //       0.1sec interval; then can just lookup in that table to see which
      //       rally we should be for any given moment (values are either null
      //       meaning don't skip, or the time to skip to).
      let nextUnfinished, newRallyNum
      for (let i = 0; i < rallies.length; i++) {
        const rally = rallies[i]
        if (curMillis <= rally.end_ms + extraMillisAfterEnd) {
          nextUnfinished = rally
          newRallyNum = i + 1
          break
        }
      }
      if (rallyNumber !== newRallyNum) {
        setRallyNumber(newRallyNum)
      }

      if (!skipDeadTime) {
        return
      }
      if (!nextUnfinished) {
        muxPlayer.pause()
        return
      }

      // if it's millisBeforeToWait or more until nextUnfinished starts, then
      // skip to it
      const millisUntilStart = nextUnfinished.start_ms - extraMillisBeforeStart - curMillis
      if (millisUntilStart >= millisBeforeToWait) {
        const newTime = (nextUnfinished.start_ms - extraMillisBeforeStart) / 1000
        muxPlayer.currentTime = newTime
      } else {
        // if we're in the middle of the nextUnfinished OR it's less than
        // millisBeforeToWait until the next rally, then just wait
      }
    }, 500) // increased interval to prevent play issues on IOS devices
    return () => {
      clearInterval(intervalId.current)
    }
  }, [disabled, muxPlayerRef, rallies, rallyNumber, setRallyNumber, skipDeadTime])
}

/**
 * Check if the filter is empty
 * @param {Object} filters - Object containing filter options.
 * @return {boolean} - True if the filter is empty, otherwise false.
 */
export const useIsFilterEmpty = (filters) => {
  return useMemo(() => {
    if (filters.characteristics.length) return false
    if (filters.customTags.length) return false
    if (filters.highlights.length) return false
    if (filters.sequences.length) return false
    if (filters.types.length) return false
    if (filters.strokeSide !== null) return false
    if (filters.quality.min !== 0 || filters.quality.max !== 1) return false
    if (filters.shotWindow.numBefore !== -1 || filters.shotWindow.numAfter !== 1) return false
    if (!filters.players.length || players.some((p) => !filters.players.includes(p))) return false
    return true
  }, [filters])
}

/**
 * Hook for handling keyboard navigation within a mux video player.
 * @param {React.MutableRefObject<HTMLVideoElement>} muxPlayerRef - Ref to the mux video player element
 */
export const useKeyNavigation = (muxPlayerRef) => {
  // Handle keydown events to change the video current time
  useEffect(() => {
    let intervalId = null

    const handleKeyDown = (event) => {
      const player = muxPlayerRef.current
      if (event.key === ' ') {
        // Skip event when in input field
        if (event.target.nodeName === 'INPUT') return
        const isVideoPlaying = !!(player.currentTime > 0 && !player.paused && !player.ended && player.readyState > 2)
        if (isVideoPlaying) {
          player.pause()
        } else {
          player.play()
        }
        event.preventDefault()
      } else if ((event.key === 'ArrowLeft' || event.key === 'ArrowRight') && !intervalId) {
        // Start interval only if it's not already running
        if (player) {
          player.pause()
          intervalId = setInterval(() => {
            switch (event.key) {
              case 'ArrowLeft':
                player.currentTime -= 1 / 30
                break
              case 'ArrowRight':
                player.currentTime += 1 / 30
                break
              default:
                break
            }
          }, 100) // Adjust position every 100ms
        }
      }
    }

    const handleKeyUp = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        clearInterval(intervalId) // Clear the interval when the key is released
        intervalId = null // Reset the interval ID
      }
    }

    // Add event listener to document to capture all key presses
    // You can also attach this to a specific component if required
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [muxPlayerRef])
}
