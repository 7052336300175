import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Link } from 'react-router-dom'

import { Button } from '@/components/button'
import { UploadButton } from '@/components/upload/UploadButton'
import { useURLParams } from '@/hooks/use-url-params'
import Container from '@/pages/purchase-done/container'
import { useLoggedInUser, useMyMonetizationData, usePrePurchaseCreditsLeft } from '@/store/auth'

/** This is used by IOS and Android after checkout. */
function NativePurchaseDonePage () {
  const { getSearchParams } = useURLParams()
  const result = getSearchParams('result')
  const error = getSearchParams('error')

  return (
    <Container>
      <NativeAppCheckoutReturnPage
        result={result}
        error={error}
      />
    </Container>
  )
}

function NativeAppCheckoutReturnPage ({ result, error }) {
  if (result === 'success') {
    return <PurchaseSuccessful />
  }

  return <PurchaseUnsuccessful error={result === 'cancelled' ? undefined : error} />
}

export function PurchaseSuccessful () {
  const user = useLoggedInUser()
  const prePurchaseCreditsLeft = usePrePurchaseCreditsLeft()
  const purchaseIsProbablyComplete = (
    prePurchaseCreditsLeft !== null &&
    user.credits.left > prePurchaseCreditsLeft)
  return (
    <>
      <CheckCircleIcon className='icon check' />
      <div className='yellow-box'>
        {purchaseIsProbablyComplete
          ? 'Your purchase has been credited to your account!'
          : 'Your purchase will be credited to your account soon. Usually this takes just several seconds.'}
      </div>
      <h2>Your purchase was successful!</h2>
      <div className='title'>You will be sent an email confirmation.</div>
      <div className='buttons'>
        <Button
          className='gray'
          variant='outlined'
          LinkComponent={Link}
          to='/library'
        >
          View your library
        </Button>
        <UploadButton className='green upload-btn' label='Upload Video' />
      </div>
    </>
  )
}

export function PurchaseUnsuccessful ({ error }) {
  const { isSubscriber } = useMyMonetizationData()

  return (
    <>
      <CancelIcon className='icon cancel' />
      <h2>Your purchase was not completed.</h2>
      <div className='title'>You have not been charged.</div>
      {error && <div className='title'>{error}</div>}
      <Button
        className='green'
        variant='outlined'
        LinkComponent={Link}
        to={isSubscriber ? '/settings/credits' : '/subscribe'}
      >
        Start Over
      </Button>
    </>
  )
}

export default NativePurchaseDonePage
