import { initializeApp } from 'firebase/app'
import * as firebaseAuth from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'

let app

/**
 * Returns the Firebase App object, creating it if needed.
 *
 * @returns {object} Firestore App object
 */
function getFirebaseApp () {
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: `${import.meta.env.VITE_PROJECT_ID}.web.app`,
    projectId: import.meta.env.VITE_PROJECT_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID
  }
  if (app === undefined) {
    app = initializeApp(firebaseConfig)
  }
  return app
}

let auth

/**
 * Returns the Firebase Auth object, creating it if needed.
 *
 * @returns {object} Firestore Auth object
 */
export function getAuth () {
  if (auth === undefined) {
    auth = firebaseAuth.getAuth(getFirebaseApp())
  }
  return auth
}

let db

/**
 * Returns the Firestore DB object, creating it if needed.
 *
 * @returns {object} Firestore DB object
 */
export function getDB () {
  if (db === undefined) {
    const app = getFirebaseApp()
    db = initializeFirestore(app, {
      experimentalForceLongPolling: true
    })
  }
  return db
}
