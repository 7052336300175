import AddIcon from '@mui/icons-material/Add'
import MuxPlayer from '@mux/mux-player-react'
import qs from 'qs'
import { useCallback, useContext, useEffect, useMemo, useRef, useState, lazy, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { useWorkflowAborted } from '../home/hooks/use-workflow-aborted'

import { Container } from './components/container'
import { FilterSideBar } from './components/filter-sidebar'
import { CurrentFilters } from './components/filters'
import { NoShotsFound } from './components/no-shots-found'
import Rallies from './components/rallies'
import { VideoPlaceholder } from './components/video-placeholder'
import { players, rallySequenceValues, shotTypeQuantity } from './helpers'
import { useIsFilterEmpty, useKeyNavigation, usePlaySkipDeadTime } from './hooks'

import { Button } from '@/components/button'
import { CircularLoadingIndicator } from '@/components/loading-indicator/CircularLoadingIndicator'
import { PlayerImage } from '@/components/player-image'
import { TitleBar } from '@/components/titlebar'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useShotsWithContextFromShotExplorerFilter } from '@/hooks/use-shots-for-shot-explorer'
import ProcessingFailed from '@/pages/home/sections/processing-failed'
import ProcessingProgress from '@/pages/home/sections/processing-progress'
import { muteVideo, usePreferMuted } from '@/store/auth'
import { sawVideo, useVideoExcerpt } from '@/store/library'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { clearFilters, setAllFilters, setShotFilters, useShotFilters } from '@/store/shot-filter'
import { APIContext } from '@/utils/api'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { formatDate } from '@/utils/helper'
import pick from '@/utils/pick'
import { normalizeFilters } from '@/utils/shot-filter'

export function PageContent () {
  const loc = useLocation()
  const { insights, video, workflow } = useContext(APIContext)
  const isMobile = useMobileDetect()
  const openSnackbar = useSnackbar()
  const dispatch = useDispatch()
  const muxPlayerRef = useRef(null)
  const params = useParams()
  const vid = params.vid
  const { videoExcerpt } = useVideoExcerpt(vid)
  const [showDrawer, setShowDrawer] = useState(false)
  const [selected, setSelected] = useState()
  const filters = useShotFilters()
  const sequenceStatsCache = useRef(null)
  const { alertWhenWorkflowIsAborted } = useWorkflowAborted(workflow, video)
  // Used for no-dead-time play hook
  const [rallyNumber, setRallyNumber] = useState(1)
  const [isPlaying, setPlaying] = useState(false)
  const playbackRate = useRef(0.5)
  const [isProcessingFailed, setIsProcessingFailed] = useState(false)
  const isVideoMuted = usePreferMuted()

  const isPosterReady = !video.noPoster
  const isVideoReadyToStream = Boolean(!video.mux?.notReady)
  const isWorkflowDone = Boolean(workflow?.epochFinished)
  const { aiEngineVersion } = workflow
  const { userData } = video
  const hasServerComputedInsights = Boolean(workflow && !workflow?.noInsights)
  const isWorkflowStarted = Boolean(workflow)

  const noFilter = useIsFilterEmpty(filters)
  const rallies = useShotsWithContextFromShotExplorerFilter(insights, filters)
  const autoSelectOnQuery = useRef(false)
  const isFullVideoForTesting = video.src.epoch >= 1717182000
  usePlaySkipDeadTime(!(isFullVideoForTesting && noFilter), muxPlayerRef, insights?.rallies, rallyNumber, setRallyNumber)
  useKeyNavigation(muxPlayerRef)

  const updateSelected = (update) => {
    if (filters.shotWindow.numBefore) {
      // When shot window before is set - use that to start playing when user clicks
      const shotStartIdx = Math.max(0, update.shotIdx + filters.shotWindow.numBefore)
      const shotStart = rallies.find((r) => r.rallyIdx === update.rallyIdx && r.shotIdx === shotStartIdx)
      // If found jump to that shot
      if (shotStart) {
        // Use shot start - 1000 or rally start if before the rally start
        const startMs = Math.max(shotStart.mStart - 1000, shotStart.rStart)
        jumpToRallyMS(startMs, true)
      } else {
        // This should never happen, but just in case
        jumpToRallyMS(update.ms - 1000, true)
      }
    } else {
      jumpToRallyMS(update.ms - 1000, true)
    }
    setSelected(update)
    setRallyNumber(update.rallyIdx)
  }

  const updateFilter = useCallback((type, value) => {
    // When filter changes (other than sequence) clear the sequence cache
    if (type !== 'sequences') sequenceStatsCache.current = null

    // Prevent shot types to have Net / Out with In (hidden filter, this shouldn't happen as they are mutually exclusive)
    const inIndex = filters.types.indexOf('in')
    if (type === 'types' && inIndex !== -1 && (value.includes('net') || value.includes('out'))) {
      value.splice(inIndex, 1)
    }
    dispatch(setShotFilters({ type, value }))
  }, [dispatch, filters.types])

  const getVideoSection = () => {
    const VideoOverlay = lazy(() => import('@/components/video-overlay'))
    const posterURL = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/poster.jpg`
    if (isVideoReadyToStream) {
      return (
        <div className='mux-container__video'>
          <MuxPlayer
            playbackId={video.mux?.playbackId}
            placeholder={posterURL}
            playbackRates={[0.25, 0.5, 1, 1.5, 2, 3]}
            accent-color={COLORS['primary-500']}
            ref={muxPlayerRef}
            forward-seek-offset='5'
            backward-seek-offset='5'
            playbackrate={playbackRate.current}
            onError={() => openSnackbar('Video loading failed, please refresh the page.', 'error')}
            muted={isVideoMuted}
          />
          <Suspense fallback={null}>
            <VideoOverlay
              muxPlayerRef={muxPlayerRef}
              rallyNumber={rallyNumber}
              vid={vid}
            />
          </Suspense>
          {alertWhenWorkflowIsAborted()}
        </div>
      )
    }
    if (isPosterReady) {
      return <img className='solo-poster' src={posterURL} />
    }
    if (!isWorkflowDone) {
      return (
        <VideoPlaceholder>
          <CircularLoadingIndicator label='This video was recently uploaded. We’re still getting your video ready to watch.' />
        </VideoPlaceholder>
      )
    }
    // If we get here, then the video isn't being processed anymore. Another
    // part of the page will describe what went wrong.
  }

  const jumpToRallyMS = (ms, pause) => {
    const muxPlayer = muxPlayerRef.current
    if (muxPlayer) {
      if (pause) {
        // Trigger the play in case player hasn't been initialized (showing the big play button)
        muxPlayer.play()
        muxPlayer.pause()
      } else if (muxPlayer.paused || muxPlayer.ended) {
        muxPlayer.play()
      }
      muxPlayer.currentTime = ms / 1000
    }
  }

  const jumpToFirstShot = useCallback((pause = true) => {
    // Find first shot that does not have isContext set to true
    const firstShot = rallies.find((shot) => !shot.isContext)
    jumpToRallyMS(rallies[0].mStart, pause)
    setRallyNumber(firstShot.rallyIdx)
    setSelected({ rallyIdx: firstShot.rallyIdx, shotIdx: firstShot.shotIdx, ms: firstShot.mStart })
  }, [rallies])

  const handleClearFilters = () => {
    dispatch(clearFilters())
  }

  function getBottomSection () {
    if (hasServerComputedInsights) {
      // if the insights data is an older version, show an error message
      if (insights?.version && !insights.version.startsWith('2.')) {
        return (
          <div>
            Insights data is version {insights.version} but we only support 2.x.
          </div>
        )
      }

      if (!insights) {
        // API to load insights data hasn't returned the data yet
        return (
          <CircularLoadingIndicator label='Loading insights...' estimatedSecsToFinish={2} />
        )
      }

      // otherwise, don't show anything as we don't have content underneath the video on SE for now
      return null
    } else {
      if (isWorkflowDone) {
        // if workflow is done but insights isn't ready, then processing failed
        !isProcessingFailed && setIsProcessingFailed(true)
        return <ProcessingFailed video={video} workflow={workflow} />
      } else if (!isWorkflowStarted) {
        // no workflow has started yet (but one should start soon, it happens a
        // few seconds after the video is uploaded)
        return (
          <CircularLoadingIndicator label='Our AI will start processing your video shortly.' estimatedSecsToFinish={5} />
        )
      }
    }
  }

  const playerAvatars = useMemo(() =>
    players.map((p) => (
      // eslint-disable-next-line react/jsx-key
      <PlayerImage className={`img player${p + 1}`} width={56} height={56} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={p} text={userData.players[p]?.name || `Player ${p + 1}`} />
    ))
  , [aiEngineVersion, userData.players, vid])

  const stats = useMemo(() => {
    const shotStats = Object.assign({}, shotTypeQuantity)
    for (let i = 0; i < rallies.length; i++) {
      const rally = rallies[i]
      // Skip isContext rallies - they are only for playback
      if (!rally.isContext) {
        if (rally.shot.shot_type) {
          shotStats[rally.shot.shot_type] += 1
        }
        if (!sequenceStatsCache.current && rally.shotSequence) {
          shotStats[rally.shotSequence] += 1
        }
        if (rally.shot.errors?.faults?.net === true) {
          shotStats.net += 1
        }
        if (rally.shot.is_final) {
          if (rally.shot.errors?.faults?.out) {
            shotStats.out += 1
          }
          shotStats.final += 1
        }
      }
    }
    if (sequenceStatsCache.current) {
      Object.assign(shotStats, sequenceStatsCache.current)
    } else if (rallies.length) {
      // cache only once rallies are initialized
      sequenceStatsCache.current = pick(shotStats, rallySequenceValues)
    }
    return shotStats
  }, [rallies])

  useEffect(() => {
    const muxPlayer = muxPlayerRef.current
    const extraMillisAfterEnd = 2000

    function stepTrough () {
      // Do not evaluate when player is paused (user clicks on a shot)
      if (muxPlayer.paused) return

      const hasBeforeWindow = Boolean(filters.shotWindow.numBefore)

      const time = muxPlayer.currentTime * 1000
      let i = rallies.length
      while (i--) {
        const m = rallies[i]
        if ((m.rallyIdx === selected?.rallyIdx && m.shotIdx < selected.shotIdx) || (selected?.shotIdx === 0 && selected.rallyIdx > m.rallyIdx)) {
          // Do not go pass (backward) the selected shot, it should remain highlighted
          break
        }
        if (time > m.mStart) {
          if (time < m.mEnd) {
            if ((!selected || !(selected.shotIdx === m.shotIdx && selected.rallyIdx === m.rallyIdx))) {
              let shotToSelect = m
              // If shot has isContext - it's in the shot window but filtered out
              if (m.isContext) {
                if (hasBeforeWindow) {
                  // find the first previous shot without this flag that is in the same rally
                  const firstPrevShot = rallies.find((shot) => shot.shotIdx > m.shotIdx && shot.rallyIdx === m.rallyIdx && !shot.isContext)
                  if (firstPrevShot) {
                    shotToSelect = firstPrevShot
                  } else {
                    // In case we didn't find next shot in the current rally it might be numAfter context
                    break
                  }
                } else {
                  // Do not select shots with isContext set to true
                  break
                }
              }
              setSelected({ rallyIdx: shotToSelect.rallyIdx, shotIdx: shotToSelect.shotIdx, ms: shotToSelect.mStart, auto: true })
              setRallyNumber(shotToSelect.rallyIdx)
            }
            // No need to iterate any more - we are inside a displayed shot time range
            break
          } else {
            if (!noFilter) {
              // past this moment - jump to the next moment in filtered array if there is a filter applied
              // otherwise playback is controlled by the hook with interval
              const nextM = rallies[i + 1]
              if (nextM) {
                if (time < nextM.mStart) {
                  jumpToRallyMS(nextM.mStart)
                }
              } else {
                // Last rally / shot finished playing
                if (time > m.mEnd + extraMillisAfterEnd) {
                  // When a “playlist” is complete restart the playing of the clips from the top.
                  jumpToFirstShot(false)
                }
              }
            }
            // Found the next moment to show, break the loop even if the next shot doesn't exist (last one)
            break
          }
        } else if (!noFilter && i === 0 && time < m.rStart) {
          // fast forward beginning of the video to the first rally start
          jumpToRallyMS(m.rStart)
        }
      }
    }

    function rememberRate () {
      playbackRate.current = muxPlayer.playbackRate
    }

    if (muxPlayer) {
      muxPlayer.addEventListener('timeupdate', stepTrough)
      muxPlayer.addEventListener('ratechange', rememberRate)
    }
    return () => {
      muxPlayer?.removeEventListener('timeupdate', stepTrough)
      muxPlayer?.removeEventListener('ratechange', rememberRate)
    }
  }, [noFilter, selected, rallies, filters.shotWindow.numBefore, jumpToFirstShot])

  useEffect(() => {
    // Track play/pause state
    const muxPlayer = muxPlayerRef.current
    const onPlay = () => setPlaying(true)
    const onPause = () => setPlaying(false)
    if (muxPlayer) {
      muxPlayer.addEventListener('play', onPlay)
      muxPlayer.addEventListener('pause', onPause)
    }
    return () => {
      muxPlayer?.removeEventListener('play', onPlay)
      muxPlayer?.removeEventListener('pause', onPause)
    }
  }, [dispatch, vid, videoExcerpt?.new])

  useEffect(() => {
    if (videoExcerpt?.new) {
      dispatch(sawVideo(vid))
    }
  }, [dispatch, vid, videoExcerpt?.new])

  useEffect(() => {
    const s = document.querySelector('.shot.selected')
    if (selected && s) {
      // Replace condition with this to have the shot placement not react on user shot click
      // if (selected?.auto) {
      const s = document.querySelector('.shot.selected')
      // .shots container
      const scrollableContainer = s.parentElement.parentElement.parentElement.parentElement.parentElement
      // Just be sure element is found
      if (scrollableContainer) {
        const sHeight = s.clientHeight
        const cHeight = scrollableContainer.offsetHeight
        // Uncomment this to scroll only when the selected item is out of the view
        // if (s.offsetTop - sHeight < c.scrollTop || c.scrollTop + cHeight + sHeight < s.offsetTop) {
        scrollableContainer.scrollTop = s.offsetTop - scrollableContainer.offsetTop - cHeight / 2 + sHeight / 2
        // }
      }
    }
  }, [selected])

  useEffect(() => {
    const queryFilters = qs.parse(loc.search.replace(/^[?, &]/, ''))
    dispatch(setAllFilters(normalizeFilters(queryFilters)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // WA-495 SE - play from filtered selection (select first filtered shot when filters are passed trough query string)
    // If there are initialized filters and query string is not empty - select first available shot
    if (rallies.length && !noFilter && loc.search && !autoSelectOnQuery.current) {
      // Set the flag to true to trigger only once during the initial rendering
      autoSelectOnQuery.current = true
      jumpToFirstShot()
    }
  }, [jumpToFirstShot, loc.search, noFilter, rallies])

  useEffect(() => {
    const updateMuteState = () => {
      const muted = muxPlayerRef.current.muted
      if (Boolean(muted) !== Boolean(isVideoMuted)) {
        // Dispatch only when muted flag changes
        dispatch(muteVideo(muted))
      }
    }
    const muxPlayer = muxPlayerRef.current
    if (muxPlayer) {
      muxPlayer.addEventListener('volumechange', updateMuteState, false)
    }
    return () => {
      if (muxPlayer) {
        muxPlayer.removeEventListener('volumechange', updateMuteState)
      }
    }
  }, [dispatch, isVideoMuted])

  return (
    <Container className={cls(isMobile && 'mobile')}>
      <div className='space-padding' />
      <div className='wrapper'>
        <TitleBar
          name={videoExcerpt?.name ?? video.userData.name}
          date={formatDate(video.userData.gameStartEpoch)}
          video={video}
          isWorkflowDone={isWorkflowDone}
        />
        {getVideoSection()}
        {workflow && !isWorkflowDone && (
          <ProcessingProgress
            vid={vid}
            workflowId={workflow.workflowId}
            workflowIndex={0}
          />
        )}
        <div className='insights-container'>
          {getBottomSection()}
        </div>
      </div>
      <aside>
        <div>
          <header>
            <div className='row'>
              <h3>Shots</h3>
              <Button variant='outlined' color='midnight' className='neutral-outline' onClick={() => setShowDrawer(true)}>
                <AddIcon />
                <em>Filter</em>
              </Button>
            </div>
            <CurrentFilters userData={userData} filters={filters} stats={stats} updateFilter={updateFilter} />
          </header>
          {rallies?.length ? (<div className='shots'><Rallies playerAvatars={playerAvatars} list={rallies} selected={selected} setSelected={updateSelected} muxPlayerRef={muxPlayerRef} isPlaying={isPlaying} /></div>) : (<NoShotsFound clear={handleClearFilters} />)}
        </div>
      </aside>
      <FilterSideBar video={video} vid={vid} aiEngineVersion={aiEngineVersion} userData={userData} filters={filters} stats={stats} handleClearFilters={handleClearFilters} updateFilter={updateFilter} showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    </Container>
  )
}
