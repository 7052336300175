import CreditCard from '@mui/icons-material/CreditCard'
import { styled } from '@mui/material'
import React from 'react'

import { row } from '@/utils/flexGrid'

export default function Credits ({ children }) {
  return (
    <Container>
      <CreditCard />
      <p>{`${children}`}</p>
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  alignItems: 'center',
  gap: 5,
  marginRight: 5,
  '& p': {
    fontSize: 'inherit',
    color: 'inherit'
  }
})
