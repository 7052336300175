import erfc from 'math-erfc'

/**
 * Calculates the percentile rank of a median serve speed in pickleball.
 *
 * This function takes the median speed of a serve and calculates the percentile
 * rank using the complementary error function (erfc).
 *
 * @param {number} medianSpeed - The median speed of a serve to calculate the percentile for.
 * @param {string} shotType - One of 'serve' or 'drive' (defaults to serve)
 * @returns {number} The percentile rank, rounded to the nearest whole number.
 */
export function calculatePercentile (medianSpeed, shotType = 'serve') {
  // 1 std interval speed: [25... 35] mph, so stdDev = (35 - 25) / 2 = 5 - for serves
  // 1 std interval speed: [20... 30] mph, so stdDev = (30 - 20) / 2 = 5 - for drives
  const stdDev = 5
  // Average (50th percentile) = 30 or 25 mph (serves/drives)
  const mean = shotType === 'serve' ? 30 : 25

  const percentile = Math.max(0, Math.min(1, 0.5 * erfc((mean - medianSpeed) / (Math.sqrt(2) * stdDev)))) * 100
  return Math.round(percentile)
}
