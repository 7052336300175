import MuiSlider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import tinygradient from 'tinygradient'
import { useDebounceCallback } from 'usehooks-ts'

import COLORS from '@/utils/colors'

const steps = [
  { color: '#11EB05', pos: 0 },
  { color: '#6EE821', pos: 0.1093 },
  { color: '#98DE18', pos: 0.2175 },
  { color: '#C3D50E', pos: 0.3274 },
  { color: '#EACC05', pos: 0.43729999999999997 },
  { color: '#FFB705', pos: 0.5472 },
  { color: '#FF970E', pos: 0.6554000000000001 },
  { color: '#FF7718', pos: 0.7653 },
  { color: '#FF5421', pos: 0.8752 },
  { color: '#FF2C2C', pos: 1 }
]
const gradient = tinygradient(steps)

const Slider = styled(MuiSlider)({
  '& .MuiSlider-rail': {
    background: gradient.css('linear', 'to left')
  },
  '.MuiSlider-track': {
    left: '0 !important',
    width: '100% !important',
    border: 'none !important',
    background: gradient.css('linear', 'to left')
  },
  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    border: `2px solid ${COLORS['neutral-200']}`,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    },
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'none'
    }
  }
})

const calculateStyle = ([val1, val2]) => ({
  '& .MuiSlider-track': {
    clipPath: `inset(0 ${100 - val2 * 100}% 0 ${val1 * 100}%)`
  },
  '& .MuiSlider-thumb[data-index="0"]': {
    background: gradient.rgbAt((100 - val1 * 100) / 100).toHexString()
  },
  '& .MuiSlider-thumb[data-index="1"]': {
    background: gradient.rgbAt((100 - val2 * 100) / 100).toHexString()
  }
})

export function RangeSlider (props) {
  const { min = 0, max = 1, minRange = max / 10, start = 0, end = max, step = null, marks } = props
  const [value, setValue] = useState([start, end])

  const onChange = useDebounceCallback(props.onChange, 300)
  const handleChange = (_evt, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minRange) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], max - minRange)
        setValue([clamped, clamped + minRange])
        onChange([clamped, clamped + minRange])
      } else {
        const clamped = Math.max(newValue[1], minRange)
        setValue([clamped - minRange, clamped])
        onChange([clamped - minRange, clamped])
      }
    } else {
      setValue(newValue)
      onChange(newValue)
    }
  }

  useEffect(() => {
    if (props.start !== value[0] || props.end !== value[1]) {
      setValue([props.start, props.end])
    }
    // Local state is loaded only initially - do not overwrite it later on as we are using debounced callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Slider
      min={min}
      max={max}
      getAriaLabel={() => 'Quality'}
      value={value}
      onChange={handleChange}
      valueLabelDisplay='off'
      sx={calculateStyle(value)}
      step={step}
      marks={marks}
      disableSwap
    />
  )
}
