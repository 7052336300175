import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import { styled } from '@mui/material/styles'
import * as DOMPurify from 'dompurify'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { TextButton } from '@/components/button/text-button'
import useKeypress from '@/hooks/use-keypress'
import useOnClickOutside from '@/hooks/use-on-click-outside'
import { showBanner } from '@/store/controls'
import { BANNER_KEYS } from '@/utils/banners'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'

/**
 * InlineEdit container
 *
 * @returns {React.ReactElement}
 */
const Container = styled('span')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,

  '& button': {
    textAlign: 'left',
    borderRadius: '0px',
    flexGrow: 1,
    display: 'flex',
    gap: '10px'
  },
  '& .text': {
    fontSize: 17,
    fontWeight: 600,
    width: '100% !important',
    lineHeight: 2,
    color: COLORS['neutral-800']
  },
  '& input': {
    flexGrow: 1,
    fontSize: 16,
    minWidth: '130px',
    fontWeight: 400,
    color: COLORS['neutral-800'],
    letterSpacing: 1,
    padding: '6px 12px',
    background: COLORS['neutral-050'],
    border: `2px solid ${COLORS['neutral-400']}`,
    borderRadius: 4,
    outline: 'none',
    display: 'none'
  },
  '& .edit-ico': {
    fontSize: 22
  },
  '& .check-ico': {
    fontSize: 22,
    cursor: 'pointer'
  },
  '&.editing': {
    '& button': {
      cursor: 'inherit'
    },
    '& span': {
      display: 'none',
      cursor: 'inherit'
    },
    '& input': {
      display: 'inline-block',
      cursor: 'inherit'
    }
  }
})

/**
 * InlineEdit component - vertical or horizontal with single or two values and configurable colors
 *
 * @exports
 * @param props {object} {text, onChange, isEditing, sx, mayEdit, buttonLeft}
 * @returns {React.ReactElement}
 */
export function InlineEdit (props) {
  const { buttonLeft, text, onChange, sx } = props
  const [inputValue, setInputValue] = useState(text)
  const [isEditing, setIsEditingInState] = useState(false)
  const dispatch = useDispatch()

  const setIsEditing = useCallback(newValue => {
    if (newValue && !props.mayEdit) {
      // prompt user to create account so they can unlock editing
      dispatch(showBanner(BANNER_KEYS.LOGIN_TO_FINISH_ACTION))
    } else {
      setIsEditingInState(newValue)
    }
  }, [dispatch, props.mayEdit])

  const inputRef = useRef(null)
  const textRef = useRef(null)

  const onLabelClick = () => {
    if (!isEditing) {
      setIsEditing(true)
    }
  }

  // When user clicks outside the edit box - save and exit inline-edit
  useOnClickOutside(inputRef, () => {
    if (isEditing) {
      setIsEditing(false)
      onChange({ text: inputValue })
    }
  })

  // When user presses Enter key - save and exit
  useKeypress('Enter', () => {
    if (isEditing) {
      setIsEditing(false)
      onChange({ text: inputValue })
    }
  })

  // Escape key cancels the editing and restores the original value
  useKeypress('Escape', () => {
    if (isEditing) {
      setInputValue(text)
      setIsEditing(false)
    }
  })

  // Get the width of the available space
  useEffect(() => {
    if (textRef.current && inputRef.current) {
      const width = `${textRef.current.getBoundingClientRect().width}px`
      // Make the input same width as the text
      inputRef.current.style.width = width
      textRef.current.style.width = width
    }
  }, [])

  // When text changes - update the local state
  useEffect(() => {
    setInputValue(text)
  }, [text])

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isEditing) {
      inputRef.current.select()
      inputRef.current.focus()
    }
  }, [isEditing])

  const handleInputChange = (event) => {
    // sanitize the input a little (XSS)
    setInputValue(DOMPurify.default.sanitize(event.target.value))
  }

  return (
    <Container className={cls(isEditing && 'editing')} sx={sx}>
      <TextButton onClick={onLabelClick}>
        {buttonLeft &&
          (isEditing ? <CheckIcon className='check-ico' data-left /> : <EditIcon className='edit-ico' data-left />)}
        <span className='text' ref={textRef}>
          {text}
        </span>
        <input ref={inputRef} value={inputValue} onChange={handleInputChange} />
        {!buttonLeft &&
          (isEditing ? <CheckIcon className='check-ico' data-right /> : <EditIcon className='edit-ico' data-right />)}
      </TextButton>
    </Container>
  )
}
