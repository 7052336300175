import omit from './omit'

/**
 * Rounds decimal number to integer
 *
 * @exports
 * @param {number} num Decimal number (defaults to 0 if falsely)
 * @returns {number} Rounded number
 */
export function round (num = 0) {
  return Math.round(Number(num))
}

/**
 * @exports
 * @param {number} base64 encoded Int8Array
 * @returns {number} Rounded number
 */
export function base64ToArrayBuffer (base64) {
  const binaryString = atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

const defaults = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
}

export const formatWithTimeConfig = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  weekday: 'short',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
}

/**
 * @exports
 * @param {Date | string | number} input Date object or unix epoch number/string
 * @param {object} options An object adjusting the output format. Corresponds to the options parameter of the Intl.DateTimeFormat() constructor
 * @param {string} lng BCP 47 language tag - defaults to 'en-US'
 * @param {boolean} skipThisYear Show year only if its not the current one
 * @returns {string} Formatted date string
 */
export function formatDate (input, options = defaults, lng = 'en-US', skipThisYear = false) {
  if (!input) return null
  const date = input instanceof Date ? input : new Date(Number(`${input}`.length === 10 ? `${input}000` : input))
  const opts = skipThisYear && new Date().getFullYear() === date.getFullYear() ? omit(options, 'year') : options
  return date.toLocaleDateString(lng, opts)
}

/**
 * Copies a given text value to the clipboard.
 *
 * @param {string} value The text value to be copied to the clipboard.
 * @returns {Promise<void>} A promise that resolves when the text has been copied to the clipboard.
 *
 * @example
 * copyToClipboard('Sample text to copy')
 *   .then(() => console.warn('Text copied successfully'))
 *   .catch((err) => console.error('Failed to copy text', err));
 */
export async function copyToClipboard (value) {
  await navigator.clipboard.writeText(value)
}

export function pluralize (word, number) {
  return number > 1 ? `${word}s` : word
}
