import { useEffect } from 'react'

function VideoTipsRedirect () {
  useEffect(() => {
    window.location.href = 'https://help.pb.vision/articles/1108176'
  }, [])
}

export default [
  {
    path: '/video-tips',
    element: <VideoTipsRedirect />
  }
]
