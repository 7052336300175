import { useState, useEffect } from 'react'

const mediaQueries = {
  xs: '(max-width: 575px)',
  sm: '(max-width: 767px)',
  md: '(max-width: 991px)',
  lg: '(max-width: 1199px)',
  xl: '(max-width: 1399px)',
  xxl: '(min-width: 1400px)',
  phone: '(min-width: 280px) and (max-width: 568px)',
  tablet: '(min-width : 768px) and (max-width : 1024px)',
  desktop: '(min-width : 1224px)'
}

export default (query) => {
  if (typeof window !== 'object') return
  if (!window.matchMedia) return

  const queryToMatch = query ? mediaQueries[query] || query : mediaQueries.phone
  const [matches, setMatches] = useState(window.matchMedia(queryToMatch).matches)

  useEffect(() => {
    const media = window.matchMedia(queryToMatch)

    if (media.matches !== matches) setMatches(media.matches)

    const listener = () => setMatches(media.matches)

    if (media.addEventListener) {
      media.addEventListener('change', listener)
    } else if (media.addListener) {
      // Support for older Safari browsers
      media.addListener(listener)
    }

    // Cleanup - remove event listeners
    return () =>
      // eslint-disable-next-line no-nested-ternary
      media.removeEventListener
        ? media.removeEventListener('change', listener)
        : media.removeListener
          ? media.removeListener(listener)
          : undefined
  }, [matches, queryToMatch])

  // eslint-disable-next-line consistent-return
  return matches
}
