import { useDispatch, useSelector } from 'react-redux'

import { useIsLoggedIn, useMyMonetizationData } from '@/store/auth'
import { showPopup } from '@/store/controls'
import { POPUP_KEYS } from '@/utils/popups'

export function useCanUploadVideo () {
  const isLoggedIn = useIsLoggedIn()
  const dispatch = useDispatch()
  const myMonetizationData = useMyMonetizationData()
  const alreadyUploadedAsAnonymous = useSelector(x => x.anonymous.uploadedVids)

  function canUploadNewVideo () {
    if (isLoggedIn) {
      // The user can upload if they have remaining credits and one of the following conditions is met:
      // 1. The user is a subscriber.
      // 2. The user is currently within the free trial period and the trial has not expired
      if ((myMonetizationData.isSubscriber || !myMonetizationData.isFreeTrialExpired) && myMonetizationData.creditsLeft > 0) {
        return true
      }
      return false
    } else {
      // anonymous users can only upload one video
      return alreadyUploadedAsAnonymous.length < 1
    }
  }

  function alertUserForForbiddenUpload () {
    if (isLoggedIn) {
      if (myMonetizationData.currentSubscription) {
        dispatch(showPopup(POPUP_KEYS.SUBSCRIBED_OUT_OF_CREDIT))
      } else {
        dispatch(showPopup(POPUP_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT))
      }
    } else {
      dispatch(showPopup(POPUP_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT))
    }
  }

  return { canUploadNewVideo, alertUserForForbiddenUpload }
}
