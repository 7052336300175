import { useCallback } from 'react'

import { displayItemsByFolder } from '../display-items-by-folder'

/**
 * Checks if an item is already included in the array based on `vid` or `fid`.
 * @param {Array<Object>} arr - The array to search.
 * @param {Object} item - The item to check for, containing either `vid` or `fid`.
 * @returns {boolean} - Returns `true` if the item exists, otherwise `false`.
 */
const includesThumb = (arr, item) => arr.some(existing => item.vid ? existing.vid === item.vid : existing.fid === item.fid)

/**
 * Gets the index of an item in an array based on `vid` or `fid`.
 * @param {Array<Object>} arr - The array to search.
 * @param {Object} item - The item to find.
 * @returns {number} - The index of the item in the array, or -1 if not found.
 */
const getIndex = (arr, item) => arr.findIndex((s) => s.vid ? s.vid === item.vid : s.fid === item.fid)

/**
 * Retrieves a subarray from an array between two indices (inclusive).
 * @param {Array<Object>} arr - The array to select from.
 * @param {number} start - The starting index.
 * @param {number} stop - The stopping index.
 * @returns {Array<Object>} - The subarray of elements between the start and stop indices.
 */
const getBetween = (arr, start, stop) => {
  const selection = []
  for (; start <= stop; start++) {
    const thumb = arr[start]
    selection.push(thumb)
  }
  return selection
}

/**
 * Concatenates unique items from an update array into the original array.
 * @param {Array<Object>} arr - The original array.
 * @param {Array<Object>} update - The array of items to add.
 * @returns {Array<Object>} - The concatenated array with unique items.
 */
const concatUnique = (arr, update) => {
  const unique = [...arr]
  update.forEach((item) => {
    if (!includesThumb(arr, item)) unique.push(item)
  })
  return unique
}

/**
 * Finds all items between the last clicked item and the currently selected item in the array.
 * If the current item is already selected, unselects the group.
 * @param {Array<Object>} arr - The array of all items.
 * @param {Array<Object>} selectedArr - The array of selected items.
 * @param {Object} item - The currently selected item.
 * @param {Object} lastClicked - The last clicked item.
 * @returns {Array<Object>} - The updated array of selected items.
 */
const findAllPrev = (arr, selectedArr, item, lastClicked) => {
  // Select or unselect (if item is already selected - we need to unselect group)
  const unselectBetween = includesThumb(selectedArr, item)

  // First find direction of selection and start/stop index to iterate
  const lastClickedIndex = getIndex(arr, lastClicked)
  const itemIndex = getIndex(arr, item)
  const selectToRight = lastClickedIndex > itemIndex
  const stopAt = selectToRight ? lastClickedIndex : itemIndex
  const startAt = selectToRight ? itemIndex : lastClickedIndex

  // Get array of elements between start/stop indexes
  const selection = getBetween(arr, startAt, stopAt)
  if (unselectBetween) {
    return selectedArr.filter((thumb) => !includesThumb(selection, thumb))
  }
  // Concatenate new selection to the existing one
  return concatUnique(selectedArr, selection)
}

/**
 * Hook-like function for handling bulk selection logic.
 * @param {Array<Object>} [selectedArray=[]] - The array of selected items.
 * @param {Array<Object>} [all=[]] - The array of all items.
 * @returns {Object} - An object with selection-related methods.
 * @returns {Function} toggleItemSelection - Toggles selection of an item.
 * @returns {Function} isItemSelected - Checks if an item is selected.
 * @returns {Function} selectToCurrent - Selects items from the last clicked item to the current one.
 */
export const useBulkSelect = (selectedArray = [], all = []) => {
  /**
   * Checks if an item is selected based on `vid` or `fid`.
   * @param {Object} item - The item to check.
   * @returns {boolean} - Returns `true` if the item is selected, otherwise `false`.
   */
  const isItemSelected = useCallback((item) => {
    return item?.vid
      ? selectedArray.some(existing => existing.vid === item.vid)
      : selectedArray.some(existing => existing.fid === item.fid)
  }, [selectedArray])

  /**
   * Toggles the selection status of an item. If the item is selected, unselects it, otherwise selects it.
   * @param {Object} item - The item to toggle.
   * @returns {Array<Object>} - The updated array of selected items.
   */
  const toggleItemSelection = useCallback((item) => {
    if (isItemSelected(item)) {
      return item?.vid
        ? selectedArray.filter(existing => existing.vid !== item.vid)
        : selectedArray.filter(existing => existing.fid !== item.fid)
    } else {
      return [...selectedArray, item]
    }
  }, [selectedArray, isItemSelected])

  /**
   * Selects items from the last clicked item to the current one, based on sorted order.
   * @param {Object} item - The currently selected item.
   * @param {Object} lastClicked - The last clicked item.
   * @returns {Array<Object>} - The updated array of selected items.
   */
  const selectToCurrent = (item, lastClicked, fid) => {
    const filteredAndSorted = displayItemsByFolder(all, fid)
    return findAllPrev(filteredAndSorted, selectedArray, item, lastClicked)
  }

  return { toggleItemSelection, isItemSelected, selectToCurrent }
}
