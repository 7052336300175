import { useSearchParams } from 'react-router-dom'

export function useURLParams () {
  const [searchParams, setSearchParams] = useSearchParams()

  function nestParamsIntoURL (baseURL) {
    let shareURL = baseURL
    const parameters = []

    // Check if startTime - 't' parameter exists
    const time = searchParams.get('t')
    if (time) {
      parameters.push(`t=${time}`)
    }

    // Check if duration -  'l' parameter exists
    const duration = searchParams.get('l')
    if (duration) {
      parameters.push(`l=${duration}`)
    }

    // Check if play speed - 'speed' parameter exists
    const speed = searchParams.get('speed')
    if (speed) {
      parameters.push(`speed=${speed}`)
    }

    // Join all parameters with '&' and append to URL
    if (parameters.length > 0) {
      shareURL += '?' + parameters.join('&')
    }

    return shareURL
  }

  function nestCustomParamsIntoURL (baseURL, paramsArray) {
    let shareURL = baseURL
    const parameters = []

    paramsArray.forEach(param => {
      parameters.push(`${param.key}=${param.value}`)
    })

    // Join all parameters with '&' and append to URL
    if (parameters.length > 0) {
      shareURL += '?' + parameters.join('&')
    }

    return shareURL
  }

  function updateSearchParams (key, value) {
    setSearchParams(
      (params) => {
        if (params.has(key)) {
          if (key === 'speed' && Number(value) === 1) {
            params.delete(key)
          } else {
            params.set(key, value)
          }
        } else {
          params.append(key, value)
        }

        return params
      },
      { replace: true }
    )
  }

  function removeSearchParams (key) {
    setSearchParams(
      (params) => {
        if (params.has(key)) {
          params.delete(key)
        }
        return params
      },
      { replace: true }
    )
  }

  function getSearchParams (key) {
    return searchParams.get(key)
  }

  return {
    nestParamsIntoURL,
    updateSearchParams,
    nestCustomParamsIntoURL,
    removeSearchParams,
    getSearchParams
  }
}
