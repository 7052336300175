import { Dialog, styled } from '@mui/material'
import React, { useMemo } from 'react'

import { SimpleButton } from '../button/simple-button'

import GreenCheckIcon from '@/assets/icons/signs/check-rounded.svg?react'
import AlertIcon from '@/assets/icons/signs/exclamation-rounded.svg?react'
import InfoIcon from '@/assets/icons/signs/exclamation-triangle.svg?react'
import ExitIcon from '@/assets/icons/signs/x-clean.svg?react'
import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function PopupAlert (props) {
  const isDesktop = useMobileDetect('desktop')
  const Icon = useMemo(() => {
    if (props.type === 'success') return GreenCheckIcon
    if (props.type === 'warning') return AlertIcon
    if (props.type === 'info') return InfoIcon

    return InfoIcon
  }, [props.type])

  return (
    <Dialog open onClose={() => props.onRemove(props.id)}>
      <Container className={cls(!isDesktop ? 'mobile' : '', props.type)}>
        <div className='header'>
          <Icon className='sign' />
          <h4 className='title'>{props.title}</h4>
          <SimpleButton className='close' onClick={() => props.onRemove(props.id)}>
            <ExitIcon />
          </SimpleButton>
        </div>
        <p className={cls('body-md', 'message')} style={props.messageStyle}>{props.message}</p>
        <div className='actions'>
          {props.actions}
        </div>
      </Container>
    </Dialog>
  )
}

const Container = styled('div')({
  ...column,
  width: 580,
  padding: 16,
  borderRadius: '12px',
  gap: 8,
  border: `1px solid ${COLORS['warning-200']}`,
  background: COLORS['warning-050'],
  fontSize: 14,
  '& .header': {
    ...row,
    width: '100%',
    alignItems: 'center',
    '& .title': {
      color: COLORS['warning-800']
    },
    '& .sign': {
      width: 24,
      minWidth: 24,
      marginRight: 8,
      path: {
        fill: COLORS['warning-500']
      }
    },
    '& .close': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
      padding: 8,
      border: `1px solid ${COLORS['warning-200']}`,
      borderRadius: 8,
      cursor: 'pointer',
      '& svg path': {
        fill: COLORS['warning-800']
      },
      '&:hover': {
        backgroundColor: `${COLORS['neutral-200']}70`,
        transition: 'background-color 0.3s'
      }
    }
  },
  '& .message': {
    color: COLORS['neutral-700'],
    maxWidth: '90%'
  },
  '& .actions': {
    marginTop: '16px'
  },
  '&.mobile': {
    width: '100%',
    '& h4': {
      lineHeight: '24px'
    }
  },
  // styles based on props.type
  '&.warning': {
    borderColor: COLORS['danger-200'],
    background: COLORS['danger-050'],
    '& .sign path': {
      fill: COLORS['danger-500']
    },
    '& .message, .title': {
      color: COLORS['danger-500']
    },
    '& .close': {
      borderColor: COLORS['danger-200'],
      '& svg path': {
        fill: COLORS['danger-500']
      }
    }
  },
  '&.success': {
    borderColor: COLORS['success-300'],
    background: COLORS['success-050'],
    '& .sign path': {
      fill: COLORS['success-800']
    },
    '& .message, .title': {
      color: COLORS['success-800']
    },
    '& .close': {
      borderColor: COLORS['success-300'],
      '& svg path': {
        fill: COLORS['success-800']
      }
    }
  }
})
