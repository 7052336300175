/* eslint-disable react/no-unescaped-entities */
import { styled } from '@mui/material'
import { useDispatch } from 'react-redux'

import { Button } from '@/components/button'
import { showBanner, showPopup } from '@/store/controls'
import { BANNER_KEYS } from '@/utils/banners'
import { column } from '@/utils/flexGrid'
import { POPUP_KEYS } from '@/utils/popups'

const SingleSection = (props) => {
  return (
    <div className='section'>
      <h3>{props.title}</h3>
      {props.children}
    </div>
  )
}

export default function TestingPage () {
  const dispatch = useDispatch()
  return (
    <Container>
      <SingleSection title='Banners'>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT))}>When an anonymous user tries to upload more than one video</Button>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.SUBSCRIBED_OUT_OF_CREDIT))}>When a logged-in user runs out of VC (with an active subscription)</Button>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT))}>When a logged-in user runs out of VC (without an active subscription)</Button>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL))}>When you want to upgrade before free trial ends</Button>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.FREE_TRIAL_EXPIRED))}>When free trial is expired</Button>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.UNSUPPORTED_VIDEO_FORMAT))}>Unsupported video format</Button>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.DEV_ENV))}>DEV ENV banner</Button>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.NOT_YOUR_VIDEO))}>When a user tries to edit someone else's video</Button>
        <Button className='gray' onClick={() => dispatch(showBanner(BANNER_KEYS.LOGIN_TO_FINISH_ACTION))}>When anonymous user try to edit</Button>
      </SingleSection>
      <SingleSection title='Popups'>
        <Button className='gray' onClick={() => dispatch(showPopup(POPUP_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT))}>When an anonymous user tries to upload more than one video</Button>
        <Button className='gray' onClick={() => dispatch(showPopup(POPUP_KEYS.SUBSCRIBED_OUT_OF_CREDIT))}>When a logged-in user runs out of VC (with an active subscription)</Button>
        <Button className='gray' onClick={() => dispatch(showPopup(POPUP_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT))}>When a logged-in user runs out of VC (without an active subscription)</Button>
      </SingleSection>
      <SingleSection title='Something else'>
        ...
      </SingleSection>
    </Container>
  )
}

export const Container = styled('div')({
  ...column,
  gap: 30,
  flex: 1,
  '& .section': {
    ...column,
    gap: 10,
    alignItems: 'flex-start',
    '&:nth-child(2n)': {
      backgroundColor: '#dad9d999'
    },
    '& h3': {
      marginBottom: 10
    },
    border: '1px solid gray',
    padding: 10,
    color: 'black'
  }
})
