/* eslint-disable no-param-reassign */
import { useEffect, useState, useRef } from 'react'

/**
 * Calculates which sticky element is out of the viewport stack to the top of the container element.
 *
 * @typedef {number | null} BookmarkIndex - Current sticky bookmark item index or null
 * @typedef {number} Width - Width of the bookmark element
 * @typedef {[BookmarkIndex, Width]} ReturnValue
 * @param {React.MutableRefObject<HTMLElement>} container - Scrollable container element
 * @returns {ReturnValue} - Array with index of the current bookmark or null if none is pass the viewport and width of the bookmark element
 */
export const useStickyScroll = (container) => {
  const [stickyElementIndex, setStickyElementIndex] = useState(null)
  const widthRef = useRef(0)

  function calculateTopOffset (element) {
    // 69 is the height of the sticky header element
    return element.offsetTop - 69
  }

  /** @type {React.MutableRefObject<object>} */
  useEffect(() => {
    const handleContainerScroll = () => {
      const currentScroll = container.scrollTop
      const bookmarkElements = [...container.querySelectorAll('.bookmark')]
      let stickyIndex = null
      if (bookmarkElements && bookmarkElements.length > 0) {
        if (currentScroll >= calculateTopOffset(bookmarkElements[3])) {
          stickyIndex = 3
        } else if (currentScroll >= calculateTopOffset(bookmarkElements[2])) {
          stickyIndex = 2
        } else if (currentScroll >= calculateTopOffset(bookmarkElements[1])) {
          stickyIndex = 1
        } else if (currentScroll >= calculateTopOffset(bookmarkElements[0])) {
          stickyIndex = 0
        }
        widthRef.current = bookmarkElements[0].clientWidth
        setStickyElementIndex(stickyIndex)
      }
    }

    container?.addEventListener('scroll', handleContainerScroll)
    // Trigger initially
    handleContainerScroll()

    // eslint-disable-next-line consistent-return
    return () => {
      container?.removeEventListener('scroll', handleContainerScroll)
    }
  }, [container])
  return [stickyElementIndex, widthRef.current]
}
