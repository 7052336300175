import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DEMO_VIDEOS } from '../anonymous-upload'

import Page from './index'

import { assert } from '@/utils'

function Redirect ({ vid }) {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(`/video/${vid}`, { replace: true })
  }, [navigate, vid])
}

export default [
  {
    path: '/video/:vid/:tab?/:playerId?',
    element: <Page />
  }
].concat(
  // special links for demo videos
  ['demo'].map((kind) => {
    for (const demo of DEMO_VIDEOS) {
      if (demo.name.toLowerCase() === kind) {
        return {
          path: `/video/${kind}`,
          element: <Redirect vid={demo.vid} />
        }
      }
    }
    assert.fail('missing demo video ' + kind)
    return null // make eslint happy
  })
)
