import { nestedPathsToProxy, singlePathsToProxy } from './paths'

import WebflowProxy from './index'

export default [
  ...singlePathsToProxy.map(path => ({
    path,
    element: <WebflowProxy />
  })),
  ...nestedPathsToProxy.map(path => ({
    path: path + '/*',
    element: <WebflowProxy />
  }))
]
