import { styled } from '@mui/material'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { PlayerStatsContext } from '..'

import { Avatar } from '@/components/avatar'
import { PlayerImage } from '@/components/player-image'
import { Spacer } from '@/components/spacer'
import { TagPlayer } from '@/components/tag-player'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useStickyScroll } from '@/hooks/use-sticky'
import COLORS from '@/utils/colors'

export default function StickyHeader (props) {
  const { vid } = useParams()
  const context = useContext(PlayerStatsContext)
  const workflow = context?.workflow
  const video = context?.video
  const userData = video?.userData
  const isMobile = useMobileDetect()

  const partner = [2, 1, 4, 3] // Partner color mapping

  const aiEngineVersion = workflow?.aiEngineVersion
  const [stickyIndex, stickyWidth] = useStickyScroll(document.getElementById('pbvision'))

  if (stickyIndex === null || !userData?.players) {
    return null
  }

  const players = [...userData.players]

  return (
    <Container className={`row sticky-summary ${isMobile && 'mobile'}`} style={{ width: `${stickyWidth}px` }}>
      <div className='row flex-grow'>
        <PlayerImage
          className={`sticky-player-image player${stickyIndex + 1}`}
          vid={vid}
          aiEngineVersion={aiEngineVersion}
          playerIdx={stickyIndex}
          text={players[stickyIndex]?.name || `Player ${stickyIndex + 1}`}
          onChange={props.onRenamePlayer(stickyIndex)}
          scale={0.5}
          numberOfImages={1}
          imageWidth={100}
        />
        <TagPlayer
          vid={vid}
          playerIdx={stickyIndex}
          playerName={players[stickyIndex]?.name}
          aiEngineVersion={aiEngineVersion}
          sx={{ minWidth: '160px', gap: '8px' }}
        >
          <Avatar className={`player${stickyIndex + 1}`} width='25' initials={players[stickyIndex]?.name} round={stickyIndex < 2} />
        </TagPlayer>
        <Spacer />
        <span className='player-name'>Partner:&nbsp;</span>

        <PlayerImage
          className={`sticky-player-image player${partner[stickyIndex]}`}
          vid={vid}
          aiEngineVersion={aiEngineVersion}
          playerIdx={partner[stickyIndex] - 1}
          text={players[partner[stickyIndex] - 1]?.name || `Player ${partner[stickyIndex]}`}
          onChange={props.onRenamePlayer(partner[stickyIndex] - 1)}
          scale={0.25}
          numberOfImages={1}
          imageWidth={100}
        />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  position: 'fixed',
  zIndex: 10,
  top: 0,

  '& .row': {
    flexGrow: 1,
    background: COLORS['neutral-200'],
    color: COLORS['neutral-800'],
    border: '1px solid #CDD7E1',
    overflow: 'hidden',
    padding: '4px 8px',
    fontSize: 20,
    fontWeight: 600,
    alignItems: 'center',
    zIndex: -1,
    '& .sticky-player-image': {
      height: 62,
      width: 62,
      borderWidth: 3
    },
    '& .sticky-player-image:first-of-type': {
      marginRight: 8
    },
    // Adjust padding for inline-edit (prevent jumps due to height difference) and allow width to expand
    '& button': {
      flexGrow: 1,
      justifyContent: 'flex-start',

      '& input': {
        padding: '7px 12px',
        width: 'unset !important'
      },
      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'unset !important'
      }
    },
    '& .player-name': {
      fontSize: 16
    }
  },
  '&.mobile': {
    width: '100%',
    '& .sticky-player-image': {
      height: '40px !important',
      width: '40px !important'
    }
  }
})
