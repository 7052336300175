import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'
/**
 * UserImageEditor container
 *
 * @returns {React.ReactElement}
 */
export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '8px',
  '& .buttons-container': {
    position: 'relative',
    '& .edit-button': {
      minWidth: 'unset'
    },
    '& .remove-button': {
      padding: '2px 6px',
      minHeight: 'unset',
      fontSize: '11px',
      color: COLORS['neutral-500'],
      position: 'absolute',
      top: '38px',
      left: '-4px',
      minWidth: 'unset',
      whiteSpace: 'nowrap'
    }
  }
})
