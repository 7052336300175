import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { CircularLoadingIndicator } from '@/components/loading-indicator/CircularLoadingIndicator'

export default function WebflowProxy () {
  const iframeRef = useRef(null)
  const location = useLocation()
  const { pathname, search } = location
  const pathAndQuery = `${pathname}${search ?? ''}`
  const [error, setError] = useState()
  const [iframeHTML, setIframeHTML] = useState()

  useEffect(() => {
    const mainDiv = document.querySelector('.main')
    mainDiv.classList.add('webflow')
    return () => {
      mainDiv.classList.remove('webflow')
    }
  }, [])

  async function fetchWebflow (path) {
    setIframeHTML()
    setError()
    try {
      const resp = await window.fetch(`${import.meta.env.VITE_API_SERVER}/webflow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ path })
      })
      if (resp.status !== 200) {
        setError(`API Failed: HTTP Error ${resp.status}`)
        return
      }
      const proxiedResp = await resp.json()
      if (proxiedResp.code !== 200) {
        setError(`Site Failed: HTTP Error ${proxiedResp.code}`)
        return
      }
      let newHTML = proxiedResp.body.replace(
        '</head>', HIDE_WEBFLOW_TAGS + '</head>')
      // Must eagerly load all elements in order to set fixed iframe height to avoid vertical scrollbars
      newHTML = newHTML.replaceAll('loading="lazy"', 'loading="eager"')

      setIframeHTML(newHTML)
    } catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    fetchWebflow(pathAndQuery)
  }, [pathAndQuery])

  const onIframeLoad = () => {
    const iframe = iframeRef.current
    const { contentWindow: iframeWindow, contentDocument: iframeDocument } = iframe
    const getIframeURL = () => iframeWindow.location.href
    let previousURL = pathAndQuery

    if (iframe && iframeWindow) {
      try {
        // footer can't be hidden using css only
        const sections = iframeDocument.querySelectorAll('div.section')
        const footer = sections[sections.length - 1]
        footer.remove()

        const height = iframeDocument.documentElement.scrollHeight
        iframe.style.height = `${height + 50}px`

        pollIframeUrlChange()
      } catch (error) {
        console.error('Error accessing iframe content:', error)
      }
    }

    // On iframe URL change, reload the main page in order to avoid recursive iframe loading
    function pollIframeUrlChange () {
      setInterval(() => {
        const currentURL = getIframeURL()
        if (currentURL !== previousURL) {
          previousURL = currentURL
          if (previousURL !== 'about:srcdoc') {
            window.location.href = currentURL
          }
        }
      }, 500)
    }
  }
  if (error) {
    return <div style={{ color: '#800' }}><b>Error</b>: {error.toString()}</div>
  }
  if (!iframeHTML) {
    return <CircularLoadingIndicator fullscreen />
  }
  return <iframe ref={iframeRef} srcDoc={iframeHTML} onLoad={onIframeLoad} style={{ width: '100%', height: '100vh', border: 0 }} />
}

const HIDE_WEBFLOW_TAGS = `
<style>
  .w-webflow-badge {
    display: none !important
  }
  .social-sticky, [role=banner] {
    display: none !important
  }
  .navbar {
    display: none !important
  }
</style>
`
