import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'
import { isInIOSWebview } from '@/utils/mobile-app-communication'

const Container = styled('div')({
  ...row,
  marginTop: 24,
  gap: 24,
  justifyContent: 'center',

  '& a': {
    color: COLORS['primary-600'],
    fontSize: 12,
    fontWeight: 500
  }
})

/**
 * Footer addition for IOS used on /subscribe /settings/subscriptions and /settings/credit pages
 *
 * @exports
 * @returns {React.ReactElement}
 */
export const IosFooter = () => {
  const isIos = isInIOSWebview()

  if (!isIos) return null

  return (
    <Container className='ios-footer'>
      <Link to='/terms-of-service'>Terms of Service</Link>
      <Link to='/privacy-policy'>Privacy Policy</Link>
    </Container>
  )
}
