import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sawUnseenAnonymousVideo, selectUnseenAnonymousVids } from './anonymous'
import { apisSlice } from './apis'
import { selectIsAuthInitialized, selectIsLoggedIn, selectLoggedInUser } from './auth'
import { watchVideoProgress } from './processing'
import { removeRecentlyCompletedUpload, sawVidsInLibrary, useRecentlyCompletedUploadVIDs } from './uploads'

import { assert, sleep } from '@/utils'
import { callAPIFromThunk, useAPI, usePublicLibraryAPI } from '@/utils/api'

/**
 * @typedef {object} VideoExcerpt
 * @property {string} vid unique video ID
 * @property {string} name video name
 * @property {string} [desc] video description
 * @property {number} [epoch] UNIX timestamp in seconds when the game was played (or when the video was uploaded if we don't know when it was played)
 * @property {number} [secs] original video duration in seconds (omitted if not yet computed; always provided on user-uploaded videos but not necessarily partner-uploaded videos)
 * @property {boolean} [new] whether this video has been viewed by this user; omitted means false
 * @property {boolean} [processing] true when the video is being processed (or reprocessed)
 * @property {boolean} [starred] starred video; omitted means false
 * @property {boolean} [hidden] hidden video; omitted means false
 */

const EMPTY_ARRAY = [] // a never-changing reference to an empty array

export function useVideoExcerpts () {
  // if the user is logged in, retrieve the videos in their library
  const user = useSelector(selectLoggedInUser)
  const unseenAnonymousVIDs = useSelector(selectUnseenAnonymousVids)
  const getMyLibraryResult = useGetMyLibrary()
  const videos = getMyLibraryResult?.videoExcerpts
  const folders = getMyLibraryResult?.folders
  const isRootFolderPublic = Boolean(getMyLibraryResult?.isRootFolderPublic)

  const videoExcerptsFromMyLibrary = !user
    ? EMPTY_ARRAY
    : (videos ?? EMPTY_ARRAY)

  // Videos that just finished uploading may take several seconds before the
  // server shows them; also we don't invalidate our cache of the user's
  // library. So we need to specifically ask the server about their information
  // so we can show them in our library.
  const { videos: recentlyUploadedVIDs, folders: recentlyUploadedVIDsFolders } = useRecentlyCompletedUploadVIDs()
  const vidsToQueryDirectly = useMemo(() => {
    const alreadyHave = new Set(videoExcerptsFromMyLibrary.map(x => x.vid))
    const ret = []
    for (const vid of recentlyUploadedVIDs) {
      if (!alreadyHave.has(vid)) {
        ret.push(vid)
      }
    }
    return ret
  }, [recentlyUploadedVIDs, videoExcerptsFromMyLibrary])
  const getLibraryFromVidsResult = useGetLibraryFromVids(vidsToQueryDirectly)
  const { videoExcerpts: videoExcerptsFromRecentUploads } = getLibraryFromVidsResult

  // combine the excerpts together and ignore hidden videos
  const videoExcerpts = useMemo(() => {
    const fromMyLib = videoExcerptsFromMyLibrary
    const vidsFromMyLib = new Set(fromMyLib.map(x => x.vid))
    const vidsFolder = new Map(recentlyUploadedVIDsFolders.map(obj => [obj.vid, obj.fid]))
    const fromRecentUploads = [] // excluding dupes already in fromMyLib

    for (const x of (videoExcerptsFromRecentUploads ?? [])) {
      if (!vidsFromMyLib.has(x.vid)) {
        const obj = {
          ...x,
          // for videos we're loading by vid, it means they were uploaded
          // anonymously AND are not yet in our own library; for those videos
          // we must track their seen state locally since the anonymous user
          // cannot mark the video as seen on the server (they must first login)
          new: unseenAnonymousVIDs.indexOf(x.vid) !== -1

        }
        // Recently uploaded videos don't have a folder location property;
        // for those videos we need to handle the vid property manually until the
        // server handles them
        if (vidsFolder.has(x.vid)) {
          obj.fid = vidsFolder.get(x.vid)
        }

        fromRecentUploads.push(obj)
      }
    }
    return [...fromRecentUploads, ...fromMyLib].filter(x => !x.hidden)
  }, [recentlyUploadedVIDsFolders, unseenAnonymousVIDs, videoExcerptsFromMyLibrary, videoExcerptsFromRecentUploads])

  const isLoading = (
    (user && !getMyLibraryResult.isReady) ||
    (vidsToQueryDirectly.length && !getLibraryFromVidsResult.isReady)
  )
  const error = getMyLibraryResult.error ?? getLibraryFromVidsResult.error

  return { error, isReady: !isLoading, videoExcerpts, folders, isRootFolderPublic }
}

export function useVideoExcerptsFromPublicLibrary (data) {
  const getPublicLibraryResult = useGetPublicLibrary(data)

  const isLoading = getPublicLibraryResult.isLoading
  const error = getPublicLibraryResult.error

  return {
    error,
    isReady: !isLoading,
    videoExcerpts: getPublicLibraryResult.videoExcerpts,
    folders: getPublicLibraryResult.folders
  }
}

export function useVideoExcerpt (vid) {
  const { error, isReady, videoExcerpts } = useVideoExcerpts()
  const videoExcerpt = useMemo(() => {
    for (const x of videoExcerpts) {
      if (x.vid === vid) {
        return x
      }
    }
  }, [vid, videoExcerpts])
  return { error, isReady, videoExcerpt }
}

/** @returns {Array<VideoExcerpt>} */
function useGetMyLibrary () {
  return useLazyLibraryAPI(apisSlice.useLazyGetMyLibraryQuery, null)
}

/** @returns {Array<VideoExcerpt>} */
function useGetLibraryFromVids (vids) {
  const inputs = useMemo(() => vids.length ? { vids } : undefined, [vids])
  return useLazyLibraryAPI(apisSlice.useLazyGetLibraryFromVidsQuery, inputs)
}

/** @returns {Array<VideoExcerpt>} */
function useGetPublicLibrary (params) {
  const obj = { uid: params.uid }
  if (params.fid) {
    obj.fid = Number(params.fid)
  }

  const result = usePublicLibraryAPI(apisSlice.useLazyGetPublicLibraryQuery, obj, true)

  const { data, ...rest } = result

  const videoExcerpts = data?.videos
  const folders = data?.folders

  return { ...rest, videoExcerpts, folders }
}

function useLazyLibraryAPI (query, inputs) {
  const result = useAPI(query, inputs, true)
  const dispatch = useDispatch()
  const { data, ...rest } = result
  const videoExcerpts = data?.videos
  const folders = data?.folders
  const isRootFolderPublic = data?.public

  // watch processing progress for any video that's still being processed
  for (const videoExcerpt of videoExcerpts ?? []) {
    const { vid, processingWorkflowIds } = videoExcerpt
    if (processingWorkflowIds) {
      // just watch the most recently started one for now
      const isLatest = true
      const isLatestIdx = 0
      dispatch(watchVideoProgress(vid, processingWorkflowIds[isLatestIdx], isLatest))
    }
  }

  if (query === apisSlice.useLazyGetMyLibraryQuery) {
    const vids = (videoExcerpts ?? []).map(x => x.vid)
    if (vids.length) {
      dispatch(sawVidsInLibrary(vids))
    }
  }

  return { ...rest, videoExcerpts, folders, isRootFolderPublic }
}

export function removeVideoFromLibrary (vid) {
  return async dispatch => {
    dispatch(markVideoAsSeenAndSetFlag(vid, 'hidden', true))
    dispatch(removeRecentlyCompletedUpload({ vid }))
  }
}

export function sawVideo (vid) {
  return markVideoAsSeenAndSetFlag(vid, 'new', false)
}

function markVideoAsSeenAndSetFlag (vid, key, flagValue) {
  return async (dispatch, getState) => {
    const initialState = getState()
    if (selectUnseenAnonymousVids(initialState).indexOf(vid) !== -1) {
      dispatch(sawUnseenAnonymousVideo({ vid }))
    }
    return callUpdateLibraryAPI(dispatch, getState, [{ vid, [key]: flagValue }])
  }
}

async function callUpdateLibraryAPI (dispatch, getState, updates) {
  const initialState = getState()
  const isAuthReady = selectIsAuthInitialized(initialState)
  const isLoggedIn = selectIsLoggedIn(initialState)
  if (!isLoggedIn) {
    return // no library to update
  }
  if (!isAuthReady) {
    await sleep(5000) // give auth lib some time to initialize
    const newState = getState()
    const isAuthReady = selectIsAuthInitialized(newState)
    assert(isAuthReady, 'cannot mark video as seen because auth is not ready')
    // recheck since user could have since changed, though unlikely in 5sec
    const isLoggedIn = selectIsLoggedIn(initialState)
    if (!isLoggedIn) {
      return
    }
  }

  return callAPIFromThunk(dispatch, getState, apisSlice.endpoints.updateLibrary, {
    videos: updates
  })
}

/** Use this for videos which the user does not own but has bookmarked. */
export function setVideoNameInLibraryOnly (vid, name) {
  return async (dispatch, getState) => {
    return callUpdateLibraryAPI(dispatch, getState, [{ vid, name }])
  }
}

export function useUnseenVIDs () {
  const unseenAnonymousVIDs = useSelector(selectUnseenAnonymousVids)
  const { error, isReady, videoExcerpts } = useVideoExcerpts()
  const unseenVIDs = useMemo(
    () => new Set([...unseenAnonymousVIDs, ...videoExcerpts.filter(x => x.new).map(x => x.vid)]),
    [unseenAnonymousVIDs, videoExcerpts])
  return { error, isReady, unseenVIDs }
}

export function createFolder (folderData) {
  return async (dispatch, getState) => {
    return await callAPIFromThunk(dispatch, getState, apisSlice.endpoints.createFolder, folderData)
  }
}

export function updateFolder (updateData) {
  return async (dispatch, getState) => {
    return await callAPIFromThunk(dispatch, getState, apisSlice.endpoints.updateFolder, updateData)
  }
}

export function removeFolder (fid) {
  return async (dispatch, getState) => {
    return await callAPIFromThunk(dispatch, getState, apisSlice.endpoints.removeFolder, { fid })
  }
}

export function moveVideosToNewFolder (videosToMove) {
  return async (dispatch, getState) => {
    return await callUpdateLibraryAPI(dispatch, getState, videosToMove)
  }
}

export function tagUser (tagData) {
  return async (dispatch, getState) => {
    return await callAPIFromThunk(dispatch, getState, apisSlice.endpoints.tagUser, tagData)
  }
}

export function removeTagFromRecent (tagData) {
  return async (dispatch, getState) => {
    return await callAPIFromThunk(dispatch, getState, apisSlice.endpoints.removeTagFromRecent, tagData)
  }
}

export function lookupUser (lookupData) {
  return async (dispatch, getState) => {
    return await callAPIFromThunk(dispatch, getState, apisSlice.endpoints.lookupUser, lookupData, { isUserAPI: false })
  }
}
