import dayjs from 'dayjs'

import { millinow } from '@/utils'
import cls from '@/utils/classnames'

/**
 * Hero component displays the status of a free trial.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.remaining - The number of credits remaining in the trial.
 * @param {number} props.startAt - The start time of the trial in epoch seconds.
 * @param {number} props.endsAt - The end time of the trial in epoch seconds.
 * @returns {JSX.Element} The rendered Hero component.
 */
const Hero = (props) => {
  const { remaining, startAt, endsAt } = props
  const currentEpoch = Date.now() / 1000
  const active = currentEpoch <= endsAt
  // Fallback to now when start is missing (user hasn't yet uploaded a video)
  const startDT = dayjs(startAt ? startAt * 1000 : millinow())
  const endsDT = dayjs(endsAt * 1000)
  const diff = dayjs(endsDT).diff(startDT, 'day')
  const ends = active ? `${diff} days (${endsDT.format('M/D/YY')})` : endsDT.format('M/D/YY')

  return (
    <div className={cls('hero', active ? 'h-active' : 'h-expired')}>
      <div className='h-title'>Free Trial {active ? 'Active' : 'Expired'}</div>
      <div className='h-content'>Credits remaining: {remaining ?? 0}</div>
      <div className='h-footer'>
        <div className='h-start'>Trial started on: {startDT.format('M/D/YY')}</div>
        <div className='h-end'>Trial {active ? 'ends in ' : 'ended on'}: {ends}</div>
      </div>
    </div>
  )
}

export default Hero
