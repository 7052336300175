import { styled } from '@mui/material/styles'

import DownTheLineLeftIcon from '@/assets/down-the-line-left.svg?react'
import DownTheLineRightIcon from '@/assets/down-the-line-right.svg?react'
import DownTheMiddleIcon from '@/assets/down-the-middle.svg?react'
import LeftCrossRightIcon from '@/assets/left-cross-right.svg?react'
import LeftToMiddleIcon from '@/assets/left-to-middle.svg?react'
import MidCrossLeftIcon from '@/assets/mid-cross-left.svg?react'
import MidCrossRightIcon from '@/assets/mid-cross-right.svg?react'
import RightCrossLeftIcon from '@/assets/right-cross-left.svg?react'
import RightToMiddleIcon from '@/assets/right-to-middle.svg?react'
import { SimpleButton } from '@/components/button/simple-button'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'

const Stack = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
  minWidth: 210,
  maxWidth: 210,
  '& button': {
    borderWidth: 2,
    borderColor: 'transparent',
    borderStyle: 'solid',
    '&.selected': {
      borderColor: COLORS['primary-500']
    }
  }
})

const icons = {
  MidCrossLeft: <MidCrossLeftIcon />,
  MidCrossRight: <MidCrossRightIcon />,
  DownTheMiddle: <DownTheMiddleIcon />,
  LeftToMiddle: <LeftToMiddleIcon />,
  LeftCrossRight: <LeftCrossRightIcon />,
  DownTheLineLeft: <DownTheLineLeftIcon />,
  RightToMiddle: <RightToMiddleIcon />,
  RightCrossLeft: <RightCrossLeftIcon />,
  DownTheLineRight: <DownTheLineRightIcon />
}
const directions = [
  'DownTheLineLeft',
  'LeftToMiddle',
  'LeftCrossRight',
  'DownTheMiddle',
  'MidCrossLeft',
  'MidCrossRight',
  'DownTheLineRight',
  'RightToMiddle',
  'RightCrossLeft'
]

export function DirectionSelect (props) {
  const { className, title, onChange, selected = [], style } = props

  const handleSelect = (rec) => {
    if (selected.includes(rec)) {
      // Toggle selection off
      onChange(selected.filter((r) => r !== rec))
    } else {
      onChange([...selected, rec])
    }
  }

  const selectAll = () => {
    onChange(selected.length === directions.length ? [] : selected)
  }

  return (
    <div style={style}>
      <div className='title'>{title}<SimpleButton onClick={selectAll}>{selected.length === directions.length ? 'Deselect all' : 'Select all'}</SimpleButton></div>
      <section className={className}>
        <Stack>
          {directions.map((d) => (
            <SimpleButton className={cls(selected.includes(d) && 'selected')} key={d} onClick={() => handleSelect(d)}>{icons[d]}</SimpleButton>
          ))}
        </Stack>
      </section>
    </div>
  )
}
