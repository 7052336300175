import { useEffect, useRef } from 'react'

import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

function removeBadge (favicon) {
  favicon.href = favicon.dataset.originalHref
}

function setBadge (favicon, unseenCount, faviconMapRef) {
  const onImageLoad = (event) => {
    const img = event.target
    const favicon = faviconMapRef.current[img.id]

    const faviconSize = 32
    const canvas = document.createElement('canvas')
    canvas.width = faviconSize
    canvas.height = faviconSize
    const context = canvas.getContext('2d')

    context.drawImage(img, 0, 0, faviconSize, faviconSize)
    context.beginPath()

    const xOffset = 4
    const yOffset = 4
    const rectX = (canvas.width / 2) - xOffset
    const rectY = (canvas.height / 2) - yOffset
    const rectWidth = canvas.width / 2 + xOffset
    const rectHeight = canvas.height / 2 + yOffset
    context.rect(rectX, rectY, rectWidth, rectHeight)
    context.fillStyle = '#e30'
    context.fill()

    context.font = '15px Arial, sans-serif'
    context.lineHeight = '15px'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillStyle = '#fff'

    const textX = rectX + (rectWidth / 2)
    const textY = rectY + (rectHeight / 2) + 2
    context.fillText(unseenCount, textX, textY)

    const base64 = canvas.toDataURL('image/png')
    favicon.href = base64
  }

  const img = document.createElement('img')
  img.id = favicon.dataset.originalHref
  faviconMapRef.current[favicon.dataset.originalHref] = favicon
  img.addEventListener('load', onImageLoad)
  img.src = favicon.href
}

export const useFaviconBadge = (opts) => {
  const { isReady, unseenCount } = opts
  const faviconMapRef = useRef({})

  const processFavicons = (processFn) => {
    document.querySelectorAll('link[rel="apple-touch-icon-precomposed"], link[rel="icon"]').forEach(processFn)
  }

  useEffect(() => {
    if (isInMobileAppWebview()) {
      return
    }
    processFavicons((favicon) => {
      // save original hrefs
      favicon.dataset.originalHref = favicon.href
    })
  }, [])

  useEffect(() => {
    if (isInMobileAppWebview()) {
      return
    }
    processFavicons((favicon) => {
      if (isReady && unseenCount > 0) {
        setBadge(favicon, unseenCount, faviconMapRef)
      } else {
        removeBadge(favicon)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, unseenCount])
}
