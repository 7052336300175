import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Alert from '@mui/material/Alert'
import Slide from '@mui/material/Slide'
import MuiSnackbar from '@mui/material/Snackbar'
import { styled } from '@mui/material/styles'
import React, { createContext, useCallback, useContext, useState } from 'react'

import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'

function SlideUpTransition (props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Slide {...props} direction='up'>
      {props.children}
    </Slide>
  )
}

const Snackbar = styled(MuiSnackbar, {
  shouldForwardProp: (prop) => !['isMobile', 'severity'].includes(prop)
})(({ isMobile, severity }) => {
  severity = severity || 'success'
  const c = (obj) => obj[severity]

  return {
    width: 'auto !important',
    minWidth: '328px',
    maxWidth: isMobile ? 'unset' : '450px',
    '& .MuiAlert-root': {
      padding: '8px',
      backgroundColor: c({ success: COLORS['success-050'], error: COLORS.error }),
      border: `1px solid ${c({ success: COLORS['success-300'], error: COLORS.error })}`,
      borderRadius: '12px',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '150%',
      color: c({ success: COLORS['success-800'], error: 'white' }),
      alignItems: 'center',
      '& .MuiAlert-icon svg': {
        fontSize: '24px',
        color: c({ success: COLORS['success-700'], error: 'white' }),
        paddingTop: 0
      },
      '& .MuiAlert-message': {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        padding: 0,
        color: c({ success: COLORS['success-800'], error: 'white' })
      },
      '& .MuiAlert-action': {
        paddingTop: 0,
        '& button': {
          marginRight: '4px',
          '& svg': {
            fontSize: '24px',
            color: c({ success: COLORS['success-800'], error: 'white' })
          }
        }
      }
    }
  }
})

const SnackbarContext = createContext()

export const useSnackbar = () => useContext(SnackbarContext)

/**
 * Provides a Snackbar context for displaying snackbar notifications.
 *
 * @param {object} props The component props.
 * @param {React.ReactNode} props.children Child components that will have access to the SnackbarContext.
 *
 * @returns {React.ReactElement} A provider component that supplies snackbar functionality to its children.
 *
 * @example
 * const openSnackbar = useSnackbar()
 * openSnackbar('Link copied to clipboard', 'success')
 * openSnackbar('Network Error', 'error')
 */
export function SnackbarProvider ({ children }) {
  const isMobile = useMobileDetect()
  const [state, setState] = useState({
    open: false,
    message: '',
    severity: ''
  })

  const openSnackbar = useCallback((message, severity) => {
    setState({ open: true, message, severity })
  }, [])

  const handleClose = (event, reason) => {
    setState({ ...state, open: false })
  }

  return (
    <SnackbarContext.Provider value={openSnackbar}>
      {children}
      <Snackbar
        open={state.open}
        onClose={(e, reason) => {
          if (reason !== 'clickaway') {
            handleClose()
          }
        }}
        isMobile={isMobile}
        severity={state.severity}
        autoHideDuration={4000}
        TransitionComponent={SlideUpTransition}
      >
        <Alert
          iconMapping={{
            success: <CheckCircleIcon />,
            error: <WarningAmberIcon />
          }}
          severity={state.severity}
          variant='filled'
          sx={{ width: '100%' }}
          onClose={handleClose}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
