import { liteClient as algoliaSearch } from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch'

const algoliaClient = algoliaSearch(
  import.meta.env.ALGOLIA_APP_ID,
  import.meta.env.ALGOLIA_SEARCH_API_KEY
)

// Custom search client serving as proxy to detect and prevent empty search requests
const proxyClient = {
  ...algoliaClient,
  search (requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: ''
        }))
      })
    }

    return algoliaClient.search(requests)
  }
}

export const AlgoliaWrapper = ({ indexName, searchAttributes, children }) => (
  <InstantSearch
    searchClient={proxyClient}
    indexName={indexName}
    future={{
      preserveSharedStateOnUnmount: true
    }}
  >
    <Configure restrictSearchableAttributes={searchAttributes} query='' />
    {children}
  </InstantSearch>
)
