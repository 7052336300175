import { styled } from '@mui/material'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { ExploreLandingSection } from './sections/explore-section'
import { HeroLandingSection } from './sections/hero-section'
import { PartnersLandingSection } from './sections/partners-section'
import { TestimonialsLandingSection } from './sections/testimonials-section'
import { VideoLandingSection } from './sections/video-section'

import { useIsLoggedIn } from '@/store/auth'
import { column } from '@/utils/flexGrid'

export default function LandingPage () {
  const isLoggedIn = useIsLoggedIn()

  useEffect(() => {
    const mainDiv = document.getElementsByClassName('main')[0]
    mainDiv.classList.add('landing-page-main')
    return () => {
      mainDiv.classList.remove('landing-page-main')
    }
  }, [])

  if (isLoggedIn) {
    return <Navigate to='/library' replace />
  }

  return (
    <Container>
      <HeroLandingSection />
      <VideoLandingSection />
      <ExploreLandingSection />
      <TestimonialsLandingSection />
      <PartnersLandingSection />
    </Container>
  )
}

const Container = styled('div')({
  ...column
})
