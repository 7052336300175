import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import { useCallback, useState, useEffect } from 'react'

import { Spinner } from '@/components/spinner'
import { PurchaseSuccessful, PurchaseUnsuccessful } from '@/pages/purchase-done'
import Container, { CheckoutContainer } from '@/pages/stripe/container'
import { useLoggedInUserCredentials } from '@/store/auth'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

// created outside of any component to avoid recreating it
async function loadStripeWrapper () {
  if (isInMobileAppWebview()) {
    return
  }
  const { loadStripe } = await import('@stripe/stripe-js')
  return loadStripe(import.meta.env.STRIPE_PUBLISHER_KEY)
}
export const stripePromise = loadStripeWrapper()

export function CheckoutForm ({ productId }) {
  const { token, uid } = useLoggedInUserCredentials()
  const fetchClientSecret = useCallback(() => {
    const body = { productId }
    if (window.location.hostname.startsWith('localhost')) {
      body.toLocalhost = true // redirect to localhost web app when checkout is done
    }
    return window.fetch(`${import.meta.env.VITE_API_SERVER}/stripe/session/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-uid': uid,
        'x-token': token
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(data => data.clientSecret)
  }, [productId, token, uid])

  if (isInMobileAppWebview()) {
    return 'invalid checkout component for mobile app'
  }
  if (!token) {
    return <Spinner text='Loading...' />
  }

  const options = { fetchClientSecret }
  return (
    <CheckoutContainer id='checkout'>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout className='stripe-checkout-content' />
      </EmbeddedCheckoutProvider>
    </CheckoutContainer>
  )
}

// This component is used for stripe checkout page and not the native ones.
function StripeCheckoutReturnPage () {
  const { token, uid } = useLoggedInUserCredentials()
  const [status, setStatus] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sessionId = urlParams.get('session_id')

    if (!token || isInMobileAppWebview()) {
      return
    }

    window.fetch(`${import.meta.env.VITE_API_SERVER}/stripe/session/status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-uid': uid,
        'x-token': token
      },
      body: JSON.stringify({ sessionId })
    })
      .then(res => res.json())
      .then(data => {
        setStatus(data.status)
      })
  }, [token, uid])

  if (isInMobileAppWebview()) {
    return 'invalid checkout return component for mobile app'
  }

  if (status === null) {
    return <Spinner text='Loading...' />
  }

  return (
    <Container>
      {status === 'complete'
        ? (
          <PurchaseSuccessful />
          )
        : (
          <PurchaseUnsuccessful />
          )}
    </Container>
  )
}

export default StripeCheckoutReturnPage
