import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

export const YouTubeEmbedContainer = styled('div')({
  position: 'relative',
  paddingBottom: '56.25%',
  width: 886,
  height: 0,
  overflow: 'hidden',
  maxWidth: '100%',
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '1142px !important',
  maxWidth: '100%',
  padding: '0 15px 104px',
  '& .court-insights': {
    marginTop: '98px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '258px',
    border: `1px solid ${COLORS['neutral-300']}`,
    backgroundColor: COLORS['neutral-100'],
    borderRadius: '8px',
    padding: '16px 24px',
    '& .header': {
      fontFamily: 'Inter',
      fontSize: 30,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '133%',
      color: COLORS['neutral-700']
    },
    '& .body': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '16px',
      '& .from': {
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '155%',
        letterSpacing: '3px',
        color: COLORS['neutral-800']
      }
    }
  },
  '& .header-text': {
    marginTop: '56px',
    maxWidth: '796px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .title': {
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '56px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '102%',
      letterSpacing: '-2px',
      color: COLORS['neutral-800']
    },
    '& .subtitle': {
      marginTop: '8px',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '155%',
      color: COLORS['neutral-800']
    },
    '& .pricing': {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '155%',
      color: COLORS['neutral-800']
    }
  },
  '& .header-buttons': {
    marginTop: '56px',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    '& button': {
      fontWeight: 500,
      '& .MuiSvgIcon-root': {
        color: COLORS['primary-500'],
        marginLeft: '0px'
      }
    }
  },
  '& .simple-one-tap-recording': {
    marginTop: '114px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
    '& .title': {
      fontFamily: 'Inter',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '133%',
      color: COLORS['neutral-800']
    },
    '& .image-container': {
      width: '100%',
      maxWidth: '872px',
      '& img': {
        width: '100%',
        height: 'auto',
        display: 'block'
      }
    }
  },
  '& .game-analytics': {
    marginTop: '88px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    '& .title': {
      fontFamily: 'Inter',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '133%',
      color: COLORS['neutral-800']
    },
    '& .logo-container': {
      padding: '8px 16px',
      backgroundColor: COLORS['neutral-050'],
      border: `1px solid ${COLORS['neutral-300']}`,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: 185,
        height: 40
      }
    }
  },
  '& .explore-section': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '> div': {
      width: '1094px',
      maxWidth: '100%'
    }
  },
  '& .replay-section': {
    maxWidth: '1142px',
    display: 'flex',
    gap: '32px',
    marginTop: '72px',
    marginBottom: '48px',
    '& .pane': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      border: `1px solid ${COLORS['neutral-300']}`,
      backgroundColor: COLORS['neutral-100'],
      borderRadius: '8px',
      padding: '16px 24px',
      gap: '26px',
      '& .description': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        '& .title': {
          fontFamily: 'Inter',
          fontSize: 30,
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '133%',
          color: COLORS['neutral-800']
        },
        '& .subtitle': {
          fontFamily: 'Inter',
          fontSize: 18,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '155%',
          color: COLORS['neutral-800']
        }
      },
      '& .image-container': {
        width: '100%',
        position: 'relative',
        '& img': {
          width: '100%',
          height: 'auto',
          display: 'block'
        },
        '& .image-overlay': {
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .replay': { width: '23%' }
        }
      }
    }
  },
  '& .see-it-in-action': {
    width: '100%',
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    '& .header': {
      fontFamily: 'Inter',
      fontSize: 36,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '133%',
      color: COLORS['neutral-800']
    },
    '& .image-container': {
      width: '100%',
      maxWidth: '886px',
      '& img': {
        width: '100%',
        height: 'auto',
        display: 'block'
      }
    }
  },
  '& .package-contents': {
    width: '100%',
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '48px',
    padding: '40px 24px',
    borderRadius: '8px',
    border: `1px solid ${COLORS['neutral-300']}`,
    backgroundColor: COLORS['neutral-100'],
    '& .header': {
      marginTop: '24px',
      fontFamily: 'Inter',
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '133%',
      color: COLORS['neutral-800']
    },
    '& .items': {
      maxWidth: '717px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      '& .package-item': {
        display: 'flex',
        gap: '24px',
        '& .image-pane': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${COLORS['neutral-300']}`,
          borderRadius: 8,
          backgroundColor: COLORS['neutral-050'],
          width: 205,
          height: 210
        },
        '& .image-container': {
          '& img': {
            width: '100%',
            height: 'auto',
            display: 'block'
          }
        },
        '& .description': {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '8px',
          '& .title': {
            fontFamily: 'Inter',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '150%',
            color: COLORS['neutral-800']
          },
          '& .subtitle': {
            fontFamily: 'Inter',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '155%',
            color: COLORS['neutral-800']
          }
        },
        '&.premium-camera': {
          '& .image-container': {
            maxWidth: '168px'
          }
        },
        '&.tablet': {
          '& .image-container': {
            maxWidth: '165px'
          }
        },
        '&.automatic-insights': {
          '& .image-container': {
            maxWidth: '142px'
          }
        }

      }
    }
  },
  '& .pricing-section': {
    width: '100%',
    marginTop: '104px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    '& .header': {
      fontFamily: 'Inter',
      fontSize: 30,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '133%',
      color: COLORS['neutral-800']
    },
    '& .items': {
      display: 'flex',
      gap: '48px',
      '& .item': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
        '& .item-header': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '8px',
          '& svg': {
            color: COLORS['primary-400'],
            fontSize: 40
          },
          '& .title': {
            fontFamily: 'Inter',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '150%',
            color: COLORS['text-tertiary']
          }
        },
        '& .item-description': {
          fontFamily: 'Inter',
          fontSize: 18,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '150%',
          color: COLORS['neutral-800']
        },
        '& button': {
          marginTop: 25
        }
      }
    },
    '&.equipment-pricing': {
      marginBottom: 69
    },
    '&.customer-pricing': {
      marginTop: 40
    }
  },
  '& .MuiDivider-root': {
    width: '100%'
  },
  '& .typeform-button': {
    cursor: 'pointer',
    fontSize: '14px !important',
    fontStyle: 'normal',
    lineHeight: '20px',
    minHeight: 32,
    padding: '8px 14px',
    textTransform: 'none',
    borderRadius: '8px',
    color: COLORS.white,
    backgroundColor: COLORS['primary-500'],
    border: 'none'
  },
  '&.mobile': {
    '& .court-insights': {
      marginTop: '50px'
    },
    '& .header-text': {
      '& .title': {
        fontSize: 35
      },
      '& .pricing': {
        textAlign: 'center'
      }
    },
    '& .header-buttons': {
      flexDirection: 'column'
    },
    '& .simple-one-tap-recording': {
      '& .title': {
        textAlign: 'center'
      }
    },
    '& .game-analytics': {
      '& .title': {
        textAlign: 'center'
      }
    },
    '& .explore-section': {
      marginTop: 20
    },
    '& .replay-section': {
      flexDirection: 'column'
    },
    '& .package-contents': {
      alignItems: 'flex-start',
      '& .items': {
        gap: '45px',
        '& .package-item': {
          gap: '5px',
          flexDirection: 'column'
        }
      }
    },
    '& .pricing-section': {
      padding: '0 5px',
      '& .items': {
        flexDirection: 'column',
        '& .item-header': {
          alignItems: 'flex-start'
        }
      }
    }
  }
})
