import { useSearchParams, useParams } from 'react-router-dom'

import { PageContent } from './content'

import { useGetInsights, useGetVideo } from '@/store/video'
import { APIWrapper } from '@/utils/api'

function ActionViewerPage () {
  const params = useParams()
  const searchParams = useSearchParams()[0]

  const vid = params.vid
  const workflowId = searchParams.get('workflowId')
  const getInsightsArgs = { vid }
  if (workflowId) {
    getInsightsArgs.workflowId = workflowId
  }

  return (
    <APIWrapper
      apis={[
        { api: useGetVideo, args: vid, waitForLoad: true, name: 'video', needsUseAPI: false },
        { api: useGetInsights, args: getInsightsArgs, waitForLoad: false, name: 'insights', needsUseAPI: false }
      ]}
      addContext={ctx => {
        // if the insights data isn't the right version, don't try to use it
        if (ctx.insights) {
          ctx.insightsVersion = ctx.insights.version
        }

        // add workflow to context (extracted from video object to be the one
        // that was requested by the user)
        const { workflows } = ctx.video
        if (!workflowId) {
          // workflows[0] could be undefined if no workflow has started yet
          const latestWorkflow = workflows[0]
          return { workflow: latestWorkflow, ...ctx }
        }
        for (const workflow of workflows) {
          if (workflow.workflowId === workflowId) {
            return { workflow, ...ctx }
          }
        }
        return ctx // workflow not found
      }}
    >
      <PageContent />
    </APIWrapper>
  )
}

export default ActionViewerPage
