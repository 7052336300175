/**
 * Function that extracts frame from video as jpg image for preview
 *
 * @exports
 * @param {File} file
 * @param {number} [seekTo]
 * @param {float} quality
 * @returns {promise}
 */
export function getVideoMeta (file, seekTo = 0.0, quality = 0.75) {
  return new Promise((resolve, reject) => {
    // load the file to a video element
    const videoPlayer = document.createElement('video')
    videoPlayer.setAttribute('src', URL.createObjectURL(file))
    videoPlayer.load()
    videoPlayer.addEventListener('error', (ex) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('error when loading video file', ex)
    })
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('video is too short.')
        return
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo
      }, 200)
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas')
        // only need a thumbnail size; choose a center crop
        const desiredSize = { width: 480, height: 270 }
        const widthToHeight = desiredSize.width / desiredSize.height
        const from = {}
        if (videoPlayer.videoHeight * widthToHeight > videoPlayer.videoWidth) {
          from.width = videoPlayer.videoWidth
          from.x = 0
          from.height = Math.ceil(from.width / widthToHeight)
          from.y = Math.floor((videoPlayer.videoHeight - from.height) / 2)
        } else {
          from.height = videoPlayer.videoHeight
          from.y = 0
          from.width = Math.ceil(from.height * widthToHeight)
          from.x = Math.floor((videoPlayer.videoWidth - from.width) / 2)
        }
        canvas.width = desiredSize.width
        canvas.height = desiredSize.height
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d')
        ctx.drawImage(
          videoPlayer,
          from.x, from.x, from.width, from.height,
          0, 0, desiredSize.width, desiredSize.height
        )

        // Return video meta data
        const meta = {
          fps: 30, // just assume for now; server will backfill with real number
          width: videoPlayer.videoWidth,
          height: videoPlayer.videoHeight,
          duration: Math.ceil(videoPlayer.duration), // round up to the nearest second
          thumbnail: ctx.canvas.toDataURL('image/jpeg')
        }
        resolve(meta)
      })
    })
  })
}

/**
 * Returns the URL from which the video can be downloaded.
 *
 * If there's no video available to download yet, then this returns null.
 */
export function getVideoURLForDownload (video) {
  const vid = video?.vid
  if (!vid) {
    return undefined
  }
  // can only download the full video until the rallies version is ready
  if (video.noPoster) {
    return null // max.mp4 isn't ready yet
  }
  return `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/max.mp4`
}
