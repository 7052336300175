import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import useForkRef from '@mui/utils/useForkRef'
import {
  DateRangePicker as MUIDateRangePicker,
  MobileDateRangePicker as MUIMobileDateRangePicker,
  pickersLayoutClasses
} from '@mui/x-date-pickers-pro'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { forwardRef, useState } from 'react'

import { ButtonToggle } from './containers'

import useMobileDetect from '@/hooks/use-mobile-detect'

const DateRangeToggle = forwardRef((props, ref) => {
  const {
    setOpened,
    setDateRange,
    id,
    value,
    disabled,
    InputProps: { ref: containerRef } = {},
    inputProps: { 'aria-label': ariaLabel } = {}
  } = props

  const handleRef = useForkRef(ref, containerRef)
  const isRangeSelected = !!value[0] && !!value[1]

  function capitalizeFirstLetter (str) {
    if (!str) return str
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const renderSelectedRange = () => {
    const start = value[0]
    const end = value[1]

    const now = dayjs()
    const oneYearAgo = now.subtract(11, 'months')

    const isWithinLast11Months = start.isAfter(oneYearAgo) && end.isAfter(oneYearAgo)
    const formatString = isWithinLast11Months ? 'MMM D' : 'MMM D, YYYY'

    const startStr = start.format(formatString)
    const endStr = start.month() === end.month() ? end.format('D') : end.format(formatString)

    const onClearDateRange = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDateRange([])
    }

    return (
      <div className='selected-range-content'>
        <CheckIcon className='check-icon' />
        <div className='label'>
          {capitalizeFirstLetter(startStr)} - {capitalizeFirstLetter(endStr)}
        </div>
        <IconButton className='close-button' onClick={onClearDateRange} title='Clear'>
          <CloseIcon className='close-icon' />
        </IconButton>
      </div>
    )
  }

  const renderNoRangeContent = () => {
    return (
      <div className='no-range-content'>
        <CalendarTodayIcon className='calendar-icon' />
        <span className='label'>Date Range</span>
        <ExpandMoreIcon className='arrow-icon' />
      </div>
    )
  }

  return (
    <ButtonToggle
      className={clsx([{ 'range-selected': isRangeSelected }])}
      variant='outlined'
      id={id}
      disabled={disabled}
      ref={handleRef}
      aria-label={ariaLabel}
      onClick={() => setOpened?.((prev) => !prev)}
    >
      {isRangeSelected ? renderSelectedRange() : renderNoRangeContent()}
    </ButtonToggle>
  )
})
DateRangeToggle.displayName = 'DateRangeToggle'
DateRangeToggle.fieldType = 'single-input'

const DateRangePicker = forwardRef((props, ref) => {
  const isMobile = useMobileDetect()
  const { dateRange, setDateRange } = props
  const [opened, setOpened] = useState(false)

  const shortcutsItems = [
    {
      label: 'This Week',
      getValue: () => {
        const today = dayjs()
        return [today.startOf('week'), today.endOf('week')]
      }
    },
    {
      label: 'Last Week',
      getValue: () => {
        const today = dayjs()
        const prevWeek = today.subtract(7, 'day')
        return [prevWeek.startOf('week'), prevWeek.endOf('week')]
      }
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs()
        return [today.subtract(7, 'day'), today]
      }
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs()
        return [today.startOf('month'), today.endOf('month')]
      }
    },
    { label: 'Reset', getValue: () => [null, null] }
  ]

  const renderDesktopView = () => (
    <MUIDateRangePicker
      slots={{ field: DateRangeToggle, ...props.slots }}
      slotProps={{
        field: { setOpened, setDateRange },
        shortcuts: {
          items: shortcutsItems
        },
        toolbar: {
          hidden: false
        },
        actionBar: { actions: [] }
      }}
      ref={ref}
      {...props}
      open={opened}
      value={dateRange}
      onChange={(value) => setDateRange(value)}
      onClose={() => setOpened(false)}
      onOpen={() => setOpened(true)}
    />
  )

  const renderMobileView = () => (
    <MUIMobileDateRangePicker
      slots={{
        field: DateRangeToggle,
        ...props.slots
      }}
      slotProps={{
        field: { setOpened, setDateRange },
        layout: {
          sx: {
            [`.${pickersLayoutClasses.toolbar}`]: {
              gridColumn: '1'
            },
            [`.${pickersLayoutClasses.shortcuts}`]: {
              gridRow: '3'
            },
            [`.${pickersLayoutClasses.contentWrapper}`]: {
              gridColumn: '1'
            },
            [`.${pickersLayoutClasses.actionBar}`]: {
              alignItems: 'flex-start'
            }
          }
        },
        shortcuts: {
          items: shortcutsItems
        },
        toolbar: {
          hidden: false
        },
        closeOnSelect: true,
        actionBar: {
          actions: ['cancel', 'accept']
        }
      }}
      ref={ref}
      {...props}
      open={opened}
      value={dateRange}
      onChange={(value) => {
        setDateRange(value)
      }}
      onClose={() => setOpened(false)}
      onOpen={() => setOpened(true)}
    />
  )

  return (
    <>
      {isMobile ? renderMobileView() : renderDesktopView()}
    </>
  )
})
DateRangePicker.displayName = 'DateRangePicker'

export default DateRangePicker
