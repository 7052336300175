/**
 * In popups.js, we should define all the popups that
 * display in our app.
 */

export const MONETIZATION_POPUP_KEYS = {
  UNSUBSCRIBED_OUT_OF_CREDIT: 'unsubscribed_out_of_credit',
  SUBSCRIBED_OUT_OF_CREDIT: 'subscribed_out_of_credit',
  ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT: 'anonymous_prompt_to_create_account'
}

export const TEST_SERVER_POPUP_KEYS = {
  NOT_WHITELISTED_TO_UPLOAD: 'not_whitelisted_to_upload'
}

const monetizationPopupList = {
  [MONETIZATION_POPUP_KEYS.SUBSCRIBED_OUT_OF_CREDIT]: {
    id: MONETIZATION_POPUP_KEYS.SUBSCRIBED_OUT_OF_CREDIT,
    title: 'You\'re Out of Credits'
  },
  [MONETIZATION_POPUP_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT]: {
    id: MONETIZATION_POPUP_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT,
    title: 'Subscribe to Upload and Analyze More Videos',
    message: 'To unlock video uploads and access advanced analysis tools, please subscribe to PB Vision. Take your game analysis to the next level.'
  },
  [MONETIZATION_POPUP_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT]: {
    id: MONETIZATION_POPUP_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT,
    title: 'Create an Account to Upload More Videos',
    message: 'As an anonymous user, you\'re limited to one video upload. To continue uploading and analyzing your videos, please log in or create an account.'
  }
}

const testServerPopupList = {
  [TEST_SERVER_POPUP_KEYS.NOT_WHITELISTED_TO_UPLOAD]: {
    title: 'Test Server Notice',
    message: 'Your account is not whitelisted to upload on the test server.'
  }
}

export const POPUP_KEYS = {
  ...MONETIZATION_POPUP_KEYS,
  ...TEST_SERVER_POPUP_KEYS
}

export const popupList = {
  ...monetizationPopupList,
  ...testServerPopupList
}
