import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'

/**
 * Material UI Button styled to have an invisible ui, used to wrap icons or other elements
 *
 * @exports
 * @param {object} { MUIButton props }
 * @returns {React.ReactElement}
 */
export const TextButton = styled(MuiButton)({
  background: 'transparent',
  border: 'none',
  color: 'inherit',
  display: 'flex',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 1,
  margin: 0,
  minWidth: 'unset',
  overflow: 'inherit',
  padding: 0,
  textOverflow: 'ellipsis',
  textTransform: 'initial',
  whiteSpace: 'nowrap',

  '&:hover, &:active, &:focus': {
    background: 'transparent'
  },
  '&.underline': {
    textDecoration: 'underline'
  }
})
// color: COLORS['00-on-surface-high-emphasis'],

TextButton.defaultProps = {
  disableRipple: true
}
