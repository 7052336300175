import { asArray } from './array'

/**
 * Casts a given value to a specified type.
 *
 * @param {*} value - The value to be cast.
 * @param {string} type - The type to cast the value to. Supported types are 'string', 'number', 'int', 'float', 'boolean', 'object', and 'array'.
 * @returns {*} - The value cast to the specified type. If no valid type is specified, returns the original value.
 */
export function cast (value, type) {
  switch (type) {
    case 'string':
      return String(value)
    case 'number':
      return Number(value)
    case 'int':
      return Number.parseInt(value, 10) // Converts value to an integer.
    case 'float':
      return Number.parseFloat(value) // Converts value to a floating-point number.
    case 'boolean':
      return Boolean(value)
    case 'object':
      return typeof value === 'object' ? value : {}
    case 'array':
      return asArray(value)
    default:
      return value // No conversion if type is not specified
  }
}
