import { styled } from '@mui/material'
import React, { useContext } from 'react'

import { PlayerStatsContext } from '..'
import StatsSectionTitle from '../components/stats-section-title'

import { CourtCoverage } from '@/components/court-coverage'
import { Divider } from '@/components/divider'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'

export default function CourtCoverageGraph (props) {
  const context = useContext(PlayerStatsContext)
  const playerIdx = props.playerIdx
  const isMobile = useMobileDetect()
  const insights = context?.insights

  if (!insights) {
    return null
  }

  return (
    <Container className={isMobile && 'mobile'}>
      <div className='graph-description'>
        <StatsSectionTitle tooltip='Total distance in feet the player moved across the court during the game.' title='Court Coverage' />
        <span className='desc-subtitle'>
          Game Overview
        </span>
        <Divider />
        <p>
          Distance Covered: {round(insights.player_data[playerIdx].court_coverage.total_distance_covered)} ft.
        </p>
      </div>
      <div className='graph'>
        <CourtCoverage data={insights.player_data[playerIdx].court_coverage.heat_map_coordinates} />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  alignItems: 'flex-start',
  background: COLORS.white,
  border: `1px solid ${COLORS['neutral-300']}`,
  padding: 32,
  '& .graph-description': {
    ...column,
    width: 200,
    minWidth: 200,
    color: COLORS.black,
    alignItems: 'flex-end',
    gap: 8,
    '& .stats-section-title': {
      alignSelf: 'flex-start'
    },
    '& .desc-subtitle': {
      fontSize: 12,
      color: COLORS['neutral-500']
    }
  },
  '& .graph': {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  '&.mobile': {
    ...column,
    marginBottom: 20,
    gap: 20,

    '& .graph-description': {
      marginLeft: 'auto'

    },
    '& .MuiInputBase-root': {
      width: '100%'
    }
  }
})
