import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const ButtonToggle = styled(Button)({
  height: '40px',
  borderColor: '#DAE2EA',
  '&.range-selected': {
    backgroundColor: '#DDE7EE',
    paddingRight: '3px'
  },
  '& .no-range-content': {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    '& .calendar-icon': {
      color: '#636B74',
      fontSize: '20px'
    },
    '& .label': {
      color: '#636B74',
      textTransform: 'none',
      marginLeft: '10px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .arrow-icon': {
      color: '#636B74',
      marginLeft: '5px',
      fontSize: '20px'
    }
  },
  '& .selected-range-content': {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& .check-icon': {
      fontSize: '20px',
      color: '#636B74'
    },
    '& .close-button': {
      marginLeft: '6px'
    },
    '& .close-icon': {
      fontSize: '17px',
      color: '#272d34'
    },
    '& .label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textTransform: 'none',
      color: '#474F54',
      fontWeight: 'bold',
      marginLeft: '6px'
    }
  }
})
