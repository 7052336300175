export function nestFolders (folders) {
  const folderMap = new Map()
  if (folders?.length) {
    folders.forEach(folder => {
      folderMap.set(folder.fid, { ...folder, level: 0, children: [] })
    })

    folders.forEach(folder => {
      if (folder.parent) {
        const parentFolder = folderMap.get(folder.parent)
        if (parentFolder) {
          const childFolder = folderMap.get(folder.fid)
          childFolder.level = parentFolder.level + 1
          parentFolder.children.push(childFolder)
        }
      }
    })

    // Collect top-level folders (those without parents)
    const nestedFolders = Array.from(folderMap.values()).filter(folder => !folder.parent)

    return nestedFolders
  }

  return folderMap
}
