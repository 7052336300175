import { styled } from '@mui/material'

import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

const Container = styled('div')({
  ...column,
  width: '100%',

  '& .hero': {
    ...column,
    borderRadius: 8,
    textAlign: 'center',
    fontWeight: 700,
    paddingBottom: 8,
    marginBottom: 8,

    '& .h-title': {
      fontSize: 18,
      letterSpacing: -1,
      lineHeight: '166%'
    },
    '& .h-content': {
      fontSize: 28,
      letterSpacing: -1,
      lineHeight: '166%'
    },
    '& .h-footer': {
      ...row,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '133%',
      justifyContent: 'center',
      gap: 32,
      padding: 8
    },
    '&.h-active': {
      background: COLORS['warning-100'],
      border: `1px solid ${COLORS['warning-600']}`,

      '& .h-title': {
        color: COLORS['warning-800']
      },
      '& .h-content': {
        background: COLORS['warning-050'],
        color: COLORS['warning-900']
      },
      '& .h-footer': {
        color: COLORS['warning-800']
      }
    },
    '&.h-expired': {
      background: COLORS['danger-050'],
      border: `1px solid ${COLORS['danger-300']}`,
      color: COLORS['danger-900'],

      '& .h-content': {
        background: COLORS['danger-100'],
        color: COLORS['danger-900']
      }
    }
  },
  // Generic helper classes
  '& .row': {
    ...row,
    gap: 8
  },
  // Mobile overrides
  '&.mobile': {
    '& .row': {
      ...column
    },
    '& .s-buttons': {
      alignItems: 'stretch'
    }
  }
})

export default Container
