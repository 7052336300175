import { useEffect } from 'react'

import Container from '../privacy-policy/container'

const PrivacyPolicyPage = () => {
  useEffect(() => {
    const mainDiv = document.getElementsByClassName('main')[0]
    mainDiv.classList.add('converted-from-webflow')
    return () => {
      mainDiv.classList.remove('converted-from-webflow')
    }
  }, [])

  return (
    <Container>
      <div className='section'>
        <div className='container w-container'>
          <div className='center-content center-text margin-top-bottom'>
            <h1 className='limit-large'>PB Vision API License Agreement</h1>
            <div className='opacity-dark-text limit-large small-text'>Last updated: 9 January, 2024</div>
            <p><strong>API License Agreement for Pickleball Vision AI Inc.</strong></p>
            <div className='rich-text-block w-richtext license-agreement'>
              <p>
                This API License Agreement is a legal agreement between you (the &quot;Licensee&quot;) and Pickleball Vision AI Inc. (the “Licensor”). By using our APIs, you agree to the following terms:
              </p>
              <div className='term-1'>
                <p>1. Definitions</p>
                <p>1.1 &quot;APIs&quot; refers to any and all application programming interfaces developed and provided by the Licensor, now and in the future. This includes, but is not limited to, services for processing uploaded pickleball game videos. This definition is inclusive of any additional APIs or services that the Licensor may develop and offer as part of its product suite in the future.</p>
              </div>
              <div className='term-2'>
                <p>2. Grant of License</p>
                <p>2.1 Subject to the terms and conditions of this Agreement, Licensor grants Licensee a non-exclusive, non-transferable, revocable right to access and use the APIs, solely to develop, test, and support any software applications, services, or products and distribute such applications to end users.</p>
              </div>
              <div className='term-3'>
                <p>3. Use of Vendor Interface</p>
                <p>3.1 Licensee shall use the vendor interface provided by Licensor solely for the purpose of accessing the APIs, shall comply with all instructions and guidelines provided by Licensor, and shall not use the vendor interface for any purpose without Licensor&apos;s prior written consent.</p>
                <p>3.2 Compliance with Privacy Policy and Terms of Service: In addition to the conditions set forth in this Agreement, the Licensee&apos;s access to and use of the APIs are also subject to the Licensor&apos;s Privacy Policy and Terms of Service, as published on the Licensor&apos;s website. The Licensee agrees to comply with these additional terms, which are incorporated by reference into this Agreement. The Privacy Policy and Terms of Service may be updated from time to time at the Licensor&apos;s discretion, and the Licensee is responsible for regularly reviewing these documents and adhering to their latest versions.</p>
              </div>
              <div className='term-4'>
                <p>4. Fees</p>
                <p>4.1 Licensee shall pay Licensor the following fees (&quot;Fees&quot;): Fees are based on the number of video-minutes processed each month. The specific cost per video-minute will be determined through mutual agreement with each individual API Licensee. For the purpose of this agreement, a &quot;video-minute&quot; is defined as one minute of video processing. For example, a complete game of pickleball may be 15 minutes, in which case the Licensee is billed for 15 video-minutes. Invoices will be issued monthly, reflecting the actual volume of video-minutes processed during the previous month. Payment terms for these invoices will be established as part of the individual agreements with each Licensee.</p>
              </div>
              <div className='term-5'>
                <p>5. Intellectual Property</p>
                <p>5.1 Licensor retains all rights, title, and interest in and to the APIs, including all related intellectual property rights. Licensee agrees to take such actions including any legal proceedings, as Licensor may reasonably request to perfect Licensor&apos;s rights to the APIs.</p>
              </div>
              <div className='term-6'>
                <p>6. Support</p>
                <p>6.1 For any technical support related to the APIs, the Licensee may send queries or issues to <a href='mailto:support-api@pb.vision'>support-api@pb.vision</a>. The Licensor will aim to address these queries, but does not guarantee a specific timeframe for response or resolution. The Licensee is encouraged to provide detailed information regarding issues experienced, including relevant data and logs, to facilitate effective communication and potential troubleshooting. However, resolution of issues based on this information is not guaranteed by the Licensor.</p>
              </div>
              <div className='term-7'>
                <p>7. Non-Exclusive Relationship</p>
                <p>7.1 This Agreement is a non-exclusive arrangement. Nothing in this Agreement prevents Licensor from entering into similar agreements with others or from independently developing, using, selling, or licensing documentation, products, and/or services that are similar to or compete with those offered by Licensee.</p>
              </div>
              <div className='term-8'>
                <p>8. Confidentiality</p>
                <p>8.1 Each party agrees to retain in confidence all non-public information and know-how disclosed pursuant to this Agreement that is either designated as proprietary and/or confidential, or by the nature of the circumstances surrounding the disclosure, should in good faith be treated as proprietary and/or confidential.</p>
              </div>
              <div className='term-9'>
                <p>9. Termination Obligations</p>
                <p>9.1 Upon termination of this Agreement, Licensee shall immediately cease use of all APIs, and shall certify to Licensor that such actions have occurred. Licensee shall also immediately cease all use of any Licensor trademarks and will not thereafter use any similar trademarks.</p>
              </div>
              <div className='term-10'>
                <p>10. Disclaimer of Warranties</p>
                <p>10.1 The APIs are provided &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; without warranty of any kind. Licensor disclaims all warranties, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>
              </div>
              <div className='term-11'>
                <p>11. Limitation of Liability</p>
                <p>11.1 The Licensor will not be liable for any indirect, punitive, special, exemplary, incidental, consequential, or other damages of any type or kind (including loss of data, revenue, profits, use, or other economic advantage) arising out of, or in any way connected with the use of the APIs. This limitation of liability applies to all use of the APIs, including but not limited to the use or inability to use the APIs, or for any content obtained from or through the APIs, any interruption, inaccuracy, error, or omission, regardless of cause. This limitation does not apply to the Licensee&apos;s liability arising from the upload or use of illegal content through the APIs; in such cases, the Licensee remains fully liable for any and all consequences of their actions. The Licensor&apos;s limitation of liability is in effect even if the Licensor has been previously advised of the possibility of such damages.</p>
              </div>
              <div className='term-12'>
                <p>12. Indemnification</p>
                <p>12.1 Licensee agrees to indemnify and hold harmless Licensor and its officers, directors, agents, employees, and suppliers from and against any and all claims, proceedings, damages, injuries, liabilities, losses, costs, and expenses (including reasonable attorneys&apos; fees), relating to any acts by Licensee or its agents, officers, directors, or employees in using the APIs, leading to claims against Licensor by any third party, except to the extent such claims directly resulted from Licensor&apos;s gross negligence or willful misconduct.</p>
              </div>
              <div className='term-13'>
                <p>13. Notices</p>
                <p>13.1 All notices under this Agreement shall be in writing and shall be deemed given when sent via email with confirmation of receipt, or three days after being sent by prepaid certified or registered U.S. mail to the address of the party to be noticed as set forth herein or to such other address as such party last provided to the other by written notice.</p>
              </div>
              <div className='term-14'>
                <p>14. Severability</p>
                <p>14.1 If any provision of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed, as nearly as possible, to reflect the intentions of the invalid or unenforceable provision(s), with all other provisions remaining in full force and effect.</p>
              </div>
              <div className='term-15'>
                <p>15. Governing Law</p>
                <p>15.1 This Agreement shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of laws principles.</p>
              </div>
              <div className='term-16'>
                <p>16. Complete Understanding</p>
                <p>16.1 This Agreement constitutes the sole and entire agreement between Licensee and Licensor with respect to the subject matter hereof and supersedes all prior discussions, negotiations, and agreement, whether oral or written.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PrivacyPolicyPage
