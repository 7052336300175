import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import COLORS from '@/utils/colors'

export const Container = styled('div')({
  marginTop: 11,
  fontSize: 14,
  lineHeight: '142%',

  '& a': {
    color: COLORS['primary-500'],
    textDecoration: 'none'
  }
})

export default function TermsSection () {
  return (
    <Container>
      By creating an account, you agree to the <Link to='/terms-of-service' target='_blank'>Terms of Service</Link> and{' '}
      <Link to='/privacy-policy' target='_blank'>Privacy Policy</Link>
    </Container>
  )
}
