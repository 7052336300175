import { cast } from './cast'

import { assert } from '.'

/**
 * Creates an object composed of the picked object properties that have existing value in the object (not undefined)
 *
 * @param {object} obj The source object
 * @param {Array} paths The property paths to pick
 * @returns {object} object with picked properties
 */
function pick (obj, paths) {
  return { ...paths.reduce((mem, key) => ({ ...mem, ...(obj[key] !== undefined ? { [key]: obj[key] } : {}) }), {}) }
}

/**
 * Picks specific properties from an object, applies default values for undefined entries,
 * normalizes values if functions are provided or converts values to specified types if they are provided.
 *
 * @param {Object} obj - The source object to pick properties from.
 * @param {Array<string>} paths - An array of keys to pick from the object.
 * @param {Object} [defaults={}] - An optional object containing default fallback values for keys that are undefined in `obj`.
 * @param {Object} [normalize={}] - An optional object containing normalization functions for specific keys.
 * @param {Object} [types={}] - An optional object specifying the desired types for the keys. Supports 'string', 'number', 'int', 'float', 'boolean', 'object', and 'array'.
 * @returns {Object} - A new object containing the picked properties, using defaults for any undefined properties, with types converted and values normalized.
 */
export function pickAndNormalize (obj, paths, defaults = {}, normalize = {}, types = {}) {
  return {
    ...paths.reduce((mem, key) => {
      let value = obj[key] !== undefined ? obj[key] : defaults[key]

      if (value !== undefined) {
      // Normalize the value if a normalization function is provided
        if (normalize[key]) {
          value = normalize[key](value)
        } else if (types[key]) {
          // Else - convert the value if a type is defined and value is not undefined
          value = cast(value, types[key])
          // Handle case where Number isn't cast properly
          if (typeof value === 'number' && isNaN(value)) {
            if (defaults[key]) {
              value = defaults[key]
            } else {
              assert.fail(`${value} is not a number`)
            }
          }
        }
      }

      return {
        ...mem,
        ...(value !== undefined ? { [key]: value } : {})
      }
    }, {})
  }
}

export default pick
