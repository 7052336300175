import { styled, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useUserHasFlag } from '@/store/auth'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { isInMobileAppWebview, notifyChangeHostname } from '@/utils/mobile-app-communication'

function ChangeHostnameInWebView () {
  const [newHost, setNewHost] = useState('')
  const [isValidLink, setIsValidLink] = useState(false)

  const defaultHost = 'pbv-dev.web.app'
  const checkLink = useCallback((url) => {
    if (url === defaultHost) {
      return true
    }
    const prefix = 'pbv-dev--pr-'
    const suffix = '.web.app'
    return url.indexOf(prefix) === 0 && url.slice(-suffix.length) === suffix
  }, [])

  function cleanUrl (url) {
    // Remove https:// or http:// from the beginning
    let cleanedUrl = url.replace(/^(https?:\/\/)/, '')
    // Remove trailing slash if present
    cleanedUrl = cleanedUrl.replace(/\/$/, '')
    return cleanedUrl
  }

  // Handle input change with URL cleaning
  const handleInputChange = (event) => {
    const inputValue = event.target.value.trim()
    const cleanedValue = cleanUrl(inputValue)
    setNewHost(cleanedValue)
  }

  useEffect(() => {
    setIsValidLink(checkLink(newHost))
  }, [checkLink, newHost])
  return (
    <>
      <p>Current hostname: {cleanUrl(window.location.hostname)}</p>
      <TextField
        variant='outlined'
        value={newHost}
        onChange={handleInputChange}
        placeholder='Enter new hostname'
      />
      <span>*link must start with “pbv-dev--pr-” and end with “.web.app”.</span>
      <span>You can paste the entire preview link, and it will be automatically stripped.</span>
      <Button
        disabled={!isValidLink}
        className='green'
        onClick={() => notifyChangeHostname(newHost)}
      >
        Change
      </Button>
      <Button
        disabled={newHost === defaultHost}
        className='green'
        onClick={() => {
          setNewHost(defaultHost)
          notifyChangeHostname(defaultHost)
        }}
      >
        Reset to Default
      </Button>
    </>
  )
}

export default function Test () {
  const isMobile = useMobileDetect()
  const isInWebview = isInMobileAppWebview() || 1
  const isTester = useUserHasFlag('tester')

  return (
    <Container className={isMobile && 'mobile'}>
      <h2>Test</h2>
      {isTester && <p>Visible only on dev server for authorized testers like you.</p>}
      {!isTester && <p>This menu is only usable by authorized testers (your account is not currently included).</p>}
      {isTester && isInWebview && (
        <SingleSection title='WebView'>
          <SingleOption title='Change hostname'>
            <ChangeHostnameInWebView />
          </SingleOption>
        </SingleSection>
      )}
    </Container>
  )
}

const SingleSection = (props) => {
  return (
    <div className='section'>
      <h3>{props.title}</h3>
      {props.children}
    </div>
  )
}
const SingleOption = (props) => {
  return (
    <div className='option'>
      <p>{props.title}</p>
      <div>
        {props.children}
      </div>
    </div>
  )
}

const Container = styled('div')({
  ...column,
  gap: 20,
  '& .section': {
    ...column,
    gap: 10,
    backgroundColor: COLORS['neutral-100'],
    alignItems: 'flex-start',
    '& h3': {
      marginBottom: 10
    },
    border: `1px solid ${COLORS['neutral-300']}`,
    borderRadius: 10,
    padding: 10,
    color: 'black'
  },
  '& .option': {
    ...row,
    width: '100%',
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS['neutral-300']}`,
    '& > p': {
      width: '20%',
      fontSize: 14,
      borderRight: `1px solid ${COLORS['neutral-300']}`
    },
    '& > div': {
      ...column,
      gap: 10,
      width: '100%',
      paddingInline: 10
    },
    '& span': {
      fontSize: 12,
      color: COLORS['neutral-700']
    }

  },
  '&.mobile': {
    '& .option': {
      ...column,
      '& > p': {
        width: '100%',
        borderRight: 'none',
        borderBottom: `1px solid ${COLORS['neutral-300']}`
      },
      '& > div': {
        paddingInline: 0,
        paddingBlock: 10
      }
    }
  }
})
