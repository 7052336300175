/* jsx-a11y/no-static-element-interactions */
import { Box } from '@mui/material'
import { signInWithCustomToken } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { getAuth } from '@/utils/firebase'

export default function DebugAsPage () {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [token] = useState(searchParams.get('t'))
  const [result, setResult] = useState(null)

  useEffect(() => {
    setSearchParams({}, { replace: true })
    if (!token) {
      navigate('/', { replace: 'true' })
    } else {
      const handleSignedIn = () => setResult('success')
      const handleError = (err) => setResult(err)
      signInWithCustomToken(getAuth(), token).then(handleSignedIn, handleError)
    }
  }, [navigate, setSearchParams, token])

  if (!token) {
    return undefined
  }
  if (!result) {
    return <Box>Signing in...</Box>
  }
  if (result === 'success') {
    return <Box>Signed in!</Box>
  }
  return (
    <Box color='error'>
      <h1>Failed to sign in with token</h1>
      <pre>{JSON.stringify(result, null, 4)}</pre>
    </Box>
  )
}
