import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { apisSlice } from './apis'
import { useUserId } from './auth'
import { watchVideoProgress } from './processing'

import { buildAPIsSlice, callAPIFromThunk, useAPI } from '@/utils/api'

export const insightsSlice = buildAPIsSlice({
  name: 'insights',
  apis: {
    getCV: {
      method: 'GET',
      path: ({ vid, cvGitHash }) => `${vid}/cv-${cvGitHash}.json`,
      providesTags: (result, error, arg) => {
        return [{ type: 'CV', id: `${arg.vid}-${arg.cvGitHash}` }]
      },
      isUserAPI: false
    },
    getInsights: {
      method: 'GET',
      path: ({ vid, aiEngineVersion }) => `${vid}/${aiEngineVersion}/insights.json`,
      providesTags: (result, error, arg) => {
        return [{ type: 'Insights', id: `${arg.vid}-${arg.aiEngineVersion}` }]
      },
      isUserAPI: false
    }
  },
  tagTypes: ['CV', 'Insights'],
  baseUrl: `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/`
})

export function useGetVideo (vid) {
  const inputs = useMemo(() => vid ? { vid } : undefined, [vid])
  const ret = useAPI(apisSlice.useLazyGetVideoQuery, inputs, true)
  const dispatch = useDispatch()
  const { data } = ret
  // watch for video progress if the most recent workflow isn't done OR if no
  // workflow is present yet (means one should show up soon, assuming the
  // upload is complete)
  if (data?.workflows?.[0] && data.workflows[0]?.epochFinished === undefined) {
    dispatch(watchVideoProgress(vid, data.workflows[0].workflowId))
  }
  return ret
}

export function useGetVideoMetadata (vid) {
  const inputs = useMemo(() => vid ? { vid } : undefined, [vid])
  return useAPI(apisSlice.useLazyGetVideoMetadataQuery, inputs, true)
}

export function setVideoField (vid, key, value) {
  return async (dispatch, getState) => {
    callAPIFromThunk(dispatch, getState, apisSlice.endpoints.updateVideo, {
      vid,
      [key]: value
    })
  }
}

export function useIsVideoOwner (vid) {
  const uid = useUserId()
  const { data: video } = useGetVideo(vid)

  if (!video) {
    return null // we don't know yet
  }

  return video.uploader.uid === uid
}

export function useMayEditVideo (vid) {
  const uid = useUserId()
  const { data: video } = useGetVideo(vid)
  // can edit if the current user owns the video or is listed as an editor
  // (this will always be false for users who aren't logged in)
  if (!video) {
    return null // we don't know yet
  }
  return video.uploader.uid === uid || video.userData.editors.indexOf(uid) !== -1
}

/** Omit workflowId to just get the latest. */
export function useGetInsights ({ vid, workflowId = undefined }) {
  const { error, isLoaded, isLoading, data: video } = useGetVideo(vid)
  const inputs = useMemo(() => {
    if (error || isLoading || !isLoaded) {
      return undefined // don't fetch it yet
    }
    for (const workflow of video.workflows) {
      if (!workflowId || workflow.workflowId === workflowId) {
        if (!workflow.noInsights) {
          return {
            __pathParams: {
              vid: video.vid,
              aiEngineVersion: workflow.aiEngineVersion
            }
          }
        }
      }
    }
  }, [error, isLoaded, isLoading, video, workflowId])
  const insightsResult = useAPI(insightsSlice.useLazyGetInsightsQuery, inputs, true)
  if (error || isLoading) {
    return { error, isLoading }
  }
  return insightsResult
}

function useGetCVGitHashForAIEngineVersion (aiEngineVersion) {
  const inputs = useMemo(() => aiEngineVersion ? { aiEngineVersion } : undefined, [aiEngineVersion])
  const ret = useAPI(apisSlice.useLazyGetCVGitHashForAIEngineVersionQuery, inputs, true)
  const { data } = ret
  return data?.cvGitHash
}

export function useGetCVData ({ vid, workflowId = undefined }) {
  // get ai engine version from video
  const { error, isLoaded, isLoading, data: video } = useGetVideo(vid)
  const aiEngineVersion = useMemo(() => {
    if (error || isLoading || !isLoaded) {
      return undefined // don't fetch it yet
    }
    for (const workflow of video.workflows) {
      if (!workflowId || workflow.workflowId === workflowId) {
        if (!workflow.noInsights) {
          return workflow.aiEngineVersion
        }
      }
    }
  }, [error, isLoaded, isLoading, video, workflowId])

  // get cv git hash from ai engine version
  const cvGitHash = useGetCVGitHashForAIEngineVersion(aiEngineVersion)

  // get cv json
  const inputsForGetCV = useMemo(() => {
    if (!cvGitHash) {
      return undefined
    }
    return {
      __pathParams: {
        vid,
        cvGitHash
      }
    }
  }, [cvGitHash, vid])
  const cvResult = useAPI(insightsSlice.useLazyGetCVQuery, inputsForGetCV, true)
  if (error || isLoading) {
    return {
      error: error ?? cvResult.error,
      isLoading: isLoading || !cvGitHash || cvResult.isLoading
    }
  }
  return cvResult.data
}
