import { styled } from '@mui/material'
import React from 'react'

import CT from '@/assets/images/marketing/ct-partner.png'
import DallasFlash from '@/assets/images/marketing/dallas-flash-partner.png'
import DUPR from '@/assets/images/marketing/dupr-partner.png'
import PIKKL from '@/assets/images/marketing/pikkl-partner.png'
import PodPlay from '@/assets/images/marketing/pod-play-partner.png'
import SaveMyPlay from '@/assets/images/marketing/save-my-play-partner.png'
import Scorpions from '@/assets/images/marketing/scorpions-partner.png'
import { column, row } from '@/utils/flexGrid'

const PARTNERS = [
  {
    name: 'PIKKL',
    logo: PIKKL
  },
  {
    name: 'DUPR',
    logo: DUPR
  },
  {
    name: 'Coachella Valley Scorpions',
    logo: Scorpions
  },
  {
    name: 'CT',
    logo: CT
  },
  {
    name: 'Save My Play',
    logo: SaveMyPlay
  },
  {
    name: 'PodPlay',
    logo: PodPlay
  },
  {
    name: 'Dallas Flash',
    logo: DallasFlash
  }

]

export function PartnersLandingSection () {
  return (
    <Container>
      <h2>
        Trusted Partners
      </h2>
      <div className='partners'>
        {PARTNERS.map((partner, i) => (
          <img key={i} src={partner.logo} alt={partner.name} />
        ))}
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  alignItems: 'center',
  margin: '100px 0 150px 0',
  '& h2': {
    marginBottom: '40px'
  },
  '& .partners': {
    ...row,
    gap: '35px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& img': {
      maxHeight: '50%'
    }
  }
})
