import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { styled } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useSettingsMenuItems } from '..'

import { TextButton } from '@/components/button/text-button'
import { signOut } from '@/store/auth'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function SettingsMobileMain () {
  const navigate = useNavigate()
  const items = useSettingsMenuItems()

  function onTabClickHandler (path) {
    navigate(`/settings/${path}`)
  }
  return (
    <Container>{items.map((item, i) => {
      const { title, icon: Icon, id, badge } = item
      return (
        <div key={id} className='item' onClick={() => onTabClickHandler(id)}>
          <Icon />
          <p className='title'>{title}</p>
          {badge || <ArrowRightIcon />}
        </div>
      )
    })}
      <TextButton
        className='signout'
        onClick={() => {
          signOut()
        }}
      >
        Sign out
      </TextButton>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  alignItems: 'flex-start',
  '& .item': {
    ...row,
    width: '100%',
    alignItems: 'center',
    padding: '8px 16px',
    gap: 16,
    color: COLORS['neutral-700'],
    '& .title': {
      fontSize: 21,
      lineHeight: '31px',
      marginRight: 'auto'
    }
  },
  '& .signout': {
    color: COLORS['danger-500'],
    padding: '8px 16px',
    marginTop: 30,
    fontSize: 18
  }
})
